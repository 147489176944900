import React, { useCallback, useEffect, useState } from 'react'
import { useStateContext } from '../contexts/ContextProvider'
import { useNavigate } from 'react-router-dom'
import { useIsAuthenticated } from "@azure/msal-react";
import { getToken } from '../fetch';
import { urlBase } from '../authConfig';
import UserLogo from '../assets/UserLogo'
import LapizLogo from '../assets/LapizLogo';
import ThrashCanLogo from '../assets/ThrashCanLogo';
import UserEditLogo from '../assets/UserEditLogo';
import AddUserLogo from '../assets/AddUserLogo';
import AddLogo from '../assets/AddLogo';
import { DinamicDropdown, Idioma, Styles,CurrencyOptions } from '../components';
import { Player } from '@lottiefiles/react-lottie-player';
import Loading from '../animations/Loading.json'
import XMark from '../assets/XMark';
import AddSingleTransactionLogo from '../assets/AddSingleTransactionLogo';

const languageOptions = [
    {
        name:'Español',
        id:'es',
    },
    {
        name:'English',
        id:'en',
    },
]


const numberFormatOptions = [
    {
        name:'-1',
        id:'1',
    },
    {
        name:'(1)',
        id:'0',
    },
]

const AdministrationScreen = () => {
    const isAuthenticated = useIsAuthenticated();
    const navigate = useNavigate();
    const {idClient,accessToken,setToken,setClient,setRS,setClientOptionsStorage,setClientRSOptionsStorage, idRS, userInfo,language,sessionID} = useStateContext()
    const [openRSEditor,setOpenRSEditor] = useState(false)
    const [openRSModal,setOpenRSModal] = useState(false)
    const [isLoading,setIsLoading] = useState(true)
    const [usersData,setUsersData] = useState([])
    const [scenarioData,setScenarioData] = useState([])
    const [selectedRS,setSelectedRS] = useState({})
    const [openRSTransitionModal,setOpenRSTransitionModal] = useState(false)
    const [rsData,setRSData] = useState([])
    const [openUserInfo,setOpenUserInfo] = useState(false)
    const [userData,setUserInfo] = useState([])
    const [userEmail,setUserEmail] = useState('')
    const [userNickname,setUserNickname] = useState('')
    const [userName,setUserName] = useState('')
    const [userPerm1,setUserPerm1] = useState(false)
    const [userPerm2,setUserPerm2] = useState(false)
    const [userPerm3,setUserPerm3] = useState(false)
    const [userPerm4,setUserPerm4] = useState(false)
    const [userPerm5,setUserPerm5] = useState(false)
    const [userPerm9,setUserPerm9] = useState(false)
    const [openAddUser,setOpenAddUser] = useState(false)
    const [isAdmin,setIsAdmin] = useState(false)
    const [openDeleteModal,setOpenDeleteModal] = useState(false)
    const [openClientModal,setOpenClientModal] = useState(false)
    const [clientName,setClientName] = useState('')
    const [clientIndustry,setClientIndustry] = useState('')
    const [clientLanguage,setClientLanguage] = useState({name:'Español',id:'es'})
    const [clientNumberFormat,setClientNumberFormat] = useState({name:'(1)',id:'0',})
    const [clientCurrency,setClientCurrency] = useState({name:'MXN',id:'MXN'})
    const [scenarioInfo,setScenarioInfo] = useState({name:'',id:'',rs:{name:Idioma.planeacion.seleccionaEmpresa[language]}})
    const [openScenarioModal,setOpenScenarioModal] = useState(false)
    const [openScenarioEditor,setOpenScenarioEditor] = useState(false)
    const [rsOptions,setRSOptions] = useState([])
    const [rsDict,setRSDict] = useState({})

    const AddScenarioDisabled = (scenarioInfo.name.length===0 || (rsOptions.length>0 && !scenarioInfo.rs.id))


    useEffect(()=> {
        if(!isAuthenticated){
            navigate('/')
            return
        }
        getToken().then((token)=>{setToken(token)})
        let headers = new Headers()
        const bearer = `Bearer ${accessToken}`;
        headers.append("Authorization", bearer);
        headers.append("Content-Type",'application/json')
        
        let body = {
            "clientID": idClient,
            logsInfo: {
              origin:'Administration',
                sessionID:sessionID
            }
        }
        if(idRS){
        body.idRS = idRS
        }
        fetch(`${urlBase}/getUsers`,{
            method: 'POST',
            headers: headers,
            body: JSON.stringify(body)
        })
        .then((response) => response.json())
        .then((aux) => {
            setUsersData(aux)
        });
        fetch(`${urlBase}/isAdmin`,{
            method: 'POST',
            headers: headers,
            body: JSON.stringify({
                logsInfo: {
                    origin:'Administration',
                    sessionID:sessionID
                }
            })
        })
        .then((response) => response.json())
        .then((aux) => {
            setIsAdmin(aux.role==='ADMIN')
        });
        //getScenarioData()
        getRSData()
    },[])

    const getRSData = () => {
        let headers = new Headers();
        const bearer = `Bearer ${accessToken}`;
        headers.append('Authorization', bearer);
        headers.append('Content-Type', 'application/json');
        let body={
            clientID: idClient,
            logsInfo: {
                origin:'Administration',
                  sessionID:sessionID
            }
        }
        if(idRS){
            body.idRS = idRS
        }
        fetch(`${urlBase}/getRS`, {
            method: 'POST',
            headers: headers,
            body: JSON.stringify(body),
        })
        .then((res)=>res.json())
        .then((aux)=>{
            let newData = []
            let auxDict = {}
            for(let i=0;i<aux.length;i++){
                newData[i]={
                    name:aux[i].rsAlias,
                    id:aux[i].idRS
                }
                auxDict[aux[i].idRS]={
                    name:aux[i].rsAlias
                }
            }
            setRSDict(auxDict)
            setRSData(aux)
            setRSOptions(newData)
            setIsLoading(false)
        })
    }
    
    const getScenarioData = () => {
        let headers = new Headers()
  
        const bearer = `Bearer ${accessToken}`;
        headers.append("Authorization", bearer);
        headers.append("Content-Type",'application/json')
        let body = {
            "clientID": idClient,
            logsInfo: {
                origin:'Administration',
                  sessionID:sessionID
              }
        }
        if(idRS){
            body.idRS = idRS
        }
        fetch(`${urlBase}/planning/getScenarios`, {
            method: 'POST',
            headers: headers,
            body: JSON.stringify(body)
        })
        .then((res)=>res.json())
        .then((aux)=>{
            let newData = []
            for(let i=0;i<aux.length;i++){
              newData[i]={
                  name:aux[i].name,
                  id:aux[i].idScenario,
                  rs:{
                    id: aux[i].idRS,
                    name: rsDict[aux[i].idRS]?.name
                  }
              }
            }
            setScenarioData(newData)
        })
    }

    const reloadUserAccess = () => {
        let headers = new Headers()
        const bearer = `Bearer ${accessToken}`;
        headers.append("Authorization", bearer);
        headers.append("Content-Type",'application/json')
        fetch(`${urlBase}/login`,{
            method: 'POST',
            headers: headers,
            body: JSON.stringify()
        })
        .then((response) => response.json())
        .then((aux) => {
            if(aux.clientOptions.length+aux.clientRSOptions.length>1){
                setClientOptionsStorage(aux.clientOptions)
                setClientRSOptionsStorage(aux.clientRSOptions)
                
            }else if(aux.clientOptions.length==1){
                setClient({
                    'id':aux.clientOptions[0].idClient,
                    'name':aux.clientOptions[0].client,
                    'access1':aux.clientOptions[0].access1,
                    'access2':aux.clientOptions[0].access2,
                    'access3':aux.clientOptions[0].access3,
                    'access4':aux.clientOptions[0].access4,
                    'access5':aux.clientOptions[0].access5,
                    'access6':aux.clientOptions[0].access6,
                    'access7':aux.clientOptions[0].access7,
                    'access8':aux.clientOptions[0].access8,
                    'access9':aux.clientOptions[0].access9,
                    'language':aux.clientOptions[0].language,
                    'currency':aux.clientOptions[0].currency,
                    'numberFormat':aux.clientOptions[0].numberFormat,
                })
            }else if(aux.clientRSOptions.length==1){
                setRS({
                    'id':aux.clientRSOptions[0].idClient,
                    'name':aux.clientRSOptions[0].client,
                    'idRS':aux.clientRSOptions[0].idRS,
                    'rsName':aux.clientRSOptions[0].rsAlias,
                    'access1':aux.clientRSOptions[0].access1,
                    'access2':aux.clientRSOptions[0].access2,
                    'access3':aux.clientRSOptions[0].access3,
                    'access4':aux.clientRSOptions[0].access4,
                    'access5':aux.clientRSOptions[0].access5,
                    'access6':aux.clientRSOptions[0].access6,
                    'access7':aux.clientRSOptions[0].access7,
                    'access8':aux.clientRSOptions[0].access8,
                    'access9':aux.clientRSOptions[0].access9,
                    'language':aux.clientRSOptions[0].language,
                    'currency':aux.clientRSOptions[0].currency,
                    'numberFormat':aux.clientRSOptions[0].numberFormat,
                })
            }
            let updatedFlag = false
            if(idRS){
                for(let i=0;i<aux.clientRSOptions.length;i++){
                    if(aux.clientRSOptions[i].idRS==idRS){
                        setRS({
                            'id':aux.clientRSOptions[i].idClient,
                            'name':aux.clientRSOptions[i].client,
                            'idRS':aux.clientRSOptions[i].idRS,
                            'rsName':aux.clientRSOptions[i].rsAlias,
                            'access1':aux.clientRSOptions[i].access1,
                            'access2':aux.clientRSOptions[i].access2,
                            'access3':aux.clientRSOptions[i].access3,
                            'access4':aux.clientRSOptions[i].access4,
                            'access5':aux.clientRSOptions[i].access5,
                            'access6':aux.clientRSOptions[i].access6,
                            'access7':aux.clientRSOptions[i].access7,
                            'access8':aux.clientRSOptions[i].access8,
                            'access9':aux.clientRSOptions[i].access9,
                            'language':aux.clientRSOptions[0].language,
                            'currency':aux.clientRSOptions[0].currency,
                            'numberFormat':aux.clientRSOptions[0].numberFormat,
                        })
                        updatedFlag=true
                    }
                }
            }else{
                for(let i=0;i<aux.clientOptions.length;i++){
                    if(aux.clientOptions[i].idClient==idClient){
                        setClient({
                            'id':aux.clientOptions[i].idClient,
                            'name':aux.clientOptions[i].client,
                            'access1':aux.clientOptions[i].access1,
                            'access2':aux.clientOptions[i].access2,
                            'access3':aux.clientOptions[i].access3,
                            'access4':aux.clientOptions[i].access4,
                            'access5':aux.clientOptions[i].access5,
                            'access6':aux.clientOptions[i].access6,
                            'access7':aux.clientOptions[i].access7,
                            'access8':aux.clientOptions[i].access8,
                            'access9':aux.clientOptions[i].access9,
                            'language':aux.clientOptions[0].language,
                            'currency':aux.clientOptions[0].currency,
                            'numberFormat':aux.clientOptions[0].numberFormat,
                        })
                        updatedFlag=true
                    }
                }
            }
            if(!updatedFlag){
                navigate('/error')
            }
        });
    }
 

    const handleUserSelect = (data) => {
        setUserName(data.name)
        setUserEmail(data.email)
        setUserPerm1(data.access1)
        setUserPerm2(data.access2)
        setUserPerm3(data.access3)
        setUserPerm4(data.access4)
        setUserPerm5(data.access5)
        setUserPerm9(data.access9)
        setUserNickname(data.nickname)
        setUserInfo(data)
        setOpenUserInfo(true)
    }

    const handleResetUserInfo = () => {
        setUserName('')
        setUserEmail('')
        setUserPerm1(false)
        setUserPerm2(false)
        setUserPerm3(false)
        setUserPerm4(false)
        setUserPerm5(false)
        setUserPerm9(false)
        setUserNickname('')
        setUserInfo([])
        setClientName('')
        setClientIndustry('')
    } 

    const handleUserPerm1Change = () => {
        setUserPerm1((prev)=>!prev)
    }

    const handleUserPerm2Change = () => {
        if(userPerm2===true){
            setUserPerm2(prev=>!prev)
            setUserPerm3(false)
        }else if(userPerm2===false){
            setUserPerm2(prev=>!prev)
        }
    }

    const handleUserPerm3Change = () => {
        if(userPerm3===true){
            setUserPerm3(prev=>!prev)
        }else if(userPerm3===false){
            setUserPerm3(prev=>!prev)
            setUserPerm2(true)
        }
        
    }
    const handleUserPerm4Change = () => {
        setUserPerm4((prev)=>!prev)
    }

    const handleUserPerm5Change = () => {
        setUserPerm5((prev)=>!prev)
    }

    const handleUserPerm9Change = () => {
        setUserPerm9((prev)=>!prev)
    }

    const handleUpdateUser = () => {
        let headers = new Headers()
        const bearer = `Bearer ${accessToken}`;
        headers.append("Authorization", bearer);
        headers.append("Content-Type",'application/json')
        let body = {
            "clientID": idClient,
            'idUser':userData.idUser,
            'access1':userPerm1===true ? 1:0,
            'access2':userPerm2===true ? 1:0,
            'access3':userPerm3===true ? 1:0,
            'access4':userPerm4===true ? 1:0,
            'access5':userPerm5===true ? 1:0,
            'access9':userPerm9===true ? 1:0,
            'nickname':userNickname,
            logsInfo: {
                origin:'Administration',
                  sessionID:sessionID
            }
        }
        if(idRS){
            body.idRS = idRS
        }
        fetch(`${urlBase}/updateUser`,{
            method: 'POST',
            headers: headers,
            body: JSON.stringify(body)
        })
        .then((response) => response.json())
        .then((aux) => {
            let aux1 = [...usersData]
            for(let i=0;i<usersData.length;i++){
                if(userData.idUser==usersData[i].idUser){
                    aux1[i]={
                        'name':userName,
                        'email':userEmail,
                        'idUser':userData.idUser,
                        'access1':userPerm1,
                        'access2':userPerm2,
                        'access3':userPerm3,
                        'access4':userPerm4,
                        'access5':userPerm5,
                        'access9':userPerm9,
                        'nickname':userNickname
                    }
                }
            }
            setUsersData(aux1)
            if(userInfo.email==userEmail){
                reloadUserAccess()
            }
            handleCloseModal()
        });
    }

    const handleOpenDeleteModal = (datum) => {
        setOpenDeleteModal(true)
        setUserInfo(datum)
    }

    const handleDeleteUser = (deleteInAllRs) => {
        let headers = new Headers()
        const bearer = `Bearer ${accessToken}`;
        headers.append("Authorization", bearer);
        headers.append("Content-Type",'application/json')
        let body = {
            "clientID": idClient,
            "idUser": userData.idUser,
            "deleteInAllRs":deleteInAllRs,
            logsInfo: {
                origin:'Administration',
                  sessionID:sessionID
            }
        }
        if(idRS){
            body.idRS = idRS
        }
        fetch(`${urlBase}/deleteAccess`,{
            method: 'POST',
            headers: headers,
            body: JSON.stringify(body)
        })
        .then((response) => response.json())
        .then((aux) => {
            if(aux[0].message == 'delete successfull'){
                let aux1 = []
                for(let i=0;i<usersData.length;i++){
                    if(usersData[i].idUser!==userData.idUser){
                        aux1[aux1.length]=usersData[i]
                    }
                }
                setUsersData(aux1)
                handleCloseModal()
            }
        });
    }

    const handleAddNewUser = () => {
        let headers = new Headers()
        const bearer = `Bearer ${accessToken}`;
        headers.append("Authorization", bearer);
        headers.append("Content-Type",'application/json')
        let body = {
            "clientID": idClient,
            'email': userEmail,
            'name':userName,
            'access1':userPerm1===true ? 1:0,
            'access2':userPerm2===true ? 1:0,
            'access3':userPerm3===true ? 1:0,
            'access4':userPerm4===true ? 1:0,
            'access5':userPerm5===true ? 1:0,
            'access9':userPerm9===true ? 1:0,
            'nickname':userNickname,
            logsInfo: {
                origin:'Administration',
                  sessionID:sessionID
            }
        }
        if(idRS){
            body.idRS = idRS
        }
        fetch(`${urlBase}/createUser`,{
            method: 'POST',
            headers: headers,
            body: JSON.stringify(body)
        })
        .then((response) => response.json())
        .then((aux) => {
            let aux1 = [...usersData]
            aux1[aux1.length]={
                "idUser": aux.idUser,
                "name": userName,
                "nickname": userNickname,
                "email": userEmail,
                "access1": userPerm1,
                "access2": userPerm2,
                "access3": userPerm3,
                "access4": userPerm4,
                "access5": userPerm5,
                "access9": userPerm9,
            }
            setUsersData(aux1)
            handleCloseModal()
        });
    }

    const handleAddNewClient = () => {
        let headers = new Headers()
        const bearer = `Bearer ${accessToken}`;
        headers.append("Authorization", bearer);
        headers.append("Content-Type",'application/json')
        fetch(`${urlBase}/createClient`,{
            method: 'POST',
            headers: headers,
            body: JSON.stringify({
                'client':clientName,
                'industry':clientIndustry,
                'currency':clientCurrency.id,
                'language':clientLanguage.id,
                'numberFormat':clientNumberFormat.id,
                logsInfo: {
                    origin:'Administration',
                      sessionID:sessionID
                }
            })
        })
        .then((response) => response.json())
        .then((aux) => {
            reloadUserAccess()
            handleCloseModal()
        });
    }

    const handleOpenNewUser = () => {
        setOpenAddUser(true)
    }

    const handleOpenNewClient = () => {
        setOpenClientModal(true)
    }
    
    

    const handleUpdateRS = () => {
        let headers = new Headers();
        const bearer = `Bearer ${accessToken}`;
        headers.append('Authorization', bearer);
        headers.append('Content-Type', 'application/json');
        let body={
            clientID: idClient,
            rsName:selectedRS.rsName,
            rsTaxID:selectedRS.rsTaxID,
            rsAlias:selectedRS.rsAlias,
            rsID:selectedRS.idRS,
            logsInfo: {
                origin:'Administration',
                sessionID:sessionID
            }
        }
        if(idRS){
            body.idRS = idRS
        }
        fetch(`${urlBase}/updateRS`, {
            method: 'POST',
            headers: headers,
            body: JSON.stringify(body),
        })
        .then((res)=>res.json())
        .then((aux)=>{
            getRSData()
            handleCloseModal()
        })
    }

    const createRS = () => {
        let headers = new Headers();
        const bearer = `Bearer ${accessToken}`;
        headers.append('Authorization', bearer);
        headers.append('Content-Type', 'application/json');
        let body={
            clientID: idClient,
            rsName:selectedRS.rsName,
            rsTaxID:selectedRS.rsTaxID,
            rsAlias:selectedRS.rsAlias,
            logsInfo: {
                origin:'Administration',
                  sessionID:sessionID
            }
        }
        if(rsData.length===0){
            body.transitionToRS=true
        }
        fetch(`${urlBase}/createRS`, {
            method: 'POST',
            headers: headers,
            body: JSON.stringify(body),
        })
        .then((res)=>res.json())
        .then((aux)=>{
            getRSData()
            reloadUserAccess()
            handleCloseModal()
            
        })
    }

    const handleRSSelect = (rsData) => {
        setOpenRSEditor(true)
        setSelectedRS(rsData)
    }

    const handleCreateRS = () => {
        setSelectedRS({
            rsName:'',
            rsTaxID:'',
            rsAlias:''
        })
        setOpenRSModal(true)
    }

    const handleTransitionToRS = () => {
        setSelectedRS({
            rsName:'',
            rsTaxID:'',
            rsAlias:''
        })
        setOpenRSTransitionModal(true)
    }

    const handleCloseModal = () => {
        setOpenRSEditor(false)
        setOpenRSModal(false)
        setOpenRSTransitionModal(false)
        setSelectedRS({})
        setOpenDeleteModal(false)
        setOpenUserInfo(false)
        setOpenAddUser(false)
        setOpenClientModal(false)
        handleResetUserInfo()
        setOpenScenarioModal(false)
        setOpenScenarioEditor(false)
        setScenarioInfo({name:'',id:'',rs:{name:Idioma.planeacion.seleccionaEmpresa[language]}})

    }

    const handleScenarioSelect = (value) => {
        setScenarioInfo(value)
        setOpenScenarioEditor(true)
    }

    const handleScenarioInfoRSChange = (value) => {
        setScenarioInfo({...scenarioInfo,rs:value})
    }

    const handleUpdateScenario = () => {
        let headers = new Headers();
        const bearer = `Bearer ${accessToken}`;
        headers.append('Authorization', bearer);
        headers.append('Content-Type', 'application/json');
        let body={
            clientID: idClient,
            idScenario:scenarioInfo.id,
            name:scenarioInfo.name,
            logsInfo: {
                origin:'Administration',
                sessionID:sessionID
            }
        }
        if(idRS){
            body.idRS = idRS
        }
        fetch(`${urlBase}/planning/updateScenario`, {
            method: 'POST',
            headers: headers,
            body: JSON.stringify(body),
        })
        .then((res)=>res.json())
        .then((aux)=>{
            getScenarioData()
            handleCloseModal()
        })
    }

    const handleAddScenario = () => {
        let headers = new Headers()

        const bearer = `Bearer ${accessToken}`;
        headers.append("Authorization", bearer);
        headers.append("Content-Type",'application/json')
        let body = {
            "clientID": idClient,
            "name":scenarioInfo.name,
            logsInfo: {
                origin:'Administration',
                  sessionID:sessionID
            }
        }
        if(idRS){
            body.idRS = idRS
        }
        if(scenarioInfo.rs.id){
            body.rsID = scenarioInfo.rs.id
        }

        fetch(`${urlBase}/planning/addScenario`,{
        method: 'POST',
        headers: headers,
        body: JSON.stringify(body)
        })
        .then((response) => response.json())
        .then((aux) => {
            handleCloseModal()
            getScenarioData()
        })
        .catch(error=>{
            alert('Ha ocurrido un error')
        })
    }

    const isSaveDisabled = ( selectedRS.rsName==null || selectedRS.rsName.length==0) || (selectedRS.rsAlias==null || selectedRS.rsAlias.length==0) || (selectedRS.rsTaxID==null || selectedRS.rsTaxID.length==0)
    const RSTransitionDisabled = selectedRS.rsName?.length===0 || selectedRS.rsAlias?.length===0 || selectedRS.rsTaxID?.length===0
    return (
        <div className='ml-[5%] mt-10 mb-[40px]'>
            {openUserInfo && 
            <div className='fixed top-0 right-0 left-[15%] bottom-0 w-screen z-[1000] h-screen'>
                <div onClick={()=>handleCloseModal()} className={Styles.modalBackground}>
                </div>
                <div className={Styles.defaultModal}>
                    <div className='w-full h-full relative'>
                    <span onClick={()=>handleCloseModal()} className={Styles.modalXmark}><XMark/></span>

                        <div className='flex mb-[52px]'><span className='2xl:text-h5-2xl text-[26px] font-bold'>{Idioma.administracion.modalEditar.titulo[language]}</span></div>
                        <div className='flex mb-2 items-center'>
                            <span className='text-p5-2xl font-bold w-36'>{Idioma.administracion.modalEditar.nombre[language]}</span><input className={Styles.nonInput} value={userName} disabled onChange={(e)=>setUserName(e.target.value)}></input>
                        </div>
                        <div className='flex mb-2 items-center'>
                            <span className='text-p5-2xl font-bold w-36'>{Idioma.administracion.modalEditar.correo[language]}</span><input className={Styles.nonInput} value={userEmail} disabled onChange={(e)=>setUserEmail(e.target.value)}></input>
                        </div>
                        <div className='flex items-center'>
                            <span className='text-p5-2xl font-bold w-36'>{Idioma.administracion.modalEditar.rol[language]}</span><input className={Styles.defaultInput} value={userNickname} onChange={(e)=>setUserNickname(e.target.value)}></input>
                        </div>
                        <div className='flex mt-[15px]'>
                            <p className=' text-h10-2xl'>{Idioma.administracion.modalEditar.permisos[language]}</p>
                        </div>
                        <div className='grid grid-cols-2 gap-x-4 mt-4'>
                            <div className='flex items-center justify-between mb-4'>
                                <span className='text-[12px]'>{Idioma.administracion.modalEditar.permiso1[language]}</span>
                                <div>
                                    <label for="default-toggle1" class={"inline-flex relative items-center cursor-pointer "}>
                                        <input type="checkbox" checked={userPerm1} onClick={()=>{handleUserPerm1Change()}}  id="default-toggle1" class="sr-only peer"/>
                                        <div class="w-11 h-6 rounded-full peer bg-gray-5 border-gray-5 peer-checked:border-md-white border-1 peer-checked:bg-light-purple-3  peer-checked:after:translate-x-full after:content-[''] after:absolute peer-checked:after:top-[4px] peer-checked:after:left-[8px] after:top-[4px] after:left-[4px] after:bg-white peer-checked:after:bg-md-white dark:after:bg-md-white  after:rounded-full after:h-4 after:w-4 after:transition-all "></div>
                                    </label>
                                </div>
                            </div>
                            <div className='flex items-center justify-between mb-4'>
                                <span className='text-[12px]'>{Idioma.administracion.modalEditar.permiso2[language]}</span>
                                <div>
                                    <label for="default-toggle2" class={"inline-flex relative items-center cursor-pointer "}>
                                        <input type="checkbox" checked={userPerm2} onClick={()=>{handleUserPerm2Change()}}  id="default-toggle2" class="sr-only peer"/>
                                        <div class="w-11 h-6 rounded-full peer bg-gray-5 border-gray-5 peer-checked:border-md-white border-1 peer-checked:bg-light-purple-3  peer-checked:after:translate-x-full after:content-[''] after:absolute peer-checked:after:top-[4px] peer-checked:after:left-[8px] after:top-[4px] after:left-[4px] after:bg-white peer-checked:after:bg-md-white dark:after:bg-md-white  after:rounded-full after:h-4 after:w-4 after:transition-all "></div>
                                    </label>
                                </div>
                            </div>
                            <div className='flex items-center justify-between mb-4'>
                                <span className='text-[12px]'>{Idioma.administracion.modalEditar.permiso3[language]}</span>
                                <div>
                                    <label for="default-toggle3" class={"inline-flex relative items-center cursor-pointer "}>
                                        <input type="checkbox" checked={userPerm3} onClick={()=>{handleUserPerm3Change()}}  id="default-toggle3" class="sr-only peer"/>
                                        <div class="w-11 h-6 rounded-full peer bg-gray-5 border-gray-5 peer-checked:border-md-white border-1 peer-checked:bg-light-purple-3  peer-checked:after:translate-x-full after:content-[''] after:absolute peer-checked:after:top-[4px] peer-checked:after:left-[8px] after:top-[4px] after:left-[4px] after:bg-white peer-checked:after:bg-md-white dark:after:bg-md-white  after:rounded-full after:h-4 after:w-4 after:transition-all "></div>
                                    </label>
                                </div>
                            </div>
                            <div className='flex items-center justify-between mb-4'>
                                <span className='text-[12px]'>{Idioma.administracion.modalEditar.permiso4[language]}</span>
                                <div>
                                    <label for="default-toggle4" class={"inline-flex relative items-center cursor-pointer "}>
                                        <input type="checkbox" checked={userPerm4} onClick={()=>{handleUserPerm4Change()}}  id="default-toggle4" class="sr-only peer"/>
                                        <div class="w-11 h-6 rounded-full peer bg-gray-5 border-gray-5 peer-checked:border-md-white border-1 peer-checked:bg-light-purple-3  peer-checked:after:translate-x-full after:content-[''] after:absolute peer-checked:after:top-[4px] peer-checked:after:left-[8px] after:top-[4px] after:left-[4px] after:bg-white peer-checked:after:bg-md-white dark:after:bg-md-white  after:rounded-full after:h-4 after:w-4 after:transition-all "></div>
                                    </label>
                                </div>
                            </div>
                            <div className='flex items-center justify-between mb-4'>
                                <span className='text-[12px]'>{Idioma.administracion.modalEditar.permiso5[language]}</span>
                                <div>
                                    <label for="default-toggle5" class={"inline-flex relative items-center cursor-pointer "}>
                                        <input type="checkbox" checked={userPerm5} onClick={()=>{handleUserPerm5Change()}}  id="default-toggle5" class="sr-only peer"/>
                                        <div class="w-11 h-6 rounded-full peer bg-gray-5 border-gray-5 peer-checked:border-md-white border-1 peer-checked:bg-light-purple-3  peer-checked:after:translate-x-full after:content-[''] after:absolute peer-checked:after:top-[4px] peer-checked:after:left-[8px] after:top-[4px] after:left-[4px] after:bg-white peer-checked:after:bg-md-white dark:after:bg-md-white  after:rounded-full after:h-4 after:w-4 after:transition-all "></div>
                                    </label>
                                </div>
                            </div>
                            <div className='flex items-center justify-between mb-4'>
                                <span className='text-[12px]'>{Idioma.administracion.modalEditar.permiso9[language]}</span>
                                <div>
                                    <label for="default-toggle9" class={"inline-flex relative items-center cursor-pointer "}>
                                        <input type="checkbox" checked={userPerm9} onClick={()=>{handleUserPerm9Change()}}  id="default-toggle9" class="sr-only peer"/>
                                        <div class="w-11 h-6 rounded-full peer bg-gray-5 border-gray-5 peer-checked:border-md-white border-1 peer-checked:bg-light-purple-3  peer-checked:after:translate-x-full after:content-[''] after:absolute peer-checked:after:top-[4px] peer-checked:after:left-[8px] after:top-[4px] after:left-[4px] after:bg-white peer-checked:after:bg-md-white dark:after:bg-md-white  after:rounded-full after:h-4 after:w-4 after:transition-all "></div>
                                    </label>
                                </div>
                            </div>
                        </div>
                        <div className='flex justify-between'>
                            <div className='text-black cursor-pointer text-p9-2xl font-bold rounded-button px-[15px] h-button-4 border-1 border-black bg-white flex items-center' onClick={()=>setOpenDeleteModal(true)}>
                                {Idioma.administracion.modalEditar.eliminar[language]}
                            </div>
                            <div className='flex justify-end items-center gap-4'>
                                <div className={Styles.saveButton} onClick={()=>handleUpdateUser()}>
                                    {Idioma.catalogoCuentas.guardar[language]}
                                </div>
                                <div className={Styles.cancelButton} onClick={()=>handleCloseModal()}>
                                    {Idioma.catalogoCuentas.cancelar[language]}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>}
            {openAddUser && 
            <div className='fixed top-0 right-0 left-[15%] bottom-0 w-screen z-[1000] h-screen'>
                <div onClick={()=>handleCloseModal()} className={Styles.modalBackground}>
                </div>
                <div className={Styles.defaultModal}>
                    <div className='w-full h-full relative'>
                    <span onClick={()=>handleCloseModal()} className={Styles.modalXmark}><XMark/></span>

                        <div className='flex mb-[52px]'><span className=' 2xl:text-h5-2xl text-h5-2xl'>{Idioma.administracion.anadirUsuarios[language]}</span></div>
                        <div className='flex mb-2 items-center'>
                            <span className='text-p5-2xl font-bold w-36'>{Idioma.administracion.modalEditar.nombre[language]}</span><input className={Styles.defaultInput}  value={userName} onChange={(e)=>setUserName(e.target.value)}></input>
                        </div>
                        <div className='flex mb-2 items-center'>
                            <span className='text-p5-2xl font-bold w-36'>{Idioma.administracion.modalEditar.correo[language]}</span><input className={Styles.defaultInput}  value={userEmail} onChange={(e)=>setUserEmail(e.target.value)} type='email'></input>
                        </div>
                        <div className='flex items-center'>
                            <span className='text-p5-2xl font-bold w-36'>{Idioma.administracion.modalEditar.rol[language]}</span><input className={Styles.defaultInput} value={userNickname} onChange={(e)=>setUserNickname(e.target.value)}></input>
                        </div>
                        <div className='flex mt-[15px]'>
                            <p className=' text-h10-2xl'>{Idioma.administracion.modalEditar.permisos[language]}</p>
                        </div>
                        <div className='grid grid-cols-2 gap-x-4 mt-4'>
                            <div className='flex items-center justify-between mb-4'>
                                <span className='text-[12px]'>{Idioma.administracion.modalEditar.permiso1[language]}</span>
                                <div>
                                    <label for="default-toggle1" class={"inline-flex relative items-center cursor-pointer "}>
                                        <input type="checkbox" checked={userPerm1} onClick={()=>{handleUserPerm1Change()}}  id="default-toggle1" class="sr-only peer"/>
                                        <div class="w-11 h-6 rounded-full peer bg-gray-5 border-gray-5 peer-checked:border-md-white border-1 peer-checked:bg-light-purple-3  peer-checked:after:translate-x-full after:content-[''] after:absolute peer-checked:after:top-[4px] peer-checked:after:left-[8px] after:top-[4px] after:left-[4px] after:bg-white peer-checked:after:bg-md-white dark:after:bg-md-white  after:rounded-full after:h-4 after:w-4 after:transition-all "></div>
                                    </label>
                                </div>
                            </div>
                            <div className='flex items-center justify-between mb-4'>
                                <span className='text-[12px]'>{Idioma.administracion.modalEditar.permiso2[language]}</span>
                                <div>
                                    <label for="default-toggle2" class={"inline-flex relative items-center cursor-pointer "}>
                                        <input type="checkbox" checked={userPerm2} onClick={()=>{handleUserPerm2Change()}}  id="default-toggle2" class="sr-only peer"/>
                                        <div class="w-11 h-6 rounded-full peer bg-gray-5 border-gray-5 peer-checked:border-md-white border-1 peer-checked:bg-light-purple-3  peer-checked:after:translate-x-full after:content-[''] after:absolute peer-checked:after:top-[4px] peer-checked:after:left-[8px] after:top-[4px] after:left-[4px] after:bg-white peer-checked:after:bg-md-white dark:after:bg-md-white  after:rounded-full after:h-4 after:w-4 after:transition-all "></div>
                                    </label>
                                </div>
                            </div>
                            <div className='flex items-center justify-between mb-4'>
                                <span className='text-[12px]'>{Idioma.administracion.modalEditar.permiso3[language]}</span>
                                <div>
                                    <label for="default-toggle3" class={"inline-flex relative items-center cursor-pointer "}>
                                        <input type="checkbox" checked={userPerm3} onClick={()=>{handleUserPerm3Change()}}  id="default-toggle3" class="sr-only peer"/>
                                        <div class="w-11 h-6 rounded-full peer bg-gray-5 border-gray-5 peer-checked:border-md-white border-1 peer-checked:bg-light-purple-3  peer-checked:after:translate-x-full after:content-[''] after:absolute peer-checked:after:top-[4px] peer-checked:after:left-[8px] after:top-[4px] after:left-[4px] after:bg-white peer-checked:after:bg-md-white dark:after:bg-md-white  after:rounded-full after:h-4 after:w-4 after:transition-all "></div>
                                    </label>
                                </div>
                            </div>
                            <div className='flex items-center justify-between mb-4'>
                                <span className='text-[12px]'>{Idioma.administracion.modalEditar.permiso4[language]}</span>
                                <div>
                                    <label for="default-toggle4" class={"inline-flex relative items-center cursor-pointer "}>
                                        <input type="checkbox" checked={userPerm4} onClick={()=>{handleUserPerm4Change()}}  id="default-toggle4" class="sr-only peer"/>
                                        <div class="w-11 h-6 rounded-full peer bg-gray-5 border-gray-5 peer-checked:border-md-white border-1 peer-checked:bg-light-purple-3  peer-checked:after:translate-x-full after:content-[''] after:absolute peer-checked:after:top-[4px] peer-checked:after:left-[8px] after:top-[4px] after:left-[4px] after:bg-white peer-checked:after:bg-md-white dark:after:bg-md-white  after:rounded-full after:h-4 after:w-4 after:transition-all "></div>
                                    </label>
                                </div>
                            </div>
                            <div className='flex items-center justify-between mb-4'>
                                <span className='text-[12px]'>{Idioma.administracion.modalEditar.permiso5[language]}</span>
                                <div>
                                    <label for="default-toggle5" class={"inline-flex relative items-center cursor-pointer "}>
                                        <input type="checkbox" checked={userPerm5} onClick={()=>{handleUserPerm5Change()}}  id="default-toggle5" class="sr-only peer"/>
                                        <div class="w-11 h-6 rounded-full peer bg-gray-5 border-gray-5 peer-checked:border-md-white border-1 peer-checked:bg-light-purple-3  peer-checked:after:translate-x-full after:content-[''] after:absolute peer-checked:after:top-[4px] peer-checked:after:left-[8px] after:top-[4px] after:left-[4px] after:bg-white peer-checked:after:bg-md-white dark:after:bg-md-white  after:rounded-full after:h-4 after:w-4 after:transition-all "></div>
                                    </label>
                                </div>
                            </div>
                            <div className='flex items-center justify-between mb-4'>
                                <span className='text-[12px]'>{Idioma.administracion.modalEditar.permiso9[language]}</span>
                                <div>
                                    <label for="default-toggle9" class={"inline-flex relative items-center cursor-pointer "}>
                                        <input type="checkbox" checked={userPerm9} onClick={()=>{handleUserPerm9Change()}}  id="default-toggle9" class="sr-only peer"/>
                                        <div class="w-11 h-6 rounded-full peer bg-gray-5 border-gray-5 peer-checked:border-md-white border-1 peer-checked:bg-light-purple-3  peer-checked:after:translate-x-full after:content-[''] after:absolute peer-checked:after:top-[4px] peer-checked:after:left-[8px] after:top-[4px] after:left-[4px] after:bg-white peer-checked:after:bg-md-white dark:after:bg-md-white  after:rounded-full after:h-4 after:w-4 after:transition-all "></div>
                                    </label>
                                </div>
                            </div>
                        </div>
                        
                        <div className='flex justify-end items-center gap-4'>
                            <div className={Styles.saveButton} onClick={()=>handleAddNewUser()}>
                                {Idioma.administracion.anadirUsuarios[language]}
                            </div>
                            <div className={Styles.cancelButton} onClick={()=>handleCloseModal()}>
                                {Idioma.catalogoCuentas.cancelar[language]}
                            </div>
                        </div>
                    </div>
                </div>
            </div>}
            {openClientModal && 
            <div className='fixed top-0 right-0 left-[15%] bottom-0 w-screen z-[1000] h-screen'>
                <div onClick={()=>handleCloseModal()} className={Styles.modalBackground}>
                </div>
                <div className={Styles.defaultModal}>
                    <div className='w-full h-full relative'>
                    <span onClick={()=>handleCloseModal()} className={Styles.modalXmark}><XMark/></span>

                        <div className='flex mb-12'><span className='2xl:text-h5-2xl text-[26px] font-bold'>{Idioma.administracion.anadirCliente[language]}</span></div>
                        <div className='flex mb-2 items-center'>
                            <span className='text-p5-2xl font-bold w-36'>{Idioma.administracion.modalEditar.nombre[language]}</span><input className={Styles.defaultInput}  value={clientName} onChange={(e)=>setClientName(e.target.value)}></input>
                        </div>
                        <div className='flex mb-2 items-center'>
                            <span className='text-p5-2xl font-bold w-36'>{Idioma.administracion.modalCliente.industria[language]}</span><input className={Styles.defaultInput}  value={clientIndustry} onChange={(e)=>setClientIndustry(e.target.value)}></input>
                        </div>
                        
                        <div className='flex mb-2 items-center'>
                            <span className='text-p5-2xl font-bold w-36'>{Idioma.administracion.modalCliente.idioma[language]}</span>
                            <DinamicDropdown options={languageOptions} selected={clientLanguage} setSelected={setClientLanguage} className="w-72 text-p9-2xl px-2 py-2"/>
                        </div>

                        <div className='flex mb-2 items-center'>
                            <span className='text-p5-2xl font-bold w-36'>{Idioma.administracion.modalCliente.moneda[language]}</span>
                            <DinamicDropdown options={CurrencyOptions} selected={clientCurrency} setSelected={setClientCurrency} className="w-72 text-p9-2xl px-2 py-2"/>
                            
                        </div>
                        <div className='flex mb-2 items-center'>
                            <span className='text-p5-2xl font-bold w-36'>{Idioma.administracion.modalCliente.negativos[language]} (1)/-1</span>
                            <DinamicDropdown options={numberFormatOptions} selected={clientNumberFormat} setSelected={setClientNumberFormat} className="w-72 text-p9-2xl px-2 py-2"/>
                            
                        </div>
                        <div className='flex justify-end items-center gap-4 mt-10'>
                            <div className={Styles.saveButton} onClick={()=>handleAddNewClient()}>
                                {Idioma.administracion.anadirCliente[language]}
                            </div>
                            <div className={Styles.cancelButton} onClick={()=>handleCloseModal()}>
                                {Idioma.catalogoCuentas.cancelar[language]}
                            </div>
                        </div>
                    </div>
                </div>
            </div>}
            {openDeleteModal && 
            <div className='fixed top-0 right-0 left-[15%] bottom-0 w-screen z-[1000] h-screen'>
                <div onClick={()=>setOpenDeleteModal(false)} className={Styles.modalBackground}>
                </div>
                <div className={Styles.defaultModal}>
                    <div className='w-full h-full relative'>  
                                      
                        <p className='text-center text-p3-2xl'>
                            {Idioma.administracion.modalEliminar.titulo[language]}
                        </p>
                        <p className='text-gray-3 text-p9-2xl text-center mt-[20px]'>{Idioma.administracion.modalEliminar.confirmar[language]}</p>
                        <div className='flex justify-center mt-[15px] items-center gap-x-[15px]'>
                            <div className={Styles.saveButton}  onClick={()=>handleDeleteUser(false)}>
                                {Idioma.administracion.modalEditar.eliminarAcceso[language]}
                            </div>
                            {!idRS && rsOptions.length>0 && <div className={Styles.saveButton}  onClick={()=>handleDeleteUser(true)}>
                                {Idioma.administracion.modalEditar.eliminarTodosAccesos[language]}
                            </div>}
                            <div className={Styles.cancelButton} onClick={()=>setOpenDeleteModal(false)}>
                                {Idioma.catalogoCuentas.cancelar[language]}
                            </div>
                        </div>

                    </div>
                </div>
            </div>
            }
            {isLoading ?
                <div className="fixed top-0 left-[15%] bottom-0 w-screen z-[1000] h-screen">
                    <div className='fixed top-0 right-0 left-[15%] bottom-0 w-screen z-10 h-screen opacity-50 bg-md-gray'></div>
                    <div className="w-full h-full flex justify-center items-center pr-[15%]">
                        <Player
                            className="w-[20%]"
                            autoplay
                            loop
                            src={Loading}
                        ></Player>
                    </div>
                </div>
            :
            null
            }
            {openRSEditor && 
            <div className='fixed top-0 right-0 left-[15%] bottom-0 w-screen z-[1000] h-screen'>
                <div onClick={()=>handleCloseModal()} className={Styles.modalBackground}>
                </div>
                <div className={Styles.defaultModal}>
                    <div className='w-full h-full relative'>
                    <span onClick={()=>handleCloseModal()} className={Styles.modalXmark}><XMark/></span>

                        <div className='flex mb-[40px]'><span className='text-h5-2xl'>{Idioma.administracion.modalEmpresas.titulo[language]}</span></div>
                        <div className='flex mb-2 items-center'>
                            <span className='text-p5-2xl font-bold w-36'>{Idioma.administracion.modalEditar.nombre[language]}</span><input className={Styles.defaultInput} value={selectedRS.rsName} onChange={(e)=>(setSelectedRS({...selectedRS,'rsName':e.target.value}))}></input>
                        </div>
                        <div className='flex mb-2 items-center'>
                            <span className='text-p5-2xl font-bold w-36'>{Idioma.administracion.modalEmpresas.alias[language]}</span><input className={Styles.defaultInput} value={selectedRS.rsAlias} onChange={(e)=>(setSelectedRS({...selectedRS,'rsAlias':e.target.value}))}></input>
                        </div>
                        <div className='flex items-center'>
                            <span className='text-p5-2xl font-bold w-36'>{Idioma.administracion.modalEmpresas.tax[language]}</span><input className={Styles.defaultInput} value={selectedRS.rsTaxID} onChange={(e)=>(setSelectedRS({...selectedRS,'rsTaxID':e.target.value}))}></input>
                        </div>
                        <span onClick={()=>handleCloseModal()} className={Styles.modalXmark}><XMark/></span>
                    </div>
                    <div className='flex justify-end mt-[40px] items-center gap-x-[15px]'>
                        <button className={isSaveDisabled ? Styles.saveButtonDisabled : Styles.saveButton} disabled={isSaveDisabled} onClick={()=>handleUpdateRS()}>
                            {Idioma.catalogoCuentas.guardar[language]}
                        </button>
                        <button className={Styles.cancelButton} onClick={()=>handleCloseModal()}>
                            {Idioma.catalogoCuentas.cancelar[language]}
                        </button>
                    </div>
                

                </div>
            </div>}
            {openRSModal && 
            <div className='fixed top-0 right-0 left-[15%] bottom-0 w-screen z-[1000] h-screen'>
                <div onClick={()=>handleCloseModal()} className={Styles.modalBackground}>
                </div>
                <div className={Styles.defaultModal}>
                    <div className='w-full h-full relative'>
                    <span onClick={()=>handleCloseModal()} className={Styles.modalXmark}><XMark/></span>

                        <div className='flex mb-[52px]'><span className='text-h5-2xl'>{Idioma.administracion.anadirEmpresas[language]}</span></div>
                        <div className='flex mb-2 items-center'>
                            <span className='text-p5-2xl font-bold w-36'>{Idioma.administracion.modalEditar.nombre[language]}</span><input className={Styles.defaultInput} value={selectedRS.rsName} onChange={(e)=>(setSelectedRS({...selectedRS,'rsName':e.target.value}))}></input>
                        </div>
                        <div className='flex mb-2 items-center'>
                            <span className='text-p5-2xl font-bold w-36'>{Idioma.administracion.modalEmpresas.alias[language]}</span><input className={Styles.defaultInput} value={selectedRS.rsAlias} onChange={(e)=>(setSelectedRS({...selectedRS,'rsAlias':e.target.value}))}></input>
                        </div>
                        <div className='flex items-center'>
                            <span className='text-p5-2xl font-bold w-36'>{Idioma.administracion.modalEmpresas.tax[language]}</span><input className={Styles.defaultInput} value={selectedRS.rsTaxID} onChange={(e)=>(setSelectedRS({...selectedRS,'rsTaxID':e.target.value}))}></input>
                        </div>
                    </div>
                    <div className='flex justify-end items-center mt-[40px] gap-x-[15px]'>
                        <div className={Styles.saveButton} onClick={()=>createRS()}>
                            {Idioma.administracion.anadirEmpresas[language]}
                        </div>
                        <div className={Styles.cancelButton} onClick={()=>handleCloseModal()}>
                            {Idioma.catalogoCuentas.cancelar[language]}
                        </div>
                    </div>
                </div>
            </div>}
            {openRSTransitionModal &&
            <div className='fixed top-0 right-0 left-[15%] bottom-0 w-screen z-[1000] h-screen'>
                <div onClick={()=>handleCloseModal()} className={Styles.modalBackground}>
                </div>
                <div className={Styles.defaultModal}>
                    <div className='w-full h-full relative'>
                        <div className='flex '><span className='text-h5-2xl'>{Idioma.administracion.habilitarEmpresas[language]}</span></div>
                        <div className='mb-12'><span className='2xl:text-p5-2xl text-[12px]'>{Idioma.administracion.modalCliente.subtitulo[language]}</span></div>
                        <div className='flex mb-2 items-center'>
                            <span className='text-p5-2xl font-bold w-36'>{Idioma.administracion.modalEditar.nombre[language]}</span><input className={Styles.wideInput} value={selectedRS.rsName} onChange={(e)=>(setSelectedRS({...selectedRS,'rsName':e.target.value}))}></input>
                        </div>
                        <div className='flex mb-2 items-center'>
                            <span className='text-p5-2xl font-bold w-36'>{Idioma.administracion.modalEmpresas.alias[language]}</span><input className={Styles.wideInput} value={selectedRS.rsAlias} onChange={(e)=>(setSelectedRS({...selectedRS,'rsAlias':e.target.value}))}></input>
                        </div>
                        <div className='flex mb-2 items-center'>
                            <span className='text-p5-2xl font-bold w-36'>{Idioma.administracion.modalEmpresas.tax[language]}</span><input className={Styles.wideInput} value={selectedRS.rsTaxID} onChange={(e)=>(setSelectedRS({...selectedRS,'rsTaxID':e.target.value}))}></input>
                        </div>
                    </div>
                    <div className='flex justify-end items-center gap-x-[15px] mt-[40px]'>
                        <button className={RSTransitionDisabled ? Styles.saveButtonDisabled : Styles.saveButton} disabled={RSTransitionDisabled} onClick={()=>createRS()}>
                            {Idioma.catalogoCuentas.guardar[language]}
                        </button>
                        <button className={Styles.cancelButton} onClick={()=>handleCloseModal()}>
                            {Idioma.catalogoCuentas.cancelar[language]}
                        </button>
                    </div>
                </div>
            </div>
            }
            {openScenarioEditor && <div className='fixed top-0 right-0 left-[15%] bottom-0 w-screen z-[1000] h-screen'>
                <div onClick={()=>handleCloseModal()} className={Styles.modalBackground}>
                </div>
                <div className="bg-white p-[40px] z-10 absolute top-[40%] mt-20  left-[42.5%] shadow-medium-elevation rounded-main -translate-x-[50%] -translate-y-[50%]">
                <div className='2xl:w-[27rem] w-[24rem] h-full relative'>
                  <p className='text-[20px] 2xl:text-h6-2xl font-bold text-left flex items-center overflow-visible'><AddSingleTransactionLogo fill='#ffffff' className='overflow-visible'/> <span className='ml-[15px]'>{Idioma.planeacion.modalEditar.titulo[language]}</span></p>
                  <div className='grid grid-cols-3 mt-8 2xl:gap-y-4 gap-y-2 items-center'>
                    {/*<p className='text-[12px] 2xl:text-p8-2xl'>{Idioma.transacciones.anadirTransaccionModal.cuenta[language]}</p>
                    <Dropdown
                        options={accountsAddT}
                        selected={accountAddT}
                        setSelected={setAccountAddT}
                        className={' w-full col-span-2 text-[10px] 2xl:text-p8-2xl bg-white rounded-button border-1 border-neutral-1 '}
                    />*/}
                    {rsOptions.length>0 && 
                    <><p className='text-[12px] 2xl:text-p8-2xl'>{Idioma.transacciones.configuracion.empresa[language]}</p>
                    <div className='col-span-2'><DinamicDropdown
                        options={rsOptions}
                        selected={scenarioInfo.rs}
                        setSelected={handleScenarioInfoRSChange}
                        className={' w-full  text-[10px] 2xl:text-p8-2xl bg-white rounded-button py-2'}
                    /></div></>}
                    <p className='text-[12px] 2xl:text-p8-2xl'>{Idioma.planeacion.escenario[language]}</p>
                    <input className='col-span-2  bg-white border-1 border-neutral-1 w-[100%] text-[10px] 2xl:text-p8-2xl rounded-button px-2 py-2' value={scenarioInfo.name} onChange={(e)=>setScenarioInfo({...scenarioInfo,name:e.target.value})} placeholder={Idioma.planeacion.escenario[language]}></input>
                    
                  </div>
                  
                  <div className='flex justify-end items-center mt-8'>
                    <button className={` font-bold text-b3-2xl py-2 px-4 w-fit rounded-button ${AddScenarioDisabled ? 'bg-gray-6 ':'bg-light-purple-3'}`} disabled={AddScenarioDisabled} onClick={()=>{handleUpdateScenario()}}>{Idioma.transacciones.desgloseTransaccion.guardar[language]}</button>
                    <button className='bg-secondary font-bold text-b3-2xl py-2 px-4 w-fit ml-2 rounded-button text-white' onClick={()=>{handleCloseModal()}}>{Idioma.transacciones.anadirTransaccionModal.cancelar[language]}</button>
                  </div>
                  <span onClick={()=>handleCloseModal()} className={Styles.modalXmark}><XMark/></span>
                  </div>
                </div>
              </div>}
            {openScenarioModal &&
              <div className='fixed top-0 right-0 left-[15%] bottom-0 w-screen z-[1000] h-screen'>
                <div onClick={()=>handleCloseModal()} className={Styles.modalBackground}>
                </div>
                <div className="bg-white p-[40px] z-10 absolute top-[40%] mt-20  left-[42.5%] shadow-medium-elevation rounded-main -translate-x-[50%] -translate-y-[50%]">
                <div className='2xl:w-[27rem] w-[24rem] h-full relative'>
                  <p className='text-[20px] 2xl:text-h6-2xl font-bold text-left flex items-center overflow-visible'><AddSingleTransactionLogo className='overflow-visible'/> <span className='ml-[15px]'>{Idioma.planeacion.modalAnadir.titulo[language]}</span></p>
                  <div className='grid grid-cols-3 mt-8 2xl:gap-y-4 gap-y-2 items-center'>
                    {/*<p className='text-[12px] 2xl:text-p8-2xl'>{Idioma.transacciones.anadirTransaccionModal.cuenta[language]}</p>
                    <Dropdown
                        options={accountsAddT}
                        selected={accountAddT}
                        setSelected={setAccountAddT}
                        className={' w-full col-span-2 text-[10px] 2xl:text-p8-2xl bg-white rounded-button border-1 border-neutral-1 '}
                    />*/}
                    {rsOptions.length>0 && 
                    <><p className='text-[12px] 2xl:text-p8-2xl'>{Idioma.transacciones.configuracion.empresa[language]}</p>
                    <div className='col-span-2'><DinamicDropdown
                        options={rsOptions}
                        selected={scenarioInfo.rs}
                        setSelected={handleScenarioInfoRSChange}
                        className={' w-full  text-[10px] 2xl:text-p8-2xl bg-white rounded-button py-2'}
                    /></div></>}
                    <p className='text-[12px] 2xl:text-p8-2xl'>{Idioma.planeacion.escenario[language]}</p>
                    <input className='col-span-2  bg-white border-1 border-neutral-1 w-[100%] text-[10px] 2xl:text-p8-2xl rounded-button px-2 py-2' value={scenarioInfo.name} onChange={(e)=>setScenarioInfo({...scenarioInfo,name:e.target.value})} placeholder={Idioma.planeacion.escenario[language]}></input>
                    
                  </div>
                  
                  <div className='flex justify-end items-center mt-8'>
                    <button className={` font-bold text-b3-2xl py-2 px-4 w-fit rounded-button ${AddScenarioDisabled ? 'bg-gray-6 ':'bg-light-purple-3'}`} disabled={AddScenarioDisabled} onClick={()=>{handleAddScenario()}}>{Idioma.transacciones.desgloseTransaccion.guardar[language]}</button>
                    <button className='bg-secondary font-bold text-b3-2xl py-2 px-4 w-fit ml-2 rounded-button text-white' onClick={()=>{handleCloseModal()}}>{Idioma.transacciones.anadirTransaccionModal.cancelar[language]}</button>
                  </div>
                  <span onClick={()=>handleCloseModal()} className={Styles.modalXmark}><XMark/></span>
                  </div>
                </div>
              </div>}
            <h2 className=' text-h9-2xl'>{Idioma.administracion.usuarios[language]}</h2>
            {usersData.length>0 && 
            <div className='max-h-[280px] w-[945px] overflow-y-auto grid-cols-1 grid snap-y mt-[20px] pr-[15px]'>
                {usersData.map((datum)=>(
                    <div className={`flex justify-between border-b-2 border-gray-3 py-[20px] snap-start ${userInfo.email == datum.email ? 'order-first':'order-none'}`}>
                        <div className='flex'>
                            
                            <div className='truncate 2xl:text-p6-2xl text-p6-2xl w-42'>
                                <span className='font-bold 2xl:text-h7-2xl text-h7-2xl capitalize hover:underline cursor-pointer' onClick={()=>{handleUserSelect(datum)}}>{datum.name.toLowerCase()}</span><br/>
                                {datum.email}
                            </div>
                        </div>
                        <div className='flex gap-2 justify-end'>
                            <p className='text-[#b4b4b4]'>
                                {datum.nickname || ''}
                            </p>  
                        </div>
                    </div>
                ))}
                
            </div>}
            {/**/isAdmin ?
            <>
                <div className='flex justify-end w-[930px] overflow-y-scroll pr-1 gap-x-[40px] mt-[20px]'>
                    <button className='flex gap-x-[20px] justify-between hover:underline' onClick={()=>handleOpenNewUser()}>
                        <AddLogo fill={"#000"}/>
                        <p className=' text-p5-2xl '>{Idioma.administracion.anadirUsuarios[language]}</p>
                    </button>
                    <button className='flex gap-x-[20px] justify-between hover:underline' onClick={()=>handleOpenNewClient()}>
                        <AddLogo fill={"#000"}/>
                        <p className=' text-p5-2xl '>{Idioma.administracion.anadirCliente[language]}</p>
                    </button>
                </div>
            </>
            :
            <div className='h-[40px]'></div>/**/}
         <h2 className='text-h9-2xl mt-[20px]'>{Idioma.administracion.empresas[language]}</h2>
            {rsData.length>0 && 
            <div className='max-h-[280px] w-[945px] overflow-y-auto grid-cols-1 grid snap-y mt-[20px] pr-[15px]'>
                {rsData.map((datum)=>(
                    <div className='flex justify-between border-b-2 border-gray-3 py-[20px] snap-start'>
                        <div className='flex'>
                            <div className='truncate 2xl:text-p6-2xl text-p6-2xl w-42'>
                                <span className='font-bold 2xl:text-h7-2xl text-h7-2xl capitalize hover:underline hover:cursor-pointer' onClick={()=>{handleRSSelect(datum)}}>{datum.rsName}</span><br/>
                                {datum.rsTaxID}
                            </div>
                        </div>
                    </div>
                ))}
                
            </div>}
            {!isLoading && rsData.length==0 ?
            <div className='flex justify-between border-b-2 w-[925px] border-gray-3 py-[20px] snap-start pr-[15px] overflow-y-scroll'>
                <div className='flex'>
                    <div className='truncate 2xl:text-p6-2xl text-p6-2xl w-42'>
                        <span className='font-bold 2xl:text-h7-2xl text-[18px] capitalize hover:underline hover:cursor-pointer' onClick={()=>{handleTransitionToRS()}}>{Idioma.administracion.habilitarEmpresas[language]}</span><br/>
                    </div>
                </div>
            </div>
            : !isLoading && rsData.length>0 ? 
            <div className='flex justify-end w-[945px] overflow-y-scroll pr-1 gap-x-[40px] mt-[20px]'>
                    <button className='flex gap-x-[20px] justify-between hover:underline' onClick={()=>handleCreateRS()}>
                        <AddLogo fill={"#000"}/>
                        <p className=' text-p5-2xl '>{Idioma.administracion.anadirEmpresas[language]}</p>
                    </button>
            </div>
            :
            <></>
            }
            {/*<h2 className=' text-h9-2xl mt-[20px]'>{Idioma.planeacion.planeacion[language]}</h2>
            {scenarioData.length>0 && 
            <div className='max-h-[280px] w-[945px] overflow-y-auto grid-cols-1 grid snap-y mt-[20px] pr-[15px]'>
                {scenarioData.map((datum)=>(
                    <div className={`flex justify-between border-b-2 border-gray-3 py-[20px] snap-start`}>
                        <div className='flex'>
                            <div className='truncate 2xl:text-p6-2xl text-p6-2xl w-42'>
                                <span className='font-bold 2xl:text-h7-2xl text-h7-2xl capitalize hover:underline cursor-pointer' onClick={()=>{handleScenarioSelect(datum)}}>{datum.name.toLowerCase()}</span><br/>
                            </div>
                        </div>
                        
                    </div>
                ))}
                
            </div>}
            <div className='flex justify-end w-[930px] overflow-y-scroll pr-1 gap-x-[40px] mt-[20px]'>
                    <button className='flex gap-x-[20px] justify-between hover:underline' onClick={()=>setOpenScenarioModal(true)}>
                        <AddLogo fill={"#000"}/>
                        <p className=' text-p5-2xl '>{Idioma.planeacion.modalAnadir.titulo[language]}</p>
                    </button>
                </div>*/}
        </div>
    )
}

export default AdministrationScreen