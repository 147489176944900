import React,{useState,useEffect,useRef, forwardRef} from 'react'
import { useTable, useFilters, useGlobalFilter, useAsyncDebounce, useSortBy, useRowSelect, useBlockLayout,useResizeColumns } from 'react-table'
import {matchSorter} from 'match-sorter'
import {AdjustmentsHorizontalIcon,PlusCircleIcon ,FunnelIcon} from '@heroicons/react/24/outline'
import Scissors from '../assets/Scissors'
import { ClassificationDropdown, DinamicDropdown, Dropdown, ExcelExport, FileDropdown, Idioma, InfoI, Loading, MessageToast, scrollbarWidth, Styles, DynamicExcelExport, CurrencyOptions, MessageHandler} from '../components';
import { FixedSizeList } from 'react-window'
import moment from 'moment'
import 'moment/locale/es'
import {AiOutlineArrowRight, AiOutlineCloseCircle, AiOutlineSave} from 'react-icons/ai'
import TransaccionesLogo from '../assets/transaccionesLogo'

import DatePicker from 'react-datepicker'
import es from 'date-fns/locale/es'
import { useStateContext } from '../contexts/ContextProvider';

import { useIsAuthenticated } from "@azure/msal-react";
import { useNavigate } from 'react-router-dom';


import { getToken } from '../fetch';

import { urlBase } from '../authConfig';


import "react-datepicker/dist/react-datepicker.css";
import Triangulo from '../assets/Triangulo';
import SmallPreferencesLogo from '../assets/SmallPreferencesLogo';
import ScissorsLogo from '../assets/ScissorsLogo';
import ThrashCanLogo from '../assets/ThrashCanLogo';
import MultiTransactionLogo from '../assets/MultiTransactionLogo';
import SearchLogo from '../assets/SearchLogo';
import SettingsLogo from '../assets/SettingsLogo'
import AddSingleTransactionLogo from '../assets/AddSingleTransactionLogo'
import ResetLogo from '../assets/ResetLogo'
import PlanningTransactionsExcelExport from '../components/PlanningTransactionsExcelExport'
import RestoreRBinLogo from '../assets/RestoreRBinLogo'
import WarningLogo from '../assets/WarningLogo'
import FiltersLogo from '../assets/FiltersLogo'
import AutoclassLogo from '../assets/AutoclassLogo'
import CheckedLogo from '../assets/CheckedLogo'
import TrianguloAbajoLogo from '../assets/TrianguloAbajoLogo'
import XMark from '../assets/XMark'
import RecurrenceLogo from '../assets/RecurrenceLogo'
import CopyScenarioLogo from '../assets/CopyScenarioLogo'
import AddScenarioLogo from '../assets/AddScenarioLogo'
import PlanningLogo from '../assets/PlanningLogo'
import EditScenarioLogo from '../assets/EditScenarioLogo'
import CleaningLogo from '../assets/CleaningLogo'
import { TbCopy } from 'react-icons/tb'





const IndeterminateCheckbox = React.forwardRef(
  ({ indeterminate, ...rest }, ref) => {
    const defaultRef = React.useRef()
    const resolvedRef = ref || defaultRef

    React.useEffect(() => {
      resolvedRef.current.indeterminate = indeterminate
    }, [resolvedRef, indeterminate])
    return (
      <div className='flex items-center justify-center h-full mt-[1px]'>
        <input className='relative peer shrink-0 appearance-none w-[10px] h-[10px] border-0 bg-gray-6 checked:bg-light-purple-3 checked:border-0' type="checkbox" ref={resolvedRef} {...rest} />
        <svg
          className="
            absolute 
            w-[10px] h-[10px] 
            hidden peer-checked:block pointer-events-none"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 24 24"
          fill="none"
          stroke="#fff"
          stroke-width="4"
          stroke-linecap="round"
          stroke-linejoin="round"
        >
          <polyline points="20 6 9 17 4 12"></polyline>
        </svg>
      </div>
    )
  }
)

// Define a default UI for filtering
function GlobalFilter({
  preGlobalFilteredRows,
  globalFilter,
  setGlobalFilter,
}) {
  const count = preGlobalFilteredRows.length
  const [value, setValue] = React.useState(globalFilter)
  const onChange = useAsyncDebounce(value => {
    setGlobalFilter(value || undefined)
  }, 200)
  const {language} = useStateContext()
  const globalFilterRef = useRef()

  return (
    <div className='flex items-center bg-white h-[52px] w-[270px] rounded-button relative border-1 border-neutral-1'>
      <div onClick={()=>globalFilterRef.current.focus()} className= ' cursor-text bg-light-purple-3 h-button-1 flex items-center justify-center w-[52px] absolute object-contain border-1 border-light-purple-3 -right-[1px] rounded-button -top-[1px] dark:text-white dark:bg-secondary-dark-bg '>
        <SearchLogo/>
      </div>
      {' '}
      <input
        ref={globalFilterRef}
        placeholder={Idioma.transacciones.barraBusqueda[language]}
        value={value || ""}
        onChange={e => {
          setValue(e.target.value);
          onChange(e.target.value);
        }}
        style={{
          border: '0',
        }}
        className='dark:bg-secondary-dark-bg bg-transparent font-medium pl-2 w-full h-[52px] focus:outline-none 2xl:text-p5-2xl text-[16px] '
      />
    </div>
  )
}

// Define a default UI for filtering
function DefaultColumnFilter({
  column: { filterValue, preFilteredRows, setFilter },
}) {
  const count = preFilteredRows.length
  const {language} = useStateContext()

  return (
    <input
      value={filterValue || ''}
      className='dark:bg-secondary-dark-bg p-2 bg-white rounded-button w-[calc(100%-16px)] 2xl:text-p9-2xl text-[12px] h-button-4 border-1 border-neutral-1 placeholder:text-neutral-1'
      placeholder={Idioma.transacciones.configuracion.concepto[language]}
      onChange={e => {
        setFilter(e.target.value || undefined) // Set undefined to remove the filter entirely
      }}
    />
  )
}

function NotesColumnFilter({
  column: { filterValue, preFilteredRows, setFilter },
}) {
  const count = preFilteredRows.length

  return (
    <input
      value={filterValue || ''}
      className='dark:bg-secondary-dark-bg p-2 bg-white rounded-button w-[calc(100%-16px)] 2xl:text-p9-2xl text-[12px] h-button-4 border-1 border-neutral-1 placeholder:text-neutral-1'
      placeholder=''
      onChange={e => {
        setFilter(e.target.value || undefined) // Set undefined to remove the filter entirely
      }}
    />
  )
}
// Define a default UI for filtering
function DisabledFilter({
  column: { filterValue, preFilteredRows, setFilter },
}) {
  const count = preFilteredRows.length

  return (
    <div><p className=' invisible'>null</p></div>
  )
}

// This is a custom filter UI for selecting
// a unique option from a list
function SelectColumnFilter({
  column: { filterValue, setFilter, preFilteredRows, id },
}) {
  // Calculate the options for filtering
  // using the preFilteredRows
  const options = React.useMemo(() => {
    const options = new Set()
    preFilteredRows.forEach(row => {
      options.add(row.values[id])
    })
    return [...options.values()]
  }, [id, preFilteredRows])
  const {language} = useStateContext()

  // Render a multi-select box
  return (
    <div className=''><select
      value={filterValue || Idioma.transacciones.filtros.todos[language]}
      className='relative peer dark:bg-secondary-dark-bg appearance-none text-neutral-1 p-2 bg-white rounded-button w-[calc(100%-16px)] 2xl:text-p9-2xl text-[12px] h-button-4 border-1 border-neutral-1 placeholder:text-neutral-1'
      onChange={e => {
        setFilter(e.target.value || "")
      }}
    >
      <option value={""}>{Idioma.transacciones.filtros.todos[language]} </option>
      {options.map((option, i) => (
        <option key={i} value={option}>
          {option}
        </option>
      ))}
    </select>
    <span className='absolute top-[12px] right-[31px] pointer-events-none'>
      <TrianguloAbajoLogo/>
    </span>
    </div>
  )
}

function SubcategorySelectFilter({
  column: { filterValue, setFilter, preFilteredRows, id },
}) {
  // Calculate the options for filtering
  // using the preFilteredRows
  const options = React.useMemo(() => {
    const options = new Set()
    preFilteredRows.forEach(row => {
      options.add(row.values[id])
    })
    return [...options.values()]
  }, [id, preFilteredRows])
  const {language} = useStateContext()


  // Render a multi-select box
  return (
    <select
      value={filterValue || Idioma.transacciones.filtros.todos[language]}
      className='dark:bg-secondary-dark-bg p-2 bg-white rounded-button w-[calc(100%-16px)] 2xl:text-p9-2xl text-[12px] h-button-4 border-1 border-neutral-1 placeholder:text-neutral-1'
      onChange={e => {
        setFilter(e.target.value || "")
      }}
    >
      <option value={""}>{Idioma.transacciones.filtros.todos[language]} </option>
      {options.map((option, i) => (
        <option key={i} value={option}>
          {option}
        </option>
      ))}
    </select>
  )
}

function SliderColumnFilter({
  column: { filterValue, setFilter, preFilteredRows, id },
}) {
  // Calculate the min and max
  // using the preFilteredRows

  const [min, max] = React.useMemo(() => {
    let min = preFilteredRows.length ? preFilteredRows[0].values[id] : 0
    let max = preFilteredRows.length ? preFilteredRows[0].values[id] : 0
    preFilteredRows.forEach(row => {
      min = Math.min(row.values[id], min)
      max = Math.max(row.values[id], max)
    })
    return [min, max]
  }, [id, preFilteredRows])

  return (
    <>
      <input
        type="range"
        min={min}
        max={max}
        value={filterValue || min}
        onChange={e => {
          setFilter(parseInt(e.target.value, 10))
        }}
      />
      <button onClick={() => setFilter(undefined)}>Off</button>
    </>
  )
}

// This is a custom UI for our 'between' or number range
// filter. It uses two number boxes and filters rows to
// ones that have values between the two
function NumberRangeColumnFilter({
  column: { filterValue = [], preFilteredRows, setFilter, id },
}) {
  const [min, max] = React.useMemo(() => {
    let min = preFilteredRows.length ? preFilteredRows[0].values[id] : 0
    let max = preFilteredRows.length ? preFilteredRows[0].values[id] : 0
    preFilteredRows.forEach(row => {
      min = Math.min(row.values[id], min)
      max = Math.max(row.values[id], max)
    })
    return [min, max]
  }, [id, preFilteredRows])
  const {language} = useStateContext()
  return (
    <div
      className='flex h-button-4 w-[calc(100%-16px)]'
    >
      <input
        value={filterValue[0] || ''}
        type='number'
        onChange={e => {
          const val = e.target.value
          setFilter((old = []) => [val ? parseFloat(val, 10) : undefined, old[1]])
        }}
        placeholder={Idioma.transacciones.filtros.min[language]}
        className='w-1/2 mr-2 p-2 bg-white rounded-button 2xl:text-p9-2xl text-[12px] h-button-4 border-1 border-neutral-1 placeholder:text-neutral-1'
      /><input
        value={filterValue[1] || ''}
        type='number'
        onChange={e => {
          const val = e.target.value
          setFilter((old = []) => [old[0], val ? parseFloat(val, 10) : undefined])
        }}
        className='w-1/2 mr-0 p-2 bg-white rounded-button 2xl:text-p9-2xl text-[12px] h-button-4 border-1 border-neutral-1 placeholder:text-neutral-1'
        placeholder={Idioma.transacciones.filtros.max[language]}
      />
    </div>
  )
}

function dateBetweenFilterFn(rows, id, filterValues) {
  const sd = filterValues[0] ? filterValues[0] : undefined
  const ed = filterValues[1] ? filterValues[1] : undefined

  if (ed || sd) {
    return rows.filter(r => {
      let dateArr = r.values[id].split('-')
      const cellDate = new Date(dateArr[0],dateArr[1]-1,dateArr[2])
      
      if (ed && sd) {
        return cellDate >= sd && cellDate <= ed
      } else if (sd){
        return cellDate >= sd
      } else if (ed){
        return cellDate <= ed
      }
    })
  } else {
    return rows
  }
}

function DateRangeColumnFilter({
  column: { filterValue = [], preFilteredRows, setFilter, id }
}) {
  const [min, max] = React.useMemo(() => {
    let min = preFilteredRows.length
      ? new Date(preFilteredRows[0].values[id])
      : new Date(0);
    let max = preFilteredRows.length
      ? new Date(preFilteredRows[0].values[id])
      : new Date(0);

    preFilteredRows.forEach((row) => {
      const rowDate = new Date(row.values[id]);

      min = rowDate <= min ? rowDate : min;
      max = rowDate >= max ? rowDate : max;
    });

    return [min, max];
  }, [id, preFilteredRows]);

  const {language} = useStateContext()
  const [start, end] = filterValue;

  return (
    <div className='w-[calc(100%-16px)]'>
      <DatePicker
        onChange={(e) => {
          const val = e;
          setFilter(e);
        }}
        startDate={start}
        endDate={end}
        selectsRange
        fixedHeight
        locale={language}
        className='p-2 bg-white rounded-button w-full 2xl:text-p9-2xl text-[12px] h-button-4 border-1 border-neutral-1 placeholder:text-neutral-1'
        placeholderText={Idioma.transacciones.configuracion.fecha[language]}
        showYearDropdown
        dropdownMode="select"
        isClearable
        dateFormat={Idioma.formatoFecha[language]}
      />
    </div>
  );
}

function fuzzyTextFilterFn(rows, id, filterValue) {
  return matchSorter(rows, filterValue, { keys: [row => row.values[id]] })
}

// Let the table remove the filter if the string is empty
fuzzyTextFilterFn.autoRemove = val => !val

// Define a custom filter filter function!
function filterGreaterThan(rows, id, filterValue) {
  return rows.filter(row => {
    const rowValue = row.values[id]
    return rowValue >= filterValue
  })
}


// This is an autoRemove method on the filter function that
// when given the new filter value and returns true, the filter
// will be automatically removed. Normally this is just an undefined
// check, but here, we want to remove the filter if it's not a number
filterGreaterThan.autoRemove = val => typeof val !== 'number'

const DeletedTransactionsTable = ({data,recycleBinLoading,setSelectedDeletedTransactions}) => {
    const {language,numberFormat} = useStateContext()
    const textNormal = 'font-bold text-left '
    const textNumber = 'font-bold text-right '
    const textCategory = 'font-bold text-left '
    const columns = React.useMemo(
    () => [/*
        {
            id:'index',
            Header: ' ',
            maxWidth:40,
            minWidth:20,
            width:40,
            className:'text-center',
            accessor: (row, i) => i+1,
        },*/{
            Header: Idioma.transacciones.configuracion.seEliminara[language],
            accessor: 'deleted',
            width:120,
            sortType:'basic',
            Filter:DateRangeColumnFilter,
            filter:dateBetweenFilterFn,
            className:textNormal
        },
        {
            Header: Idioma.transacciones.configuracion.empresa[language],
            accessor: 'rsName',
            width:130,
            Filter: SelectColumnFilter,
            className:textNormal + '',
        },
        {
            Header: Idioma.planeacion.escenario[language],
            accessor: 'scenario',
            width:130,
            Filter: SelectColumnFilter,
            className:textNormal + '',
        },
        {
            Header: Idioma.transacciones.configuracion.fecha[language],
            accessor: 'date',
            width:120,
            sortType:'basic',
            Filter:DateRangeColumnFilter,
            filter:dateBetweenFilterFn,
            className:textNormal
        },/*
        {
            Header: 'ID',
            accessor: 'id', 
        },*/
        {
            Header: Idioma.transacciones.configuracion.concepto[language],
            accessor: 'context',
            width:document.documentElement.clientWidth*.15,
            className:textNormal
        },
        {
            Header: Idioma.transacciones.configuracion.abono[language],
            accessor: 'inflow',
            width:140,
            Filter: NumberRangeColumnFilter,
            filter: 'between',
            className:textNumber
        },
        {
            Header: Idioma.transacciones.configuracion.cargo[language],
            accessor: 'outflow',
            width:140,
            Filter: NumberRangeColumnFilter,
            filter: 'between',
            className:textNumber 
        },
        {
            Header: Idioma.transacciones.configuracion.categoria[language],
            accessor: 'category',
            Filter: SelectColumnFilter,
            width:document.documentElement.clientHeight*.20,
            className:textCategory + ' pl-2 '
        },
        {
            Header: Idioma.transacciones.configuracion.subcategoria[language],
            accessor: 'subcategory',
            width:document.documentElement.clientHeight*.30,
            Filter: SelectColumnFilter,
            className:textCategory + ' pl-2 '
        },{
            Header: Idioma.transacciones.configuracion.moneda[language],
            accessor: 'currency',
            Filter: SelectColumnFilter,
            className:textNormal + '',
        },
        {
            Header: Idioma.transacciones.configuracion.fx[language],
            accessor: 'exchange',
            width:80,
            Filter: NumberRangeColumnFilter,
            filter: 'between',
            className:textNumber  
        },{
            Header: Idioma.transacciones.configuracion.creditoDebito[language],
            accessor: 'paidWith',
            width:140,
            Filter: SelectColumnFilter,
            className:textNormal + ''
        },{
            Header: Idioma.transacciones.configuracion.clientesProvedores[language],
            accessor: 'clientSupplier',
            width:document.documentElement.clientWidth*.20,
            Filter: SelectColumnFilter,
            className:textCategory + ''
        },{
            Header: Idioma.transacciones.configuracion.notas[language],
            accessor: 'notes',
            width:140,
            Filter: NotesColumnFilter,
            className:textNormal + ' pr-4'
        },
    ],
    []
  )
  

  const defaultColumn = React.useMemo(
    () => ({
      // Let's set up our default Filter UI
      Filter: DefaultColumnFilter,
      minWidth: 10, // minWidth is only used as a limit for resizing
    width: 100, // width is used for both the flex-basis and flex-grow
    maxWidth: 1800, // maxWidth is only used as a limit for resizing
    }),
    []
  )

  const createCellOfType = (cell,row) => {
		if(cell.column.id==='exchange'){
			return (
				<div className='text-right relative tabular-nums w-[100%]'>
				<span>{cell.value ? (parseFloat(cell.value)).toLocaleString("en-US", {
				  style: "currency",
				  currency: "USD",
				  currencyDisplay: 'code',
				  minimumSignificantDigits:'4'}).slice(4) : cell.value==0 ? '--' : ''}</span>
				</div>
			)
		}else if(cell.column.id==='inflow'){
			return (
				<div className='text-right relative tabular-nums w-[100%]'>
				<span>{cell.value ? (parseFloat(cell.value)).toLocaleString("en-US", {
				  style: "currency",
				  currency: "USD",
				  currencyDisplay: 'code'}).slice(4) : '--'}</span>
				</div>
			)
		}else if(cell.column.id==='outflow'){
			return (
				<div className='text-right relative tabular-nums w-[100%]'>
				<span>{cell.value ? `${numberFormat===0?'(':'-'}${(parseFloat(cell.value)).toLocaleString("en-US", {
            style: "currency",
            currency: "USD",
            currencySign: "accounting",
            currencyDisplay: 'code'
            }).slice(4)}${numberFormat===0?')':''}` : '--'}</span>
				</div>
			)
		}else if(cell.column.id === 'selection'){
      return  <div className='w-[100%] flex items-center'>
      <IndeterminateCheckbox {...row.getToggleRowSelectedProps()} />
    </div>}
    else if(cell.column.id==='date' || cell.column.id==='deleted'){
			return <div className='w-[100%]'>{moment(cell.value).locale(language).format(language==='en' ?'MMM/DD/YYYY':'DD/MMM/YYYY').replace('.','')}</div> 
		}else{
			return (
				<div className=' w-[100%]' title={cell.value}>
					{cell.value?.length>50 ? cell.value?.slice(0,50) + '...' : cell.value}
				</div>
			)
		}
		
	}

  const filterTypes = React.useMemo(
    () => ({
      // Add a new fuzzyTextFilterFn filter type.
      fuzzyText: fuzzyTextFilterFn,
      // Or, override the default text filter to use
      // "startWith"
      text: (rows, id, filterValue) => {
        return rows.filter(row => {
          const rowValue = row.values[id]
          return rowValue !== undefined
            ? String(rowValue)
                .toLowerCase()
                .startsWith(String(filterValue).toLowerCase())
            : true
        })
      },
    }),
    []
  )

  const scrollBarSize = React.useMemo(() => scrollbarWidth(), [])

  


  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    totalColumnsWidth,
    prepareRow,
    setAllFilters,
    selectedFlatRows,
    allColumns,
    state,
    setSortBy,
    visibleColumns,
    preGlobalFilteredRows,
    setGlobalFilter,
  } = useTable(
    {
      columns,
      data,
      autoResetPage: false,
      autoResetGlobalFilter: false,
      defaultColumn, // Be sure to pass the defaultColumn option
      filterTypes,
      autoResetSortBy:false,
    },
    useFilters, // useFilters!
    useGlobalFilter, // useGlobalFilter!
    useSortBy,
    useRowSelect,
    useBlockLayout,
    useResizeColumns,
    hooks => {
      hooks.visibleColumns.push(columns => [
        // Let's make a column for selection
        {
          id: 'selection',
          width:45,
          // The header can use the table's getToggleAllRowsSelectedProps method
          // to render a checkbox
          Header: ({ getToggleAllRowsSelectedProps }) => (
            <div className='flex items-center'>
              <IndeterminateCheckbox {...getToggleAllRowsSelectedProps()} />
            </div>
          ),
          // The cell can use the individual row's getToggleRowSelectedProps method
          // to the render a checkbox
          Cell: ({ row }) => (
            <div className='flex items-center h-full'>
              <IndeterminateCheckbox {...row.getToggleRowSelectedProps()} />
            </div>
          ),
        },
        ...columns,
      ])
      
    }
  )

  const RenderRow = React.useCallback(
    (rows) => ({ index, style }) => {
      const row = rows[index];
      prepareRow(row);
      return (
        <div
         className={`${row.isSelected && 'bg-light-purple-6'}`}
        {...row.getRowProps({
          style
        })}
        >
          {row.cells.map((cell) => {
            return (
              <div className=''><td {...cell.getCellProps()} className='w-[100%] '>{createCellOfType(cell,row)}</td></div>
            );
          })}
        </div>
      );
    },
    [prepareRow,data]
  );

  useEffect(()=>{
    setSelectedDeletedTransactions(selectedFlatRows)
  },[selectedFlatRows])
  
  return (
  <div className=' block overflow-scroll thin-scrollbar'>
   <table {...getTableProps()} className="table ">
					<thead>
						{headerGroups.map(headerGroup => (
						<tr {...headerGroup.getHeaderGroupProps()} className={' '}>
							{headerGroup.headers.map(column => (
							<th {...column.getHeaderProps()} className={column.className + '  '}>
								 {<div className='text-[10px] mb-2'>{column.canFilter && column.render('Filter')}</div>}
								{column.id=='selection' ?
                            <>
                            <div className=' scale-90 pb-1 pr-1 cursor-pointer invisible' ><ResetLogo/></div>
                              {column.render('Header')} </>:
								<p className={`flex pr-2 text-[12px] 2xl:text-[16px] items-center cursor-pointer ${column.id=='inflow'||column.id=='outflow'||column.id=='exchange'?' flex justify-end':''}`} onClick={()=>{
									const desc =
									column.isSortedDesc === true ?
									undefined :
									column.isSortedDesc === false ?
									true :
									false ;
									if(desc===undefined){
									  setSortBy([/*...sortees*/])
									}else if(desc===true){
									setSortBy([{id:column.id,desc:desc}/*,...sorteesTrue*/])
									}else{
									  setSortBy([{id:column.id,desc:desc}/*,...sortees*/])
									}
								  }} >{column.Header}
								  <span className='ml-1'>
								  {column.isSorted
									? column.isSortedDesc
									  ? <Triangulo/>
									  : <div className='rotate-180'><Triangulo/></div>
									: <></>}
								</span></p>
								}
                {/*
								<div
                  {...column.getResizerProps()}
                  className={` inline-block bg-light-purple-3 w-[5px] h-full absolute right-1 top-0 translate-x-[50%] z-1 touch-none ${
                    column.isResizing ? 'isResizing' : ''
                  }`}
                />*/}
							</th>
							))}
							
						</tr>
						))}
					</thead>

					<tbody {...getTableBodyProps()} className='text-[12px] 2xl:text-[16px]'>
						<FixedSizeList
						height={document.documentElement.clientHeight*.30}
						itemCount={rows.length}
						itemSize={35}
						width={totalColumnsWidth+scrollBarSize}
						>
						{RenderRow(rows)}
						</FixedSizeList>
					</tbody>
					</table></div>)
}

const PlanningTransaction = () => {
  
    const {idClient,accessToken,setToken,startDate,endDate,
        dateRange,setContextSubcategoryId,contextSubcategoryId,
        setContextDateRange,userAccess,idRS,setContextRSID,contextRSID,mainCurrency,language,numberFormat,contextCategoriesId,setContextCategoriesIds,sessionID} = useStateContext()
  
    const navigate = useNavigate();

    const isAuthenticated = useIsAuthenticated();

    const [showState,setContextShowState] = useState('all')
    const [categories,setCategories] = useState([])
    const [categoriesDict,setCategoriesDict] = useState({})
    const [inflowCategories,setInflowCategories] = useState([])
    const [outflowCategories,setOutflowCategories] = useState([])
    const [lastExchange,setLastExchange] = useState({})

    const [subcategories,setSubcategories] = useState([])
    
    const [rsID,setRSID] = useState({name:Idioma.transacciones.configuracion.empresa[language]})
    const [rsOptions,setRSOptions] = useState([])
    const [scenarioOptions,setScenarioOptions] = useState([])
    const [rsDict,setRSDict] = useState({})
    
    const [data,setData] = useState([])
    const [subcategoriesData,setSubcategoriesData] = useState([])
    const [indexToUpdate,setIndexToUpdate] = useState(-1)
    const isFirstRender = useRef(true);
    const [filesData,setFilesData] = useState([])
    const [file,setFile] = useState({name:Idioma.transacciones.seleccionarArchivo[language]})


    const [openRulesModal,setOpenRulesModal] = useState(false)
    const [openPreferencesModal,setOpenPreferencesModal] = useState(false)
    const [openFiltersModal,setOpenFiltersModal] = useState(false)
    const [openTransactionModal,setOpenTransactionModal] = useState(false)
    const [openAddScenario,setOpenAddScenario] = useState(false)
    const [openCopyScenario,setOpenCopyScenario] = useState(false)
    const [openRecurrence,setOpenRecurrence] = useState(false)

    const [transactionQuantities,setTransactionQuantities] = useState([])
    const [selectedTransaction,setSelectedTransaction] = useState([])
    const [transactionId,setTransactionId] = useState(null)
    const [ruleName,setRuleName] = useState('')
    const [ruleText,setRuleText] = useState('')
    const [ruleType,setRuleType] = useState({name:`${Idioma.transacciones.configuracion.cargo[language]}/${Idioma.transacciones.configuracion.cargo[language]}`})
    const [ruleType2,setRuleType2] = useState({name:'Concepto/Monto'})
    const [ruleType3,setRuleType3] = useState({name:'Contiene/No Contiene/Es Exacto'})
    const [ruleSubcategories,setRuleSubcategories] = useState([])
    const [ruleSubcategory,setRuleSubcategory] = useState({name:'Subcategoría'})
    const [ruleCategory,setRuleCategory] = useState({name:'Categoría'})



    const [rsActive,setRSActive] = useState(false)
    const [scenarioActive,setScenarioActive] = useState(false)
    const [dateActive,setDateActive] = useState(true)
    const [contextActive,setContextActive] = useState(true)
    const [inflowActive,setInflowActive] = useState(true)
    const [outflowActive,setOutflowActive] = useState(true)
    const [currencyActive,setCurrencyActive] = useState(false)
    const [fxActive,setFXActive] = useState(false)
    const [paidWithActive,setPaidWithActive] = useState(false)
    const [notesActive,setNotesActive] = useState(false)
    const [clientSupplierActive,setClientSupplierActive] = useState(false)
    const [additionalInfoActive,setAdditionalInfoActive] = useState(false)
    const [extraColumnsActive,setExtraColumnsActive] = useState({})


    const [scenarioFilter,setScenarioFilter] = useState({name:Idioma.planeacion.escenario[language]})

    const [filtersActive,setFiltersActive] = useState(false)

    const [forexValue,setForexValue] = useState(null)

    const [selectedSubcategory,setSelectedSubcategory] = useState()

    const [isLoading,setIsLoading] = useState(true)
    const [isMultiUpdateLoading,setIsMultiUpdateLoading] = useState(false)
    
    

    //const [account,setAccount] = useState({name:Idioma.transacciones.configuracion.cuentaBanco[language]});
    const [info,setInfo] = useState()

    const [updateCounter,setUpdateCounter] = useState(0)
    const [showTransactionsUpdated, setShowTransactionsUpdated] = useState(false)
    const [updatedTransactions,setUpdatedTransactions] = useState(0)
    const [showTransactionsError,setShowTransactionsError] = useState(false)
    const [unaffectedTransactions,setUnaffectedTransactions] = useState(0)

    const [toastList, setToastList] = useState([])


    const [openDeleteTransactionsWarning,setOpenDeleteTransactionsWarning] = useState(false)
    const [errorSign,setErrorSign] = useState(false)

    const [openAddTransaction,setOpenAddTransaction] = useState(false)
    const [dateAddT,setDateAddT] = useState(new Date());
    const [conceptAddT,setConceptAddT] = useState('')
    const [inflowAddT,setInflowAddT] = useState()
    const [outflowAddT,setOutflowAddT] = useState()
    const [categoryAddT,setCategoryAddT] = useState()
    const [currencyAddT,setCurrencyAddT] = useState({name:mainCurrency,id:mainCurrency})
    const [paidWithAddT,setPaidWithAddT] = useState({name:Idioma.transacciones.debito[language],id:'D'})
    const [scenarioAddT,setScenarioAddT] = useState({name:Idioma.planeacion.seleccionaEscenario[language]})
    const [recurrenceAddT,setRecurrenceAddT] = useState({name:Idioma.planeacion.seleccionaRecurrencia[language]})
    const [exchangeAddT,setExchangeAddT] = useState()
    const [clientSupplierAddT,setClientSupplierAddT] = useState({name:Idioma.planeacion.seleccionaClienteProveedor[language]})
    const [rsAddT,setRSAddT] = useState({name:Idioma.planeacion.seleccionaEmpresa[language]})
    const [splitTransactionLoading, setSplitTransactionLoading] = useState(false)
    const [addTransactionLoading, setAddTransactionLoading] = useState(false)

    const [nameAddScenario,setNameAddScenario] = useState('')
    const [rsAddScenario,setRSAddScenario] = useState({name:Idioma.planeacion.seleccionaEmpresa[language]})

    const [copyScenario,setCopyScenario] = useState({name:Idioma.planeacion.seleccionaEscenario[language]})
    const [recurrence,setRecurrence] = useState({name:Idioma.planeacion.seleccionaRecurrencia[language]})
    const [recurrenceEndDate,setRecurrenceEndDate] = useState(new Date())
    const recurrenceEndDateLimit = new Date()
    const [recurrenceEndDateMinLimit,setRecurrenceEndDateMinLimit] = useState()

    const [recurrenceRepeats,setRecurrenceRepeats] = useState()
    const [recurrenceRepeatsLimit,setRecurrenceRepeatsLimit] = useState()


    const [clientOptions,setClientOptions] = useState([])
    const [supplierOptions,setSupplierOptions] = useState([])
    const [clientSupplierOptions,setClientSupplierOptions] = useState([])
    const [clientSupplierDict,setClientSupplierDict] = useState({})

    const [totals,setTotals] = useState({})

    const [openDeletedTransactions,setOpenDeletedTransactions] = useState(false)
    const [deletedData,setDeletedData] = useState([])
    const [selectedDeletedTransactions,setSelectedDeletedTransactions] = useState([])
    const [recycleBinLoading,setRecycleBinLoading] = useState(false)

    const [openEditScenario,setOpenEditScenario] = useState(false)

    const [openSendToRecycleBinWarning,setOpenSendToRecycleBinWarning] = useState(false)
    const [openCleanTransactionsWarning,setOpenCleanTransactionsWarning] = useState(false)

    const [additionalColumns,setAdditionalColumns] = useState({})

    const [MLInfo,setMLInfo] = useState({})
    const [isAutoClassing,setIsAutoClassing] = useState(false)
    const [amountAutoClassing,setAmountAutoClassing] = useState(0)
    const hasEditionAcess = userAccess && userAccess.access3 ? 1 : 0

    const [recurrenceOptions,setRecurrenceOptions] = useState([{
        name:Idioma.planeacion.opcionesRecurrencia.noSeRepite[language],
        id:'none'
    },
    {
        name:Idioma.planeacion.opcionesRecurrencia.diario[language],
        id:'daily'
    },
    {
        name:`${Idioma.planeacion.opcionesRecurrencia.semanal[language]} ${moment(selectedTransaction?.original?.date).locale(language).format('dddd')}`,
        id:'weeklyOnDD'
    }])


    const AddTransactionDisabled = conceptAddT.length===0 || !(outflowAddT || inflowAddT) || !(scenarioAddT.id) || (!idRS && rsOptions.length>0 && !rsAddT.id) || !(recurrence.id && (recurrence.id=='none' || recurrenceRepeats>0 || recurrenceEndDate))
    const AddScenarioDisabled = nameAddScenario.length===0
    const copyScenarioDisabled = !(copyScenario.id)
    const recurrenceDisabled = !(recurrence.id)
    
    const recurrenceOptionsAddT = [
        {
            name:Idioma.planeacion.opcionesRecurrencia.noSeRepite[language],
            id:'none'
        },
        {
            name:Idioma.planeacion.opcionesRecurrencia.diario[language],
            id:'daily'
        },
        {
            name:`${Idioma.planeacion.opcionesRecurrencia.semanal[language]} ${moment(dateAddT).locale(language).format('dddd')}`,
            id:'weeklyOnDD'
        },
        {
            name:`${Idioma.planeacion.opcionesRecurrencia.mensual[language]} ${parseInt((dateAddT.getDate()-1)/7+1)<=4?Idioma.nums[`${parseInt((dateAddT.getDate()-1)/7+1)}s`][language]:Idioma.nums.ultimo[language]} ${moment(dateAddT).locale(language).format('dddd')}`,
            id:'monthlyOnNDD'
        },
        {
            name:Idioma.planeacion.opcionesRecurrencia.personalizado[language],
            id:'custom'
        },
    ]

    

    const creditDebitOptions = [
        {
            name:Idioma.transacciones.credito[language],
            id:'C'
        },
        {
            name:Idioma.transacciones.debito[language],
            id:'D'
        },
    ]
  
      useEffect(() => {
          if(!isAuthenticated){
              navigate('/')
              return
          }
          getToken().then((token)=>{setToken(token)})
          let headers = new Headers()
  
          const bearer = `Bearer ${accessToken}`;
          headers.append("Authorization", bearer);
          headers.append("Content-Type",'application/json')
    
          if(localStorage.getItem('planningColumnPreferences')){
            
            let aux = localStorage.getItem('planningColumnPreferences')
            let aux1 = JSON.parse(aux)
            setRSActive(aux1['rsActive'])
            setScenarioActive(aux1['scenarioActive'])
            setDateActive(aux1['dateActive'])
            setContextActive(aux1['contextActive'])
            setInflowActive(aux1['inflowActive'])
            setOutflowActive(aux1['outflowActive'])
            setFXActive(aux1['fxActive'])
            setPaidWithActive(aux1['paidWithActive'])
            setNotesActive(aux1['notesActive'])
            setClientSupplierActive(aux1['clientSupplierActive'])
            setCurrencyActive(aux1['currencyActive'])
            //setAdditionalInfoActive(aux1['additionalInfoActive'])
            if(!aux1['rsActive']){
              allColumns[0+hasEditionAcess].toggleHidden()
            }
            if(!aux1['scenarioActive']){
                allColumns[1+hasEditionAcess].toggleHidden()
              }
            if(!aux1['dateActive']){
              allColumns[2+hasEditionAcess].toggleHidden()
            }
            if(!aux1['contextActive']){
              allColumns[3+hasEditionAcess].toggleHidden()
            }
            if(!aux1['inflowActive']){
              allColumns[4+hasEditionAcess].toggleHidden()
            }
            if(!aux1['outflowActive']){
              allColumns[5+hasEditionAcess].toggleHidden()
            }
            if(!aux1['currencyActive']){
              allColumns[8+hasEditionAcess].toggleHidden()
            }
            if(!aux1['fxActive']){
              allColumns[9+hasEditionAcess].toggleHidden()
            }
            if(!aux1['paidWithActive']){
              allColumns[10+hasEditionAcess].toggleHidden()
            }
            if(!aux1['clientSupplierActive']){
              allColumns[11+hasEditionAcess].toggleHidden()
            }
            if(!aux1['notesActive']){
              allColumns[12+hasEditionAcess].toggleHidden()
            }
            /*if(!aux1['additionalInfoActive']){
              allColumns[13+hasEditionAcess].toggleHidden()
            }*/
          }else{
            allColumns[0+hasEditionAcess].toggleHidden() //Empresa
            allColumns[1+hasEditionAcess].toggleHidden() //Escenario
            allColumns[8+hasEditionAcess].toggleHidden() //moneda
            allColumns[9+hasEditionAcess].toggleHidden() //fx
            allColumns[10+hasEditionAcess].toggleHidden() //credito o debito
            allColumns[11+hasEditionAcess].toggleHidden() //clientes proveedores
            allColumns[12+hasEditionAcess].toggleHidden() //notas
            //allColumns[13+hasEditionAcess].toggleHidden() //informacion adicional
          }
          let body = {
            "clientID": idClient,
            logsInfo:{
              origin:'planningTransactions',
              sessionID:sessionID
            }
          }
          if(idRS){
            body.idRS = idRS
          }
          fetch(`${urlBase}/getCategories`,{
            method: 'POST',
            headers: headers,
            body: JSON.stringify({
              "clientID": idClient,
              "lowest": 1,
              showHidden: true,
              planning: true,
              logsInfo:{
                origin:'planningTransactions',
                sessionID:sessionID
            }
          })
          })
            .then((response) => response.json())
            .then((aux) => {
              let catDict = {}
              let newData = []
              let inflowCategoriesData = []
              let outflowCategoriesData = []
              let moreData = []
              for(let i = 0;i<aux.length;i++){
                newData[i] = {
                  'name' : aux[i].category,
                  'idCategory' : aux[i].idCategory,
                  'index': i
                }
                if(aux[i].flowType=='+'){
                  inflowCategoriesData[i] = newData[i]
                }else {
                  outflowCategoriesData[i] = newData[i]
                }
                catDict[aux[i].idCategory] = {
                  "name": aux[i].category,
                  "isActive" : aux[i].isActive === true
                }
                moreData[i]=[]
                for(let j = 0;j<aux[i].subcategories.length;j++){
                  if(aux[i].subcategories[j].isActive){
                    moreData[i][moreData[i].length] = {
                      'name':aux[i].subcategories[j].category,
                      'idSubCategory':aux[i].subcategories[j].idCategory,
                      'idFather': aux[i].idCategory
                    }
                  }
                  catDict[aux[i].subcategories[j].idCategory] = {
                    "name": aux[i].subcategories[j].category,
                    "isActive" : aux[i].subcategories[j].isActive === true,
                    "idFather" : aux[i].subcategories[j].idCategoryFather
                  }
                }
              }
              setInflowCategories(inflowCategoriesData)
              setOutflowCategories(outflowCategoriesData)
              setCategories(newData)
              setSubcategories(moreData)
              setCategoriesDict(catDict)
          });
          fetch(`${urlBase}/getClientSupplier`,{
            method: 'POST',
            headers: headers,
            body: JSON.stringify(body)
          })
            .then((response) => response.json())
            .then((aux) => {
              setClientOptions(aux.clients)
              setSupplierOptions(aux.suppliers)
              let aux1 = []
              let auxDict = {}
              for(let i=0;i<aux.clients.length;i++){
                aux1[aux1.length] = {
                    'name':aux.clients[i].clientSupplierName,
                    'id':aux.clients[i].idClientSupplier,
                }
                auxDict[aux.clients[i].idClientSupplier] = {
                  'name':aux.clients[i].clientSupplierName,
                  "isActive" : aux.clients[i].isActive === true,
                }
              }
              for(let i=0;i<aux.suppliers.length;i++){
                aux1[aux1.length] = {
                    'name':aux.suppliers[i].clientSupplierName,
                    'id':aux.suppliers[i].idClientSupplier,
                }
                auxDict[aux.suppliers[i].idClientSupplier] = {
                  'name':aux.suppliers[i].clientSupplierName,
                  "isActive" : aux.suppliers[i].isActive === true,
                }
              }
              setClientSupplierOptions(aux1)
              setClientSupplierDict(auxDict)
              
          });
          fetch(`${urlBase}/getRS`, {
              method: 'POST',
              headers: headers,
              body: JSON.stringify(body)
          })
          .then((res)=>res.json())
          .then((aux)=>{
              let newData = []
              let auxDict = {}
              for(let i=0;i<aux.length;i++){
                newData[i]={
                    name:aux[i].rsAlias,
                    id:aux[i].idRS
                }
                auxDict[aux[i].idRS] = {
                  name:aux[i].rsAlias
                }
              }
              setRSOptions(newData)
              setRSDict(auxDict)
              if(aux.length==0 && localStorage.getItem('planningColumnPreferences')){
                let aux1 = localStorage.getItem('planningColumnPreferences')
                aux1=JSON.parse(aux1)
                if(aux1['rsActive']==true){
                    allColumns[0+hasEditionAcess].toggleHidden()
                }
              }
          })
          fetch(`${urlBase}/getColumns`,{
            method: 'POST',
            headers: headers,
            body: JSON.stringify(body)
          })
            .then((response) => response.json())
            .then((aux) => {            
              setAdditionalColumns(aux[0])
          });
          fetch(`${urlBase}/getLastExchange`, {
            method: 'POST',
            headers: headers,
            body: JSON.stringify({
              "clientID": idClient,
              mainCurrency:mainCurrency,
              logsInfo:{
                origin:'planningTransactions',
                sessionID:sessionID
            }
            })
        })
        .then((res)=>res.json())
        .then((aux)=>{
            setLastExchange(aux)
        })
          getScenarioData()
        return () => {
        }
      },[])
  
      useEffect(()=>{
        localStorage.setItem('planningColumnPreferences',JSON.stringify({
            'rsActive':rsActive,
            'scenarioActive':scenarioActive,
            'dateActive':dateActive,
            'contextActive':contextActive,
            'inflowActive':inflowActive,
            'outflowActive':outflowActive,
            'fxActive':fxActive,
            'paidWithActive':paidWithActive,
            'notesActive':notesActive,
            'clientSupplierActive':clientSupplierActive,
            'currencyActive':currencyActive,
            'additionalInfoActive':additionalInfoActive
        }))
      },[rsActive,scenarioActive,dateActive,contextActive,inflowActive,outflowActive,fxActive,paidWithActive,notesActive,clientSupplierActive,currencyActive,additionalInfoActive])
  
    const getScenarioData = () => {
        let headers = new Headers()
  
        const bearer = `Bearer ${accessToken}`;
        headers.append("Authorization", bearer);
        headers.append("Content-Type",'application/json')
        let body = {
            "clientID": idClient,
            logsInfo:{
              origin:'planningTransactions',
              sessionID:sessionID
          }
        }
        if(idRS){
            body.idRS = idRS
        }
        fetch(`${urlBase}/planning/getScenarios`, {
            method: 'POST',
            headers: headers,
            body: JSON.stringify(body)
        })
        .then((res)=>res.json())
        .then((aux)=>{
            let newData = []
            for(let i=0;i<aux.length;i++){
                newData[i]={
                    name:       aux[i].name,
                    id:         aux[i].idScenario,
                    notes:      aux[i].notes,
                    createdBy:  aux[i].createdBy,
                    minDate:    aux[i].minDate ? aux[i].minDate.slice(0,10) : null,
                    maxDate:    aux[i].maxDate ? aux[i].maxDate.slice(0,10) : null,
                    userName:   aux[i].username
                }
            }
            setScenarioOptions(newData)
        })
    }

  
    const getTransactions = (type) => {
        setData([])
        setIsLoading(true)
        getToken().then((token)=>{setToken(token)})
        if(!scenarioFilter.id){
            setIsLoading(false)
            return
        }
        let headers = new Headers()
  
        const bearer = `Bearer ${accessToken}`;
        headers.append("Authorization", bearer);
        headers.append("Content-Type",'application/json')
        let body = {
            "clientID": idClient,
            "showState":showState,
            "currency":mainCurrency,
            "idScenario":scenarioFilter.id,
            logsInfo:{
              origin:'planningTransactions',
              sessionID:sessionID
          }
        }
        /*if(type==='file' || (type==='' && file.id)){
            body.fileID = file.id
        }
        if(contextSubcategoryId>0){
            body.categoryID = contextSubcategoryId
        }
        if(contextRSID.length>0){
            body.rsIDs = contextRSID
        }
        if(contextCategoriesId.length>0){
            body.categories = contextCategoriesId
        }
        if(type==='dates' || (type==='' && startDate && endDate)){
            let year = startDate.getFullYear().toString()
            let month = startDate.getMonth()+1
            let day = startDate.getDate()
            if(day<10){
                day = '0'+day.toString()
            }else{
                day = day.toString()
            }
            if(month<10){
                month = '0'+month.toString()
            }else{
                month = month.toString()
            }
            let fechaInicial = month+'-'+day+'-'+year
            year = endDate.getFullYear().toString()
            month = endDate.getMonth()+1
            day = endDate.getDate()
            if(day<10){
                day = '0'+day.toString()
            }else{
                day = day.toString()
            }
            if(month<10){
                month = '0'+month.toString()
            }else{
                month = month.toString()
            }
            let fechaFinal = month+'-'+day+'-'+year
            body.startDate = fechaInicial
            body.endDate =  fechaFinal
        }*/
        if(idRS){
            body.idRS = idRS
        }
        if(rsID.id){
            body.rsID = rsID.id
        }
        fetch(`${urlBase}/planning/getTransactions`,{
            method: 'POST',
            headers: headers,
            body: JSON.stringify(body)
        })
        .then((response) => response.json())
        .then((aux) => {
            let newData = []
            let extraColumns = new Set([])
            let extColsActive = {}
            let colAux = []
            for(let i = 0;i<aux.length;i++){
                newData[i] = {
                    'date':aux[i].date_d.slice(0,10),
                    'id':aux[i].idTransaction,
                    'scenario':aux[i].name,
                    'context':aux[i].context,
                    'reference':aux[i].references_c,
                    'inflow':aux[i].inflow ,
                    'outflow':aux[i].outflow,
                    'category':categoriesDict[categoriesDict[aux[i].idCategory]?.idFather]?.name || '',
                    'idCategory':categoriesDict[aux[i].idCategory]?.idFather || '',
                    'subcategory':categoriesDict[aux[i].idCategory]?.name || '',
                    'idSubCategory':aux[i].idCategory,
                    'exchange':aux[i].exchange?aux[i].exchange:aux[i].defaultFX?aux[i].defaultFX:aux[i].currency!=mainCurrency ? lastExchange[aux[i].currency]:null,
                    "updated":null,
                    'paidWith':aux[i].paidWith==='D'?Idioma.transacciones.debito[language]:aux[i].paidWith==='C' ? Idioma.transacciones.credito[language] :'NA',
                    'signature':aux[i].transactionSignature,
                    'notes': aux[i].notes,
                    'idClientSupplier':aux[i].idClientSupplier,
                    'clientSupplier':clientSupplierDict[aux[i].idClientSupplier]?.name,
                    'currency': aux[i].currency,
                    'rsName': aux[i].rsAlias,
                    'extraText': aux[i].extraText
                }
                if(aux[i].extraText){
                    let json = JSON.parse(aux[i].extraText)
                    let auxExtra = Object.keys(json) 
                    for(let j=0;j<auxExtra.length;j++){
                      extraColumns.add(auxExtra[j])
                      newData[i][auxExtra[j]] = json[auxExtra[j]]
                    }
                }
            }
            extraColumns.forEach((val1,val2,set)=>{
                colAux[colAux.length]={
                    Header: additionalColumns[val1] ? additionalColumns[val1] : val1,
                    accessor: val1,
                    width:document.documentElement.clientWidth*.15,
                    Filter: NotesColumnFilter,
                    className:textNormal + ' '
                }
                extColsActive[val1] = true 
            })
            setColumns([...initialColumns,...colAux])
            setExtraColumnsActive(extColsActive)
            setData(newData)
            setIsLoading(false)
        });
    }
      
    useEffect(()=>{
        if(rsID.id && subcategories.length!==0){
            setFile({'name':Idioma.transacciones.seleccionarArchivo[language]})
        }
    },[rsID])
  
    useEffect(() => {
        if((file.id||file.name===Idioma.transacciones.filtros.todos[language]) && subcategories.length!==0){
            setRSID({name:Idioma.transacciones.configuracion.empresa[language]})
            setContextDateRange([null,null])
            //setData([])
            //setIsLoading(true)
            //getTransactions('')
        }
    },[file])

    useEffect(()=>{
        if((scenarioFilter.id) && subcategories.length!==0){
            setFile({'name':Idioma.transacciones.seleccionarArchivo[language]})
            setRSID({name:Idioma.transacciones.configuracion.empresa[language]})
            getTransactions('')
        }
    },[scenarioFilter])
  
    useEffect(() => {
        if(startDate!=null && endDate!=null){
            //setData([])
            setFile({'name':Idioma.transacciones.seleccionarArchivo[language]})
            //setIsLoading(true)
            //getTransactions('')
        }else if(!file.id){
            //getTransactions('')
        }
    },[dateRange])
  
    useEffect(() => {
        if(subcategories.length!==0 && Object.keys(categoriesDict).length>0){
            setIsLoading(true)
            getTransactions('')
        }
    }, [subcategories,categoriesDict,clientSupplierDict,contextSubcategoryId,contextRSID,contextCategoriesId,showState])
  
    useEffect(()=>{
        if (indexToUpdate<0 || data.length<=0) {
            isFirstRender.current = false;
            return; // 👈️ return early if initial render
        }
    },[data])
  
    const updateTransaction = async (index,subCategoryId) => {
        let headers = new Headers()

        const bearer = `Bearer ${accessToken}`;
        headers.append("Authorization", bearer);
        headers.append("Content-Type",'application/json')
          
    
        if(index>=0){
          let body = {
            "clientID": idClient,
            "transactions": [data[index].id],
            "categoryID": subCategoryId,
            logsInfo:{
              origin:'planningTransactions',
              sessionID:sessionID
          }
        }
        if(idRS){
          body.idRS = idRS
        }
            fetch(`${urlBase}/planning/updateTransaction`,{
                method: 'POST',
                headers: headers,
                body: JSON.stringify(body)
            })
            .then((response) => response.json())
            .then((aux) => {
            setIndexToUpdate(-1)
            if(aux.updates.length>0){
              newToast('success', Idioma.transacciones.transActualizadas[language], aux.updates.length)
            }
            if(aux.unaffected.length >0){
              newToast('error', Idioma.transacciones.transNoActualizadas[language], aux.unaffected.length)
            }
            //setShowTransactionsUpdated(aux.updates.length>=1)
            //setUpdatedTransactions(aux.updates.length)
            if(showState==='nonClassified'){
                setData(prev => prev.filter(datum => datum.id!==data[index].id))
            }else{
                setData(current=> 
                    current.map(obj => {
                    if (obj.id === data[index].id) {
                        return {...obj,updated:true}
                    }
                    return obj;
                    }),
                )}
            })
            .catch(error=>{
            alert('Ha ocurrido un error')
            })
        }
    }
  
    const updateTransactionConcept = async (index,concept) => {
        let headers = new Headers()
  
        const bearer = `Bearer ${accessToken}`;
        headers.append("Authorization", bearer);
        headers.append("Content-Type",'application/json')
    

        if(index>=0){
          let body = {
            "clientID": idClient,
            "transactions": [data[index].id],
            "concept": concept,
            logsInfo:{
              origin:'planningTransactions',
              sessionID:sessionID
          }
        }
          if(idRS){
            body.idRS = idRS
          }
            fetch(`${urlBase}/planning/updateTransaction`,{
                method: 'POST',
                headers: headers,
                body: JSON.stringify(body)
            })
            .then((response) => response.json())
            .then((aux) => {
            setIndexToUpdate(-1)
            if(aux.updates.length>0){
              newToast('success', Idioma.transacciones.transActualizadas[language], aux.updates.length)
            }
            if(aux.unaffected.length >0){
              newToast('error', Idioma.transacciones.transNoActualizadas[language], aux.unaffected.length)
            }
            //setShowTransactionsUpdated(aux.updates.length>=1)
            //setUpdatedTransactions(aux.updates.length)
            setUpdateCounter((prevValue)=>prevValue+1)
            })
            .catch(error=>{
            alert('Ha ocurrido un error')
            })
        }
    }

    const updateTransactionNotes = async (index,notes) => {
        let headers = new Headers()
  
        const bearer = `Bearer ${accessToken}`;
        headers.append("Authorization", bearer);
        headers.append("Content-Type",'application/json')
   
    
        if(index>=0){
          let body = {
            "clientID": idClient,
            "transactions": [data[index].id],
            "notes":notes,
            logsInfo:{
              origin:'planningTransactions',
              sessionID:sessionID
          }
        }
          if(idRS){
            body.idRS = idRS
          }
            fetch(`${urlBase}/planning/updateTransaction`,{
                method: 'POST',
                headers: headers,
                body: JSON.stringify(body)
            })
            .then((response) => response.json())
            .then((aux) => {
                setIndexToUpdate(-1)
                if(aux.updates.length>0){
                  newToast('success', Idioma.transacciones.transActualizadas[language], aux.updates.length)
                }
                if(aux.unaffected.length >0){
                  newToast('error', Idioma.transacciones.transNoActualizadas[language], aux.unaffected.length)
                }
                //setShowTransactionsUpdated(aux.updates.length>=1)
                //setUpdatedTransactions(aux.updates.length)
                setUpdateCounter((prevValue)=>prevValue+1)
            })
            .catch(error=>{
                alert('Ha ocurrido un error')
            })
        }
    }
  
      
  
      const multiTransactionUpdate = (subCategoryId) => {
        setIsMultiUpdateLoading(true)
        let headers = new Headers()
  
          const bearer = `Bearer ${accessToken}`;
          headers.append("Authorization", bearer);
          headers.append("Content-Type",'application/json')
          
  
          let saveIndexes = {}
          let allTransactionIds = []
          for( const property in selectedRowIds){
            if(selectedRowIds[property]){
              allTransactionIds[allTransactionIds.length]=data[property].id
              saveIndexes[data[property].id]=property
            }
            
          }
          let body = {
            "clientID": idClient,
            "transactions": allTransactionIds,
            "categoryID": subCategoryId,
            logsInfo:{
              origin:'planningTransactions',
              sessionID:sessionID
          }
        }
          if(idRS){
            body.idRS = idRS
          }
  
            fetch(`${urlBase}/planning/updateTransaction`,{
                method: 'POST',
                headers: headers,
                body: JSON.stringify(body)
            })
            .then((response) => response.json())
            .then((aux) => {
              if(aux.updates.length>0){
                newToast('success', Idioma.transacciones.transActualizadas[language], aux.updates.length)
              }
              if(aux.unaffected.length >0){
                newToast('error', Idioma.transacciones.transNoActualizadas[language], aux.unaffected.length)
              }
            //setShowTransactionsUpdated(aux.updates.length>=1)
            //setUpdatedTransactions(aux.updates.length)
            //setErrorSign(aux.unaffected.length >= 1)
            //setShowTransactionsError(aux.unaffected.length >= 1)
            //setUnaffectedTransactions(aux.unaffected)
            let auxData = [...data]
            if(showState=='classified' || showState=='all'){
              
              for(let i=0;i<aux.updates.length;i++){
                auxData[saveIndexes[aux.updates[i]]].category = categoriesDict[categoriesDict[subCategoryId]?.idFather]?.name || '',
                auxData[saveIndexes[aux.updates[i]]].idCategory = categoriesDict[subCategoryId]?.idFather || '',
                auxData[saveIndexes[aux.updates[i]]].subcategory = categoriesDict[subCategoryId]?.name || '',
                auxData[saveIndexes[aux.updates[i]]].idSubCategory = subCategoryId
              }
              setData(auxData)
            }else{
              for(let i=0;i<aux.updates.length;i++){//mejorar esta parte UPGRADE
                setData(prev => prev.filter(datum => datum.id!==aux.updates[i]))
              }
            }
            setIsMultiUpdateLoading(false)
            })
            .catch(error=>{
              alert('Ha ocurrido un error')
            setIsMultiUpdateLoading(false)
  
            })
      }
  
      const multiDeleteTransaction = async () => {
        setOpenSendToRecycleBinWarning(false)

          let headers = new Headers()
          const bearer = `Bearer ${accessToken}`;
          headers.append("Authorization", bearer);
          headers.append("Content-Type",'application/json')
  
          let allTransactionIds = []
          for(let i=0;i<selectedFlatRows.length; i++){
            allTransactionIds[i]=selectedFlatRows[i].original.id
          }
          let body = {
            "clientID": idClient,
            "transactions": allTransactionIds,
            logsInfo:{
              origin:'planningTransactions',
              sessionID:sessionID
          }
        }
          if(idRS){
            body.idRS = idRS
          }

          fetch(`${urlBase}/planning/moveToRecycleBin`,{
              method: 'POST',
              headers: headers,
              body: JSON.stringify(body)
          })
          .then((response) => response.json())
          .then((aux) => {
          setErrorSign(aux.unaffected.length >= 1)
          setShowTransactionsError(aux.unaffected.length >= 1)
          setUnaffectedTransactions(aux.unaffected)
          for(let i=0;i<aux.deleted.length;i++){
            setData(prev => prev.filter(datum => datum.id!==aux.deleted[i]))
          }
          setOpenDeleteTransactionsWarning(false)
          })
          .catch(error=>{
            alert('Ha ocurrido un error')
          })
      }
  
    const updateTransactionExchange = async (index,forex) => {
        let headers = new Headers()
  
        const bearer = `Bearer ${accessToken}`;
        headers.append("Authorization", bearer);
        headers.append("Content-Type",'application/json')


        if(index>=0){
          let body = {
            "clientID": idClient,
            "transactions" : [data[index].id],
            "exchange": forex,
            logsInfo:{
              origin:'planningTransactions',
              sessionID:sessionID
          }
        }
          if(idRS){
            body.idRS = idRS
          }
            fetch(`${urlBase}/planning/updateTransaction`,{
                method: 'POST',
                headers: headers,
                body: JSON.stringify(body)
            })
            .then((response) => response.json())
            .then((aux) => {

            setIndexToUpdate(-1)
            if(aux.updates.length>0){
              newToast('success', Idioma.transacciones.transActualizadas[language], aux.updates.length)
            }
            if(aux.unaffected.length >0){
              newToast('error', Idioma.transacciones.transNoActualizadas[language], aux.unaffected.length)
            }
            //setShowTransactionsUpdated(aux.updates.length>=1)
            //setUpdatedTransactions(aux.updates.length)
            //setErrorSign(aux.unaffected.length >= 1)
            //setShowTransactionsError(aux.unaffected.length >= 1)
            //setUnaffectedTransactions(aux.unaffected)
            })
            .catch(error=>{
            alert('Ha ocurrido un error')
            })
        }
      }
  
    const updateTransactionClientSupplier = async (index,idClientSupplier) => {
        let headers = new Headers()
        const bearer = `Bearer ${accessToken}`;
        headers.append("Authorization", bearer);
        headers.append("Content-Type",'application/json')
        if(index>=0){
          let body = {
            "clientID": idClient,
            "transactions" : [data[index].id],
            "idClientSupplier": idClientSupplier,
            logsInfo:{
              origin:'planningTransactions',
              sessionID:sessionID
          }
        }
          if(idRS){
            body.idRS = idRS
          }
            fetch(`${urlBase}/planning/updateTransaction`,{
                method: 'POST',
                headers: headers,
                body: JSON.stringify(body)
            })
            .then((response) => response.json())
            .then((aux) => {
            setIndexToUpdate(-1)
            if(aux.updates.length>0){
              newToast('success', Idioma.transacciones.transActualizadas[language], aux.updates.length)
            }
            if(aux.unaffected.length >0){
              newToast('error', Idioma.transacciones.transNoActualizadas[language], aux.unaffected.length)
            }
            //setShowTransactionsUpdated(aux.updates.length>=1)
            //setUpdatedTransactions(aux.updates.length)
            //setErrorSign(aux.unaffected.length >= 1)
            //setShowTransactionsError(aux.unaffected.length >= 1)
            //setUnaffectedTransactions(aux.unaffected)
            
            })
            .catch(error=>{
                alert('Ha ocurrido un error')
            })
        }
    }
   //Funcion para actualizar el monto de una transaccion
      const updateTransactionAmount = async (index,inflow,outflow,clear) => {
        let headers = new Headers()
  
        const bearer = `Bearer ${accessToken}`;
        headers.append("Authorization", bearer);
        headers.append("Content-Type",'application/json')
  
    
        if(index>=0){
          let body = {
            "clientID": idClient,
            "transactionID": data[index].id,
            "inflow": inflow,
            "outflow": outflow,
            "clear":clear,
            logsInfo:{
              origin:'planningTransactions',
              sessionID:sessionID
          }
        }
          if(idRS){
            body.idRS = idRS
          }
          fetch(`${urlBase}/planning/updateSingleTransaction`,{
              method: 'POST',
              headers: headers,
              body: JSON.stringify(body)
          })
          .then((response) => response.json())
          .then((aux) => {
  
          setIndexToUpdate(-1)
          if(aux.updates.length>0){
            newToast('success', Idioma.transacciones.transActualizadas[language], aux.updates.length)
          }
          if(aux.unaffected.length >0){
            newToast('error', Idioma.transacciones.transNoActualizadas[language], aux.unaffected.length)
          }
          //setShowTransactionsUpdated(aux.updates.length>=1)
          //setUpdatedTransactions(aux.updates.length)
          //setShowTransactionsError(aux.unaffected.length >= 1)
          //setUnaffectedTransactions(aux.unaffected)
          
          })
          .catch(error=>{
            alert('Ha ocurrido un error')
          })
        }
      }

    //Funcion para actualizar la fecha de una transaccion
    const updateTransactionDate = async (index,date) => {
        let headers = new Headers()

        const bearer = `Bearer ${accessToken}`;
        headers.append("Authorization", bearer);
        headers.append("Content-Type",'application/json')


        if(index>=0){

          let body = {
            "clientID": idClient,
            "transactionID": data[index].id,
            "date":date,
            logsInfo:{
              origin:'planningTransactions',
              sessionID:sessionID
          }
        }
          if(idRS){
            body.idRS = idRS
          }
            fetch(`${urlBase}/planning/updateSingleTransaction`,{
                method: 'POST',
                headers: headers,
                body: JSON.stringify(body)
            })
            .then((response) => response.json())
            .then((aux) => {

            setIndexToUpdate(-1)
            if(aux.updates.length>0){
              newToast('success', Idioma.transacciones.transActualizadas[language], aux.updates.length)
            }
            if(aux.unaffected.length >0){
              newToast('error', Idioma.transacciones.transNoActualizadas[language], aux.unaffected.length)
            }
            //setShowTransactionsUpdated(aux.updates.length>=1)
            //setUpdatedTransactions(aux.updates.length)
            //setShowTransactionsError(aux.unaffected.length >= 1)
            //setUnaffectedTransactions(aux.unaffected)
            
            })
            .catch(error=>{
            alert('Ha ocurrido un error')
            })
        }
    }

    //Funcion para actualizar la fecha de una transaccion
    const updateTransactionCurrency = async (index,currency) => {
        let headers = new Headers()

        const bearer = `Bearer ${accessToken}`;
        headers.append("Authorization", bearer);
        headers.append("Content-Type",'application/json')


        if(index>=0){
          let body = {
            "clientID": idClient,
            "transactionID": data[index].id,
            "currency":currency,
            'mainCurrency':mainCurrency,
            logsInfo:{
              origin:'planningTransactions',
              sessionID:sessionID
          }
        }
          if(idRS){
            body.idRS = idRS
          }
            fetch(`${urlBase}/planning/updateSingleTransaction`,{
                method: 'POST',
                headers: headers,
                body: JSON.stringify(body)
            })
            .then((response) => response.json())
            .then((aux) => {

            setIndexToUpdate(-1)
            if(aux.updates.length>0){
              newToast('success', Idioma.transacciones.transActualizadas[language], aux.updates.length)
            }
            if(aux.unaffected.length >0){
              newToast('error', Idioma.transacciones.transNoActualizadas[language], aux.unaffected.length)
            }
            //setShowTransactionsUpdated(aux.updates.length>=1)
            //setUpdatedTransactions(aux.updates.length)
            //setShowTransactionsError(aux.unaffected.length >= 1)
            //setUnaffectedTransactions(aux.unaffected)
            
            })
            .catch(error=>{
            alert('Ha ocurrido un error')
            })
        }
    }
  

    //Funcion para actualizar la fecha de una transaccion
    const updateTransactionPaidWith = async (index,paidWith) => {
        let headers = new Headers()

        const bearer = `Bearer ${accessToken}`;
        headers.append("Authorization", bearer);
        headers.append("Content-Type",'application/json')


        if(index>=0){
          let body = {
            "clientID": idClient,
            "transactionID": data[index].id,
            "paidWith":paidWith,
            logsInfo:{
              origin:'planningTransactions',
              sessionID:sessionID
          }
        }
          if(idRS){
            body.idRS = idRS
          }
            fetch(`${urlBase}/planning/updateSingleTransaction`,{
                method: 'POST',
                headers: headers,
                body: JSON.stringify(body)
            })
            .then((response) => response.json())
            .then((aux) => {

            setIndexToUpdate(-1)
            if(aux.updates.length>0){
              newToast('success', Idioma.transacciones.transActualizadas[language], aux.updates.length)
            }
            if(aux.unaffected.length >0){
              newToast('error', Idioma.transacciones.transNoActualizadas[language], aux.unaffected.length)
            }
            //setShowTransactionsUpdated(aux.updates.length>=1)
            //setUpdatedTransactions(aux.updates.length)
            //setShowTransactionsError(aux.unaffected.length >= 1)
            //setUnaffectedTransactions(aux.unaffected)
            
            })
            .catch(error=>{
            alert('Ha ocurrido un error')
            })
        }
    }
    

    const multiTransactionUpdateForex = (forex) => {
        let headers = new Headers()

        const bearer = `Bearer ${accessToken}`;
        headers.append("Authorization", bearer);
        headers.append("Content-Type",'application/json')

        let allTransactionIds = []
        for(let i=0;i<selectedFlatRows.length; i++){
        allTransactionIds[i]=selectedFlatRows[i].original.id
        }

        let body = {
          "clientID": idClient,
          "transactions": allTransactionIds,
          "exchange": forex,
          logsInfo:{
            origin:'planningTransactions',
            sessionID:sessionID
        }
      }
          if(idRS){
            body.idRS = idRS
          }
        fetch(`${urlBase}/planning/updateTransaction`,{
            method: 'POST',
            headers: headers,
            body: JSON.stringify(body)
        })
        .then((response) => response.json())
        .then((aux) => {
            if(aux.updates.length>0){
              newToast('success', Idioma.transacciones.transActualizadas[language], aux.updates.length)
            }
            if(aux.unaffected.length >0){
              newToast('error', Idioma.transacciones.transNoActualizadas[language], aux.unaffected.length)
            }
            //setShowTransactionsUpdated(aux.updates.length>=1)
            //setUpdatedTransactions(aux.updates.length)
            //setErrorSign(aux.unaffected.length >= 1)
            //setShowTransactionsError(aux.unaffected.length >= 1)
            //setUnaffectedTransactions(aux.unaffected)
            for(let i = 0;i<allTransactionIds.length;i++){
                setData(current=> 
                current.map(obj => {
                    if (obj.id == allTransactionIds[i]) {
                    return {...obj,exchange:forex}
                    }
                    return obj;
                }),
                )
            }
            setForexValue()
        })
        .catch(error=>{
            alert('Ha ocurrido un error')
        })
    }
      
    const handleDateAddTChange = (date) => {
        setDateAddT(date)
        let aux = date
        aux = new Date(aux.getTime() + aux.getTimezoneOffset() * 60000)
        setRecurrenceEndDateMinLimit(aux)
        let aux1 = [{
            name:Idioma.planeacion.opcionesRecurrencia.noSeRepite[language],
            id:'none'
        },
        {
            name:Idioma.planeacion.opcionesRecurrencia.diario[language],
            id:'daily'
        },
        {
            name:`${Idioma.planeacion.opcionesRecurrencia.semanal[language]} ${moment(aux).locale(language).format('dddd')}`,
            id:'weeklyOnDD'
        }]

        if(parseInt((moment(aux).locale(language).format('YYYY-MM-DD').split('-')[2]-1)/7+1)<=4){
            aux1[aux1.length] = {
                name:`${Idioma.planeacion.opcionesRecurrencia.mensual[language]} ${Idioma.nums[`${parseInt((moment(aux).locale(language).format('YYYY-MM-DD').split('-')[2]-1)/7+1)}s`][language]} ${moment(aux).locale(language).format('dddd')}`,
                id:'monthlyOnNDD'
            }
        }
        if(moment(aux).month()!==moment(aux).add(1,'week').month()){
            aux1[aux1.length] = {
                name:`${Idioma.planeacion.opcionesRecurrencia.mensual[language]} ${Idioma.nums.ultimo[language]} ${moment(aux).locale(language).format('dddd')}`, 
                id:'monthlyLastDD'
            }
        }
        setRecurrenceOptions(aux1)
    }

    const handleValueAddTChange = (inflow,outflow) => {
        setInflowAddT(inflow)
        setOutflowAddT(outflow)
    }

    const handleOpenAddTransaction = () => {
        setOpenAddTransaction(true)
        let aux = new Date()
        aux = new Date(aux.getTime() + aux.getTimezoneOffset() * 60000)
        setRecurrenceEndDateMinLimit(aux)
        setRecurrenceEndDate(aux)
        setRecurrence({
            name:Idioma.planeacion.opcionesRecurrencia.noSeRepite[language],
            id:'none'
        })
        let aux1 = [{
            name:Idioma.planeacion.opcionesRecurrencia.noSeRepite[language],
            id:'none'
        },
        {
            name:Idioma.planeacion.opcionesRecurrencia.diario[language],
            id:'daily'
        },
        {
            name:`${Idioma.planeacion.opcionesRecurrencia.semanal[language]} ${moment(aux).locale(language).format('dddd')}`,
            id:'weeklyOnDD'
        }]

        if(parseInt((moment(aux).locale(language).format('YYYY-MM-DD').split('-')[2]-1)/7+1)<=4){
            aux1[aux1.length] = {
                name:`${Idioma.planeacion.opcionesRecurrencia.mensual[language]} ${Idioma.nums[`${parseInt((moment(aux).locale(language).format('YYYY-MM-DD').split('-')[2]-1)/7+1)}s`][language]} ${moment(aux).locale(language).format('dddd')}`,
                id:'monthlyOnNDD'
            }
        }
        if(moment(aux).month()!==moment(aux).add(1,'week').month()){
            aux1[aux1.length] = {
                name:`${Idioma.planeacion.opcionesRecurrencia.mensual[language]} ${Idioma.nums.ultimo[language]} ${moment(aux).locale(language).format('dddd')}`, 
                id:'monthlyLastDD'
            }
        }
        setRecurrenceOptions(aux1)
    }

    const handleCloseAddT = () => {
        setOpenAddTransaction(false)
        setDateAddT(new Date())
        setConceptAddT('')
        setInflowAddT()
        setOutflowAddT()
        setCategoryAddT()
        setCurrencyAddT({name:mainCurrency,id:mainCurrency})
        setPaidWithAddT({name:Idioma.transacciones.debito[language],id:'D'})
        setScenarioAddT({name:Idioma.planeacion.seleccionaEscenario[language]})
        setRecurrenceAddT({name:Idioma.planeacion.seleccionaRecurrencia[language]})
        setExchangeAddT()
        setClientSupplierAddT({name:Idioma.planeacion.seleccionaClienteProveedor[language]})
        setRecurrenceEndDateMinLimit()
        setRecurrenceRepeats()
        setRecurrenceRepeatsLimit()
        
    }

    const handleCloseAddScenario = () => {
        setOpenAddScenario(false)
        setNameAddScenario('')
        setRSAddScenario({name:Idioma.planeacion.seleccionaEmpresa[language]})
    }

    const handleCloseCopyScenario = () => {
        setOpenCopyScenario(false)
        setCopyScenario({name:Idioma.planeacion.seleccionaEscenario[language]})
    }

    const AddScenario = () => {
        let headers = new Headers()

        const bearer = `Bearer ${accessToken}`;
        headers.append("Authorization", bearer);
        headers.append("Content-Type",'application/json')
        let body = {
            "clientID": idClient,
            "name":nameAddScenario,
            logsInfo:{
              origin:'planningTransactions',
              sessionID:sessionID
            }
        }
        if(idRS){
            body.idRS = idRS
        }
        if(rsAddScenario.id){
            body.rsID = rsAddScenario.id
        }

        fetch(`${urlBase}/planning/addScenario`,{
        method: 'POST',
        headers: headers,
        body: JSON.stringify(body)
        })
        .then((response) => response.json())
        .then((aux) => {
            handleCloseAddScenario()
            getScenarioData()
        })
        .catch(error=>{
            alert('Ha ocurrido un error')
        })
    }

    const CopyTransactionsToScenario = () => {
        let headers = new Headers()

        const bearer = `Bearer ${accessToken}`;
        headers.append("Authorization", bearer);
        headers.append("Content-Type",'application/json')
        let allTransactionIds = []
        for(let i=0;i<selectedFlatRows.length; i++){
        allTransactionIds[i]=selectedFlatRows[i].original.id
        }
        let body = {
            "clientID": idClient,
            "idScenario":copyScenario.id,
            'transactions':allTransactionIds,
            logsInfo:{
              origin:'planningTransactions',
              sessionID:sessionID
          }
        }
        if(idRS){
            body.idRS = idRS
        }

        fetch(`${urlBase}/planning/copyScenario`,{
        method: 'POST',
        headers: headers,
        body: JSON.stringify(body)
        })
        .then((response) => response.json())
        .then((aux) => {
            handleCloseCopyScenario()
            getTransactions('')
            if(aux.updates.length>0){
              newToast('success', Idioma.transacciones.transActualizadas[language], aux.updates.length)
            }
            if(aux.unaffected.length >0){
              newToast('error', Idioma.transacciones.transNoActualizadas[language], aux.unaffected.length)
            }
            //setShowTransactionsUpdated(aux.updates.length>=1)
            //setUpdatedTransactions(aux.updates.length)
            //setErrorSign(aux.unaffected.length >= 1)
            //setShowTransactionsError(aux.unaffected.length >= 1)
            //setUnaffectedTransactions(aux.unaffected)
        })
        .catch(error=>{
            alert('Ha ocurrido un error')
        })
    }

    const handleChangeRecurrenceAddT = (value) => {
        setRecurrence(value)
        if(value.id==='daily'){
            setRecurrenceRepeatsLimit(Math.floor((recurrenceEndDateLimit - new Date()) / (1000*60*60*24)))
            let aux = moment(dateAddT).add(1,'days').format('YYYY-MM-DD')
            aux = new Date(aux)
            aux = new Date(aux.getTime() + aux.getTimezoneOffset() * 60000)
            setRecurrenceEndDateMinLimit(aux)
            if(!recurrenceRepeats && recurrenceEndDate<aux){
                setRecurrenceEndDate(aux)
            }
            return
        }
        if(value.id==='weeklyOnDD'){
            setRecurrenceRepeatsLimit(Math.floor((recurrenceEndDateLimit - new Date()) / (1000*60*60*24*7)))
            let aux = moment(dateAddT).add(1,'week').format('YYYY-MM-DD')
            aux = new Date(aux)
            aux = new Date(aux.getTime() + aux.getTimezoneOffset() * 60000)
            setRecurrenceEndDateMinLimit(aux)
            if(!recurrenceRepeats && recurrenceEndDate<aux){
                setRecurrenceEndDate(aux)
            }
            return
        }
        if(value.id==='monthlyOnNDD'){
            setRecurrenceRepeatsLimit(Math.floor((recurrenceEndDateLimit - new Date()) / (1000*60*60*24*30)))
            let n = parseInt((selectedTransaction?.original?.date?.split('-')[2]-1)/7+1)
            let aux = moment(dateAddT).add(4,'week').format('YYYY-MM-DD')
            if(n!==parseInt((aux.split('-')[2]-1)/7+1)){
                aux = moment(aux).add(1,'week').format('YYYY-MM-DD')
            }
            aux = new Date(aux)
            aux = new Date(aux.getTime() + aux.getTimezoneOffset() * 60000)
            setRecurrenceEndDateMinLimit(aux)
            if(!recurrenceRepeats && recurrenceEndDate<aux){
                setRecurrenceEndDate(aux)
            }
            return
        }
        setRecurrenceRepeatsLimit()
    }
  
    const AddSingleTransaction = () => {
        
        setAddTransactionLoading(true)
        let year = dateAddT.getFullYear().toString()
        let month= dateAddT.getMonth()+1
        let day = dateAddT.getDate()
        if(day<10){1
            day = '0'+day.toString()
        }else{
            day = day.toString()
        }

        if(month<10){
            month = '0'+month.toString()
        }else{
            month = month.toString()
        }
        let fecha = year+'-'+month+'-'+day
        let datesArray=[]
        if(recurrence.id && recurrence.id!='none'){
            datesArray=CalculateRecurrence(fecha)
        }

        let headers = new Headers()

        const bearer = `Bearer ${accessToken}`;
        headers.append("Authorization", bearer);
        headers.append("Content-Type",'application/json')
        let body = {
            "clientID": idClient,
            "date": fecha,
            "context": conceptAddT,
            "inflow":   parseFloat(inflowAddT) || 0,
            "outflow":  parseFloat(outflowAddT) || 0,
            "idCategory": categoryAddT,
            "idScenario":scenarioAddT.id,
            "currency":currencyAddT.id,
            "paidWith":paidWithAddT.id,
            "recurrence":[],
            logsInfo:{
              origin:'planningTransactions',
              sessionID:sessionID
          }
        }
        if(clientSupplierAddT.id){
            body.idClientSupplier=clientSupplierAddT.id
        }
        if(datesArray.length>0){
            body.recurrence=datesArray
        }
        if(idRS){
          body.idRS = idRS
        }
        if(rsAddT.id){
          body.rsID = rsAddT.id
        }
        fetch(`${urlBase}/planning/addTransaction`,{
        method: 'POST',
        headers: headers,
        body: JSON.stringify(body)
        })
        .then((response) => response.json())
        .then((aux) => {
        setAddTransactionLoading(false)
        getTransactions('')
        handleCloseAddT()
        })
        .catch(error=>{
        alert('Ha ocurrido un error')
        })
    }
  
    const handleShowStateChange = (value) => {
        setContextShowState(value)
        setData([])
        
    }
  
    const handleFileChange = (value) => {
        setFile(value)
    }

    const handleScenarioChange = (value) =>{
        setScenarioFilter(value)
    }

    useEffect(()=> {
        setAllFilters([])
    },[filtersActive])
  
    const handleDateRangeChange = (update) => {
        setContextDateRange(update)
    }
  
    const transactionSelect = (row) => {
        if(row.original.id == transactionId){
            setSelectedTransaction([])
            setTransactionId(null)
            setTransactionQuantities([])
        }else{
            setSelectedTransaction(row)
            setTransactionId(row.original.id)
            setTransactionQuantities(row.original.inflow > 0 ? [{value:[row.original.inflow],category:{name:'Categoría'},subcategory:{name:'Subcategoría'}},]:[{value:[row.original.outflow],category:{name:'Categoría'},subcategory:{name:'Subcategoría'}}])
        }
    }
        
    const deleteTransactionQuantity = index => e => {

        setTransactionQuantities(prev => prev.filter((datum,id) => {
            return id!==index
        }))
    }
  
    const updateFieldChangedValue = index => e => {
        let newArr = [...transactionQuantities]; // copying the old datas array
        // a deep copy is not needed as we are overriding the whole object below, and not setting a property of it. this does not mutate the state.
        newArr[index].value = e.target.value; // replace e.target.value with whatever you want to change it to
    
        setTransactionQuantities(newArr);
    }
  
    const updateFieldChangedText = index => e => {
        let newArr = [...transactionQuantities]; // copying the old datas array
        // a deep copy is not needed as we are overriding the whole object below, and not setting a property of it. this does not mutate the state.
        newArr[index].context = e.target.value; // replace e.target.value with whatever you want to change it to
    
        setTransactionQuantities(newArr);
    }
  
    const handleTransactionCategoryChange = index => value => {
        //setRuleCategory(value)
        //setRuleSubcategory({name:'Subcategoría'})
        let newArr = [...transactionQuantities]; // copying the old datas array
        // a deep copy is not needed as we are overriding the whole object below, and not setting a property of it. this does not mutate the state.
        newArr[index].category = value; // replace e.target.value with whatever you want to change it to
        newArr[index].subcategory = {name: 'Subcategoria'}
        setTransactionQuantities(newArr);

    }
  
    const handleTransactionSubcategoryChange = (index,event) => {
        let newArr = [...transactionQuantities]; // copying the old datas array
        // a deep copy is not needed as we are overriding the whole object below, and not setting a property of it. this does not mutate the state.
        
        newArr[index].subcategory = {idSubCategory: event.target.value}
        setTransactionQuantities(newArr);
    }

  
    const addTransactionQuantities = (selectedTransaction) => {
        let newArr = [...transactionQuantities,{context:selectedTransaction.context,value:selectedTransaction.inflow>0 ?  parseFloat(selectedTransaction.inflow-getTransactionQuantityTotal()).toFixed(2) :  parseFloat(selectedTransaction.outflow-getTransactionQuantityTotal()).toFixed(2),category:{name:'Categoría'},subcategory: {name:'Subcategoría'}}]; // copying the old datas array
        // a deep copy is not needed as we are overriding the whole object below, and not setting a property of it. this does not mutate the state.

    
        setTransactionQuantities(newArr);
    }
  
    const getTransactionQuantityTotal = () => {
        let total=0
        for(let i=0;i<transactionQuantities.length;i++){
            total+=parseFloat(transactionQuantities[i].value) ? parseFloat(transactionQuantities[i].value) : 0 
        }
        return total
    }
    
  
    const createCellOfType = (cell,row) => {
        const [showIcons,setShowIcons] = useState(false)
        const [isEditingInflow,setIsEditingInflow] = useState(false)
        const [isEditingOutflow,setIsEditingOutflow] = useState(false)
        const inputInflowRef = useRef(null)
        const inputOutflowRef = useRef(null)

        const ExampleCustomInput = forwardRef(({ value, onClick }, ref) => (
            <span onClick={onClick} ref={ref}>{moment(cell.value).locale(language).format(language==='en' ?'MMM/DD/YYYY':'DD/MMM/YYYY').replace('.','')}</span>
          ));

        const toggleEditingInflow = () => {
        setIsEditingInflow((prev)=>!prev)
        setIsEditingOutflow(false)
        }

        useEffect(()=>{
        if(isEditingInflow){
            inputInflowRef.current.focus()
        }
        },[isEditingInflow])

        const toggleEditingOutflow = () => {
        setIsEditingOutflow((prev)=>!prev)
        setIsEditingInflow(false)
        }

        useEffect(()=>{
        if(isEditingOutflow){
            inputOutflowRef.current.focus()
        }
        },[isEditingOutflow])

        const handleInflowChange = async (event) =>{
            let clear = cell.row.original.outflow>0
            if(cell.row.original.inflow != event.target.value){
                let index = data.indexOf(cell.row.original)
                setIndexToUpdate(index)
                setData(current =>
                current.map(obj => {
                    if(obj.id === cell.row.original.id){
                    return{ ...obj,inflow:event.target.value,outflow:0,category:clear?'':cell.row.original.category,idSubCategory:clear?'':cell.row.original.idSubCategory,subcategory:clear?'':cell.row.original.subcategory}
                    }
                    return obj;
                }),
                )
                let resp = await updateTransactionAmount(index,event.target.value,0,clear)
            }
            toggleEditingInflow()
        }

        const handleOutflowChange = async (event) =>{
            let clear = cell.row.original.inflow>0
            if(cell.row.original.outflow != event.target.value){
                let index = data.indexOf(cell.row.original)
                setIndexToUpdate(index)
                setData(current =>
                    current.map(obj => {
                    if(obj.id === cell.row.original.id){
                        return{ ...obj,inflow:0,outflow:event.target.value,category:clear?'':cell.row.original.category,idSubCategory:clear?'':cell.row.original.idSubCategory,subcategory:clear?'':cell.row.original.subcategory}
                    }
                    return obj;
                    }),
                )
                let resp = await updateTransactionAmount(index,0,event.target.value,clear)
            }
            toggleEditingOutflow()
        }
        
        const handleDateChange = async (value) =>{
            let year = value.getFullYear().toString()
            let month= value.getMonth()+1
            let day = value.getDate()
            if(day<10){1
                day = '0'+day.toString()
            }else{
                day = day.toString()
            }
    
            if(month<10){
                month = '0'+month.toString()
            }else{
                month = month.toString()
            }
            let fecha = year+'-'+month+'-'+day
            if(cell.row.original.date != fecha){
                let index = data.indexOf(cell.row.original)
                setIndexToUpdate(index)
                setData(current =>
                    current.map(obj => {
                    if(obj.id === cell.row.original.id){
                        return{ ...obj,date:fecha}
                    }
                    return obj;
                    }),
                )
                let resp = await updateTransactionDate(index,fecha)
            }
        }
        
        

        const handleConceptChange = async (event) =>{
            let index = data.indexOf(cell.row.original)
            setIndexToUpdate(index)
            setData(current =>
                current.map(obj => {
                if(obj.id === cell.row.original.id){
                    return{ ...obj,context:event.target.value}
                }
                return obj;
                }),
            )
            let resp = await updateTransactionConcept(index,event.target.value)
        }

        const handleNotesChange = async (event) =>{
            let index = data.indexOf(cell.row.original)
            setIndexToUpdate(index)
            setData(current =>
                current.map(obj => {
                if(obj.id === cell.row.original.id){
                    return{ ...obj,notes:event.target.value}
                }
                return obj;
                }),
            )
            let resp = await updateTransactionNotes(index,event.target.value)
        }

        const handleExchangeChange = async (event) =>{
            let index = data.indexOf(cell.row.original)
            setIndexToUpdate(index)
            setData(current =>
                current.map(obj => {
                if(obj.id === cell.row.original.id){
                    return{ ...obj,exchange:event.target.value}
                }
                return obj;
                }),
            )
            let resp = await updateTransactionExchange(index,event.target.value)
        }
    
        const handleSubcategoryChange = async (event) => {
            let index = data.indexOf(cell.row.original)
            setIndexToUpdate(index)
            setData(current=> 
                current.map(obj => {
                if (obj.id === cell.row.original.id) {
                    return {...obj,category:event.target.selectedOptions[0].outerText,idSubCategory:event.target.value,subcategory:event.target.selectedOptions[0].label}
                }
                return obj;
                }),
            )
            let resp = await updateTransaction(index,event.target.value)
        }

        const handleClientSupplierChange = async (event) => {
            let index = data.indexOf(cell.row.original)
            setIndexToUpdate(index)
            setData(current=> 
                current.map(obj => {
                if (obj.id === cell.row.original.id) {
                    return {...obj,idClientSupplier:event.target.value,clientSupplier:event.target.selectedOptions[0].label}
                }
                return obj;
                }),
            )
            let resp = await updateTransactionClientSupplier(index,event.target.value)
        }

        const handleCurrencyChange = async (value) => {
            let index = data.indexOf(cell.row.original)
            setIndexToUpdate(index)
            if(mainCurrency===value.name){
                setData(current=> 
                    current.map(obj => {
                    if (obj.id === cell.row.original.id) {
                        return {...obj,currency:value.name,exchange:null}
                    }
                    return obj;
                    }),
                )
            }else{
                setData(current=> 
                    current.map(obj => {
                    if (obj.id === cell.row.original.id) {
                        return {...obj,currency:value.name}
                    }
                    return obj;
                    }),
                )
            }
            
            let resp = await updateTransactionCurrency(index,value.id)
        }

        const handlePaidWithChange = async (value) => {
            let index = data.indexOf(cell.row.original)
            setIndexToUpdate(index)
            setData(current=> 
                current.map(obj => {
                if (obj.id === cell.row.original.id) {
                    return {...obj,paidWith:value.name}
                }
                return obj;
                }),
            )
            
            let resp = await updateTransactionPaidWith(index,value.id)
        }

        const copyFunction = (textToCopy) => {
          newToast('success', Idioma.transacciones.textoCopiado[language], textToCopy)
          navigator.clipboard.writeText(textToCopy)
        }

        let updatedStyle = 'px-2 rounded-button bg-transparent w-[100%]  border-neutral-1 border-1  appearance-none relative'
        let normalStyle = 'px-0 rounded-button bg-transparent w-[100%] appearance-none relative'
        let classifiedStyle = 'px-2 rounded-button bg-transparent w-[100%] appearance-none relative'

        if(cell.column.id === 'category'){
            return <div className='pl-4 w-[100%]'>{cell.value}</div>
        }
        if(cell.column.id === 'subcategory' && userAccess && userAccess.access3){
        return <div className='pl-2 w-[100%] flex relative'>
            <select className={!row.original.subcategory ? normalStyle : row.original.updated ? updatedStyle : classifiedStyle} value={categoriesDict[row.original.idSubCategory]?.isActive ? row.original.idSubCategory : ""} onChange={(event)=>handleSubcategoryChange(event)}>
            <option value={cell.row.original.idSubCategory} selected='true' disabled>{cell.value}</option>
            {row.original.inflow!=0 ? inflowCategories.map(category=>{
                return (
                <optgroup label={category.name}>
                    {subcategories[category.index].map(subcategory => {
                    return (
                        <option value={subcategory.idSubCategory} label={subcategory.name}>{category.name}</option>
                    )
                    })}
                </optgroup>
                )
            }) :
            outflowCategories.map(category=>{
                return (
                <optgroup label={category.name}>
                    {subcategories[category.index].map(subcategory => {
                    return (
                        <option value={subcategory.idSubCategory} label={subcategory.name}>{category.name}</option>
                    )
                    })}
                </optgroup>
                )
            }) }
            </select><span className='absolute flex items-center right-[15px] top-[2px] pointer-events-none'><TrianguloAbajoLogo/></span></div>
        //return <div><ClassificationDropdown className={!data[data.indexOf(row.original)].subcategory ? normalStyle : row.original.updated ? updatedStyle : classifiedStyle} disabled={row.original.idCategory ? false : true} selected={data[data.indexOf(row.original)].subcategory ? {name:data[data.indexOf(row.original)].subcategory} :{'name':'Subcategoria'}} setSelected={handleSubcategoryChange} options={row.original.idCategory ? subcategories[data[data.indexOf(row.original)].categoryIndex] : [] }/></div>
        }
        
        if(cell.column.id === 'clientSupplier' && userAccess && userAccess.access3){
        return <div className='pl-2 w-[100%] flex relative'>
            <select className={!row.original.idClientSupplier ? normalStyle : row.original.updated ? updatedStyle : classifiedStyle} value={clientSupplierDict[row.original.idClientSupplier]?.isActive ? row.original.idClientSupplier : ""} onChange={(event)=>handleClientSupplierChange(event)}>
            <option value={cell.row.original.idClientSupplier} selected='true' disabled>{cell.value}</option>
            <optgroup label={'Clientes'}>
                {clientOptions.map(client=>{
                return (
                <option value={client.idClientSupplier} label={client.clientSupplierName}>{client.clientSupplierName}</option>
                )})
                }
            </optgroup>
            <optgroup label={'Proveedores'}>
                {supplierOptions.map(supplier=>{
                return(
                <option value={supplier.idClientSupplier} label={supplier.clientSupplierName}>{supplier.clientSupplierName}</option>
                )})
                }
            </optgroup>
            
            </select><span className='absolute flex items-center right-[15px] top-[2px] pointer-events-none'><TrianguloAbajoLogo/></span></div>
        }
        if(cell.column.id === 'inflow'&& userAccess && userAccess.access3){
        
        return <div className='text-right relative w-[100%] tabular-nums'>
            
            {isEditingInflow ?
            <div className='bg-white rounded-[7px] w-full'>
            <input ref={inputInflowRef} onBlur={(e)=>handleInflowChange(e)} defaultValue={cell.value} className='text-right' type='number'>
            </input>
            </div>
            :
            <span onClick={()=>toggleEditingInflow()}>{cell.value ? (parseFloat(cell.value)).toLocaleString("en-US", {
            style: "currency",
            currency: "USD",
            currencyDisplay: 'code'}).slice(4) : '--'}</span>
            }
            </div>/*
            return <div className='text-right relative w-[100%] tabular-nums'>
            <span>{cell.value ? (parseFloat(cell.value)).toLocaleString("en-US", {
            style: "currency",
            currency: "USD",
            currencyDisplay: 'code'}).slice(4) : '--'}</span>
            </div>*/
        }
        if(cell.column.id === 'inflow'){
            return 
                return <div className='text-right relative w-[100%] tabular-nums'>
                <span>{cell.value ? (parseFloat(cell.value)).toLocaleString("en-US", {
                style: "currency",
                currency: "USD",
                currencyDisplay: 'code'}).slice(4) : '--'}</span>
                </div>
            }
        if(cell.column.id === 'outflow' && userAccess && userAccess.access3){
        return <div className='text-right relative overflow-visible w-[100%] tabular-nums '>
            {isEditingOutflow ? 
            <div className='bg-white rounded-[7px] shadow-xl w-fit border-1 px-2'>
            <input ref={inputOutflowRef} onBlur={(e)=>handleOutflowChange(e)} defaultValue={cell.value} className='text-right' type='number'>
            </input>
            </div>
            :
            <span onClick={()=>toggleEditingOutflow()}>{cell.value ? `${numberFormat===0?'(':'-'}${(parseFloat(cell.value)).toLocaleString("en-US", {
            style: "currency",
            currency: "USD",
            currencySign: "accounting",
            currencyDisplay: 'code'
            }).slice(4)}${numberFormat===0?')':''}` : '--' }</span>
            }</div>
        /*
        return <div className='text-right relative overflow-visible w-[100%] tabular-nums '>
            <span>{cell.value ? `${numberFormat===0?'(':'-'}${(parseFloat(cell.value)).toLocaleString("en-US", {
            style: "currency",
            currency: "USD",
            currencySign: "accounting",
            currencyDisplay: 'code'
            }).slice(4)}${numberFormat===0?')':''}` : '--' }</span>
            </div>*/

        }
        if(cell.column.id === 'outflow'){
            return <div className='text-right relative overflow-visible w-[100%] tabular-nums '>
                <span>{cell.value ? `${numberFormat===0?'(':'-'}${(parseFloat(cell.value)).toLocaleString("en-US", {
                style: "currency",
                currency: "USD",
                currencySign: "accounting",
                currencyDisplay: 'code'
                }).slice(4)}${numberFormat===0?')':''}` : '--' }</span>
                </div>
    
            }
        if(cell.column.id === 'exchange' && userAccess && userAccess.access3){
        return <div className='pl-2 w-[100%] truncate focus:bg-black relative '  title={cell.value}>
            
            <input className='w-full h-full appearance-none bg-transparent group text-right' placeholder='--' disabled={row.original.currency===mainCurrency} defaultValue={cell.value} onBlur={(e)=>handleExchangeChange(e)}></input>
            
            {false && <AiOutlineSave className='absolute h-5 w-5 text-black top-0 right-0 bg-main-bg'/>}
            </div>
        }
        if(cell.column.id === 'exchange'){
        return <div className='pl-2 text-right w-[100%] tabular-nums'>{cell.value ? parseFloat(cell.value).toFixed(2).toLocaleString("en-US") : '--'}</div>
        }
        if(cell.column.id === 'context' && userAccess && userAccess.access3){
        return <div className='pl-2 w-[100%] truncate relative '  title={cell.value}>
            <input className='w-full h-full appearance-none bg-transparent group underline' defaultValue={cell.value} onBlur={(e)=>handleConceptChange(e)}></input>
            
            {false && <AiOutlineSave className='absolute h-5 w-5 text-black top-0 right-0 bg-main-bg'/>}
            </div>
        }
        if(cell.column.id === 'context'){
        return <div className='pl-2 w-[100%] truncate relative '  title={cell.value}>{cell.value}</div>
        }
        if(cell.column.id === 'date'){
            let date = new Date(cell.value)
            date = new Date(date.getTime() + date.getTimezoneOffset() * 60000)
        return <div className='pl-2 w-[100%] '>
            <DatePicker
            className={'h-8 pl-6 text-[10px] 2xl:text-p8-2xl bg-white rounded-button border-1 border-neutral-1 mb-2'} 
            selected={date} 
            locale={language}
            onChange={(date) => handleDateChange(date)}
            fixedHeight
            customInput={<ExampleCustomInput/>}
            dateFormat={Idioma.formatoFecha[language]}
            />
            
            </div>  
        }
        if(cell.column.id === 'selection'){
        return  <div className='w-[100%] text-center'>
        <IndeterminateCheckbox {...row.getToggleRowSelectedProps()} />
        </div>
        }
        if(cell.column.id === 'paidWith' && userAccess && userAccess.access3){
            return <div className=' w-[100%] text-left '>
                <Dropdown
                    options={creditDebitOptions}
                    selected={{name:cell.value}}
                    setSelected={handlePaidWithChange}
                    className={' w-full bg-white rounded-button '}
                />
            </div>
        }
        if(cell.column.id === 'paidWith'){
        return <div className='pl-2 w-[100%] text-left'>{cell.value}</div>
        }
        if(cell.column.id === 'notes' && userAccess && userAccess.access3){
        return <div className='pl-2 w-[100%] truncate focus:bg-black relative '  title={cell.value}>
            <input className='w-full h-full appearance-none underline bg-transparent group' defaultValue={cell.value} onBlur={(e)=>handleNotesChange(e)}></input>
            
            {cell.value?.length>0 && <TbCopy onClick={()=>copyFunction(cell.value)} className='absolute h-5 w-5 text-black top-0 right-0 bg-main-bg cursor-pointer'/>}

            </div>
        }
        if(cell.column.id === 'extraText'){
            if(row.original.extraText){
                let json = JSON.parse(row.original.extraText)
                let aux = Object.keys(json) 
                let auxText = ``
                for(let i=0;i<aux.length;i++){
                auxText =auxText+ `${aux[i]} : ${json[aux[i]]} \n`
                } 
                return <div className='w-[100%] text-left truncate' title={auxText}>{auxText}</div>
            }else{
                return <div className=' w-[100%] text-left '>{cell.value}</div>    
            } 
        
        }
        if(cell.column.id === 'currency' && userAccess && userAccess.access3){
            return <div className=' w-[100%] text-left '>
                <Dropdown
                    options={CurrencyOptions}
                    selected={{name:cell.value,id:cell.value}}
                    setSelected={handleCurrencyChange}
                    className={' w-full bg-white rounded-button '}
                />
            </div>
        }
        return <div className=' w-[100%] text-left pl-2 truncate' title={cell.value}>{cell.value}</div>
    }
        
  
        const filterTypes = React.useMemo(
          () => ({
            // Add a new fuzzyTextFilterFn filter type.
            fuzzyText: fuzzyTextFilterFn,
            // Or, override the default text filter to use
            // "startWith"
            text: (rows, id, filterValue) => {
              return rows.filter(row => {
                const rowValue = row.values[id]
                return rowValue !== undefined
                  ? String(rowValue)
                      .toLowerCase()
                      .startsWith(String(filterValue).toLowerCase())
                  : true
              })
            },
          }),
          []
        )
  
    const textNormal = 'font-bold text-left'
    const textNumber = 'font-bold text-right '
    const textCategory = 'font-bold text-left '
      

    const initialColumns = [/*
        {
            id:'index',
            Header: ' ',
            maxWidth:40,
            minWidth:20,
            width:40,
            className:'text-center',
            accessor: (row, i) => i+1,
        },*/
        {
            Header: Idioma.transacciones.configuracion.empresa[language],
            accessor: 'rsName',
            width:130,
            Filter: SelectColumnFilter,
            className:textNormal + '',
        },
        {
            Header: Idioma.planeacion.escenario[language],
            accessor: 'scenario',
            width:130,
            Filter: SelectColumnFilter,
            className:textNormal + '',
            },
        {
            Header: Idioma.transacciones.configuracion.fecha[language],
            accessor: 'date',
            width:120,
            sortType:'basic',
            Filter:DateRangeColumnFilter,
            filter:dateBetweenFilterFn,
            className:textNormal
        },/*
        {
            Header: 'ID',
            accessor: 'id', 
        },*/
        {
            Header: Idioma.transacciones.configuracion.concepto[language],
            accessor: 'context',
            width:document.documentElement.clientWidth*.15,
            className:textNormal
        },
        {
            Header: Idioma.transacciones.configuracion.abono[language],
            accessor: 'inflow',
            width:140,
            Filter: NumberRangeColumnFilter,
            filter: 'between',
            className:textNumber
        },
        {
            Header: Idioma.transacciones.configuracion.cargo[language],
            accessor: 'outflow',
            width:140,
            Filter: NumberRangeColumnFilter,
            filter: 'between',
            className:textNumber 
        },
        {
            Header: Idioma.transacciones.configuracion.categoria[language],
            accessor: 'category',
            Filter: SelectColumnFilter,
            width:document.documentElement.clientHeight*.20,
            className:textCategory + ' pl-2 '
        },
        {
            Header: Idioma.transacciones.configuracion.subcategoria[language],
            accessor: 'subcategory',
            width:document.documentElement.clientHeight*.30,
            Filter: SelectColumnFilter,
            className:textCategory + ' pl-2 '
        },{
            Header: Idioma.transacciones.configuracion.moneda[language],
            accessor: 'currency',
            Filter: SelectColumnFilter,
            className:textNormal + '',
        },
        {
            Header: Idioma.transacciones.configuracion.fx[language],
            accessor: 'exchange',
            width:90,
            Filter: NumberRangeColumnFilter,
            filter: 'between',
            className:textNumber +' pr-2 '
        },{
            Header: Idioma.transacciones.configuracion.creditoDebito[language],
            accessor: 'accountType',
            width:140,
            Filter: SelectColumnFilter,
            className:textNormal + ''
        },{
            Header: Idioma.transacciones.configuracion.clientesProvedores[language],
            accessor: 'clientSupplier',
            width:document.documentElement.clientHeight*.20,
            Filter: SelectColumnFilter,
            className:textCategory + ''
        },{
            Header: Idioma.transacciones.configuracion.notas[language],
            accessor: 'notes',
            width:140,
            Filter: NotesColumnFilter,
            className:textNormal + ''
        },/*{
            Header: Idioma.transacciones.configuracion.informacionAdicional[language],
            accessor: 'extraText',
            width:document.documentElement.clientWidth*.15,
            Filter: NotesColumnFilter,
            className:textNormal + ' '
        }*/
    ]
    const [columns,setColumns] = useState(React.useMemo(
        () => initialColumns,
        []
    ))

    
    useEffect(()=>{
      if(!rsActive || rsOptions.length==0){
          allColumns[0+hasEditionAcess].toggleHidden()
      }
      
      if(!dateActive){
          allColumns[2+hasEditionAcess].toggleHidden()
      }
      if(!contextActive){
          allColumns[3+hasEditionAcess].toggleHidden()
      }
      if(!inflowActive){
          allColumns[4+hasEditionAcess].toggleHidden()
      }
      if(!outflowActive){
          allColumns[5+hasEditionAcess].toggleHidden()
      }
      if(!currencyActive){
          allColumns[8+hasEditionAcess].toggleHidden()
      }
      if(!fxActive){
          allColumns[9+hasEditionAcess].toggleHidden()
      }
      if(!paidWithActive){
          allColumns[10+hasEditionAcess].toggleHidden()
      }
      if(!clientSupplierActive){
          allColumns[11+hasEditionAcess].toggleHidden()
      }
      if(!notesActive){
          allColumns[12+hasEditionAcess].toggleHidden()
      }
      /*if(!additionalInfoActive){
          allColumns[14+hasEditionAcess].toggleHidden()
      }*/
  },[columns])

        
        const defaultColumn = React.useMemo(
          () => ({
            // Let's set up our default Filter UI
            Filter: DefaultColumnFilter,
            minWidth: 10, // minWidth is only used as a limit for resizing
          width: 100, // width is used for both the flex-basis and flex-grow
          maxWidth: 1800, // maxWidth is only used as a limit for resizing
          }),
          []
        )
  
        const scrollBarSize = React.useMemo(() => scrollbarWidth(), [])
  
        const sortees = React.useMemo(
          ()=> [
            {
              id:'index',
              desc:false
            }
          ],
          []
        )
  
        const sorteesTrue = React.useMemo(
          ()=> [
            {
              id:'index',
              desc:true
            }
          ],
          []
        )
      
        const {
          getTableProps,
          getTableBodyProps,
          headerGroups,
          rows,
          totalColumnsWidth,
          prepareRow,
          setAllFilters,
          selectedFlatRows,
          allColumns,
          state,
          setSortBy,
          visibleColumns,
          preGlobalFilteredRows,
          setGlobalFilter,
          state: { pageIndex,pageSize, globalFilter, selectedRowIds, }
        } = useTable(
          {
            columns,
            data,
            autoResetPage: false,
            autoResetFilters: false,
            autoResetGlobalFilter: false,
            initialState: {
              pageSize:1000,
              hiddenColumns:[]
              //sortBy: sortees
            },
            defaultColumn, // Be sure to pass the defaultColumn option
            filterTypes,
            autoResetSortBy:false
          },
          useFilters, // useFilters!
          useGlobalFilter, // useGlobalFilter!
          useSortBy,
          useRowSelect,
          useBlockLayout,
          useResizeColumns,
          hooks => {
            if(userAccess && userAccess.access3){
              hooks.visibleColumns.push(columns => [
                // Let's make a column for selection
                {
                  id: 'selection',
                  width:30,
                  // The header can use the table's getToggleAllRowsSelectedProps method
                  // to render a checkbox
                  Header: ({ getToggleAllRowsSelectedProps }) => (
                    <div className=''>
                      <IndeterminateCheckbox {...getToggleAllRowsSelectedProps()} />
                    </div>
                  ),
                  // The cell can use the individual row's getToggleRowSelectedProps method
                  // to the render a checkbox
                  Cell: ({ row }) => (
                    <div className=' justify-center flex text-center '>
                      <IndeterminateCheckbox {...row.getToggleRowSelectedProps()} />
                    </div>
                  ),
                },
                ...columns,
              ])
            }
            
          }
        )
  
        
  
        const RenderRow = React.useCallback(
          (rows) => ({ index, style }) => {
            const row = rows[index];
            prepareRow(row);
            return (
              <div className=''
              {...row.getRowProps({
                style
              })}
              >
                {row.cells.map((cell) => {
                  return (
                    <div><td {...cell.getCellProps()} className='w-[100%] '>{createCellOfType(cell,row)}</td></div>
                  );
                })}
              </div>
            );
          },
          [prepareRow,data]
        );
  
  
        useEffect(()=>{
          if(selectedFlatRows.length === 1){
            if(selectedFlatRows[0].original.id == transactionId){
            }else{
              setSelectedTransaction(selectedFlatRows[0])
              setTransactionId(selectedFlatRows[0].original.id)
              setTransactionQuantities(selectedFlatRows[0].original.inflow > 0 ? [{context:selectedFlatRows[0].original.context,value:[selectedFlatRows[0].original.inflow],category:{name:'Categoría'},subcategory:{name:'Subcategoría'}},]:[{value:[selectedFlatRows[0].original.outflow],category:{name:'Categoría'},subcategory:{name:'Subcategoría'}}])
            }
            let inflowTotal = {[`${Idioma.transacciones.equivalente[language]} ${mainCurrency}`]:null}
              let outflowTotal = {[`${Idioma.transacciones.equivalente[language]} ${mainCurrency}`]:null}
              for(let i=0;i<selectedFlatRows.length;i++){
                if(!inflowTotal[selectedFlatRows[i].original.currency]){
                  inflowTotal[selectedFlatRows[i].original.currency] = 0
                }
                if(!outflowTotal[selectedFlatRows[i].original.currency]){
                  outflowTotal[selectedFlatRows[i].original.currency] = 0
                }
                inflowTotal[selectedFlatRows[i].original.currency] = inflowTotal[selectedFlatRows[i].original.currency] + selectedFlatRows[i].original.inflow
                outflowTotal[selectedFlatRows[i].original.currency] = outflowTotal[selectedFlatRows[i].original.currency] + selectedFlatRows[i].original.outflow
                inflowTotal[[`${Idioma.transacciones.equivalente[language]} ${mainCurrency}`]] = inflowTotal[[`${Idioma.transacciones.equivalente[language]} ${mainCurrency}`]] + selectedFlatRows[i].original.inflow * (selectedFlatRows[i].original.exchange||1)
                outflowTotal[[`${Idioma.transacciones.equivalente[language]} ${mainCurrency}`]] = outflowTotal[[`${Idioma.transacciones.equivalente[language]} ${mainCurrency}`]] + selectedFlatRows[i].original.outflow * (selectedFlatRows[i].original.exchange||1)
              }
              setTotals({inflow:inflowTotal,outflow:outflowTotal})
          }else if(selectedFlatRows.length === 0){
            setTotals({})
            setForexValue('')
            setSelectedTransaction([])
            setTransactionId(null)
            setTransactionQuantities([])
          }
          else{
              setSelectedTransaction([])
              setTransactionId(null)
              setTransactionQuantities([])
              let inflowTotal = {[`${Idioma.transacciones.equivalente[language]} ${mainCurrency}`]:null}
              let outflowTotal = {[`${Idioma.transacciones.equivalente[language]} ${mainCurrency}`]:null}
              for(let i=0;i<selectedFlatRows.length;i++){
                if(!inflowTotal[selectedFlatRows[i].original.currency]){
                  inflowTotal[selectedFlatRows[i].original.currency] = 0
                }
                if(!outflowTotal[selectedFlatRows[i].original.currency]){
                  outflowTotal[selectedFlatRows[i].original.currency] = 0
                }
                inflowTotal[selectedFlatRows[i].original.currency] = inflowTotal[selectedFlatRows[i].original.currency] + selectedFlatRows[i].original.inflow
                outflowTotal[selectedFlatRows[i].original.currency] = outflowTotal[selectedFlatRows[i].original.currency] + selectedFlatRows[i].original.outflow
                inflowTotal[[`${Idioma.transacciones.equivalente[language]} ${mainCurrency}`]] = inflowTotal[[`${Idioma.transacciones.equivalente[language]} ${mainCurrency}`]] + selectedFlatRows[i].original.inflow * (selectedFlatRows[i].original.exchange||1)
                outflowTotal[[`${Idioma.transacciones.equivalente[language]} ${mainCurrency}`]] = outflowTotal[[`${Idioma.transacciones.equivalente[language]} ${mainCurrency}`]] + selectedFlatRows[i].original.outflow * (selectedFlatRows[i].original.exchange||1)
              }
              setTotals({inflow:inflowTotal,outflow:outflowTotal})
          }
          
          
        },[selectedFlatRows])
  
        const closeRecycleBin = () => {
          setDeletedData([])
          setOpenDeletedTransactions(false)
        }
  
        const openRecycleBin = () => {
        setOpenDeletedTransactions(true)
        setRecycleBinLoading(true)
        let headers = new Headers()
        const bearer = `Bearer ${accessToken}`;
        headers.append("Authorization", bearer);
        headers.append("Content-Type",'application/json')
        let body = {
          "clientID": idClient,
          "currency":mainCurrency,
          logsInfo:{
            origin:'planningTransactions',
            sessionID:sessionID
        }
        }
        if(idRS){
          body.idRS = idRS
        }
        fetch(`${urlBase}/planning/getDeletedTransactions`,{
            method: 'POST',
            headers: headers,
            body: JSON.stringify(body)
        })
        .then((response) => response.json())
        .then((aux) => {
          let newData = []
          for(let i = 0;i<aux.length;i++){
            newData[i] = {
                'date':aux[i].date_d.slice(0,10),
                'id':aux[i].idTransaction,
                'scenario':aux[i].name,
                'context':aux[i].context,
                'reference':aux[i].references_c,
                'inflow':aux[i].inflow ,
                'outflow':aux[i].outflow,
                'category':categoriesDict[categoriesDict[aux[i].idCategory]?.idFather]?.name || '',
                'idCategory':categoriesDict[aux[i].idCategory]?.idFather || '',
                'subcategory':categoriesDict[aux[i].idCategory]?.name || '',
                'idSubCategory':aux[i].idCategory,
                'exchange':aux[i].exchange || aux[i].defaultFX,
                "updated":null,
                'accountType':aux[i].accountType==='D'?Idioma.transacciones.debito[language]:aux[i].accountType==='C' ? Idioma.transacciones.credito[language] :'NA',
                'signature':aux[i].transactionSignature,
                'notes': aux[i].notes,
                'idClientSupplier':aux[i].idClientSupplier,
                'clientSupplier':clientSupplierDict[aux[i].idClientSupplier]?.name,
                'currency': aux[i].currency,
                'rsName': aux[i].rsAlias,
                'deleted': aux[i].deleted.slice(0,10)
            }
          }
          setDeletedData(newData)
          setRecycleBinLoading(false)
        });
        }
  
    const restoreFromRecycleBin = async () => {
        let headers = new Headers()
        const bearer = `Bearer ${accessToken}`;
        headers.append("Authorization", bearer);
        headers.append("Content-Type",'application/json')

        let allTransactionIds = []
        for(let i=0;i<selectedDeletedTransactions.length; i++){
            allTransactionIds[i]=selectedDeletedTransactions[i].original.id
        }


        let body = {
          "clientID": idClient,
          "transactions": allTransactionIds,
          logsInfo:{
            origin:'planningTransactions',
            sessionID:sessionID
        }
      }
          if(idRS){
            body.idRS = idRS
          }
        fetch(`${urlBase}/planning/restoreFromRecycleBin`,{
            method: 'POST',
            headers: headers,
            body: JSON.stringify(body)
        })
        .then((response) => response.json())
        .then((aux) => {
            setErrorSign(aux.unaffected.length >= 1)
            setShowTransactionsError(aux.unaffected.length >= 1)
            setUnaffectedTransactions(aux.unaffected)
            for(let i=0;i<aux.restored.length;i++){
                setDeletedData(prev => prev.filter(datum => datum.id!==aux.restored[i]))
            }
            setOpenDeleteTransactionsWarning(false)
            getTransactions('')
        })
        .catch(error=>{
            alert('Ha ocurrido un error')
        })
    }
  
      const emptyRecycleBin = () => {
        let headers = new Headers()
        const bearer = `Bearer ${accessToken}`;
        headers.append("Authorization", bearer);
        headers.append("Content-Type",'application/json')  
        let body = {
        "clientID": idClient,
        logsInfo:{
          origin:'planningTransactions',
          sessionID:sessionID
      }
        }
        if(idRS){
        body.idRS = idRS
        }
        fetch(`${urlBase}/planning/emptyRecycleBin`,{
            method: 'POST',
            headers: headers,
            body: JSON.stringify(body)
        })
        .then((response) => response.json())
        .then((aux) => {
            setDeletedData([])
            setOpenDeletedTransactions(false)
            })
        .catch(error=>{
            
        })
      }
  
      const removeFromRecycleBin = () => {
        let headers = new Headers()
        const bearer = `Bearer ${accessToken}`;
        headers.append("Authorization", bearer);
        headers.append("Content-Type",'application/json')

        let allTransactionIds = []
        for(let i=0;i<selectedDeletedTransactions.length; i++){
        allTransactionIds[i]=selectedDeletedTransactions[i].original.id
        }

        let body = {
          "clientID": idClient,
          "transactions": allTransactionIds,
          logsInfo:{
            origin:'planningTransactions',
            sessionID:sessionID
        }
      }
          if(idRS){
            body.idRS = idRS
          }
        fetch(`${urlBase}/planning/deleteTransaction`,{
            method: 'POST',
            headers: headers,
            body: JSON.stringify(body)
        })
        .then((response) => response.json())
        .then((aux) => {
            setShowTransactionsError(aux.unaffected.length >= 1)
            setErrorSign(aux.unaffected.length >= 1)

            setUnaffectedTransactions(aux.unaffected)
            for(let i=0;i<aux.deleted.length;i++){
            setDeletedData(prev => prev.filter(datum => datum.id!==aux.deleted[i]))
            }
            setOpenDeleteTransactionsWarning(false)
            getTransactions('')
        })
        .catch(error=>{
            alert('Ha ocurrido un error')
        })
    }

    
    const cleanTransactions = () => {
      let headers = new Headers()
      const bearer = `Bearer ${accessToken}`;
      headers.append("Authorization", bearer);
      headers.append("Content-Type",'application/json')
      
      let allTransactionIds = []
      for(let i=0;i<selectedFlatRows.length; i++){
        allTransactionIds[i]=selectedFlatRows[i].original.id
      }

      let body = {
        "clientID": idClient,
        "transactions": allTransactionIds,
        "clear": true,
        logsInfo:{
          origin:'planningTransactions',
          sessionID:sessionID
      }
    }
          if(idRS){
            body.idRS = idRS
          }
      fetch(`${urlBase}/planning/updateTransaction`,{
          method: 'POST',
          headers: headers,
          body: JSON.stringify(body)
      })
      .then((response) => response.json())
      .then((aux) => {
          setOpenCleanTransactionsWarning(false)
          if(aux.updates.length>0){
            newToast('success', Idioma.transacciones.transActualizadas[language], aux.updates.length)
          }
          if(aux.unaffected.length >0){
            newToast('error', Idioma.transacciones.transNoActualizadas[language], aux.unaffected.length)
          }
          //setShowTransactionsUpdated(aux.updates.length>=1)
          //setUpdatedTransactions(aux.updates.length)
          //setErrorSign(aux.unaffected.length >= 1)
          //setErrorMessage(aux.unaffected.length >= 1 ? Idioma.transacciones.transNoActualizadas[language] : '')
          //setShowTransactionsError(aux.unaffected.length >= 1)
          //setUnaffectedTransactions(aux.unaffected)
          getTransactions('')
          
          })
          .catch(error=>{
              alert('Ha ocurrido un error')

      })
  }

  
    const contextRSIDText = () => {
        let value = ''
        if(Object.keys(rsDict).length>0){
            for(let i=0;i<contextRSID.length;i++){
            value = value + rsDict[contextRSID[i]].name + '\n'
            }
        }
        return value
    }
  
    const contextCategoriesIdsFilter = (titleOrCount) => {
        let value = ''
        let count = 0
        if(Object.keys(categoriesDict).length>0){
            for(let i=0;i<contextCategoriesId.length;i++){
            if(categoriesDict[contextCategoriesId[i]]){
                count++
                value = value + categoriesDict[contextCategoriesId[i]].name + '\n'
            }
            
            }
        }
        if(titleOrCount){
            return count
        }
        return value
    }
  
      
  
    const resetFilters = () => {
    setAllFilters([])

    }

    const handleOpenRecurrence = () => {
        setOpenRecurrence(true)
        let aux = new Date(selectedTransaction.original.date)
        aux = new Date(aux.getTime() + aux.getTimezoneOffset() * 60000)
        setRecurrenceEndDateMinLimit(aux)
        setRecurrenceEndDate(aux)
        setRecurrence({name:Idioma.planeacion.seleccionaRecurrencia[language]})
        let aux1 = [{
            name:Idioma.planeacion.opcionesRecurrencia.noSeRepite[language],
            id:'none'
        },
        {
            name:Idioma.planeacion.opcionesRecurrencia.diario[language],
            id:'daily'
        },
        {
            name:`${Idioma.planeacion.opcionesRecurrencia.semanal[language]} ${moment(selectedTransaction?.original?.date).locale(language).format('dddd')}`,
            id:'weeklyOnDD'
        }]

        if(parseInt((selectedTransaction?.original?.date?.split('-')[2]-1)/7+1)<=4){
            aux1[aux1.length] = {
                name:`${Idioma.planeacion.opcionesRecurrencia.mensual[language]} ${Idioma.nums[`${parseInt((selectedTransaction?.original?.date?.split('-')[2]-1)/7+1)}s`][language]} ${moment(selectedTransaction?.original?.date).locale(language).format('dddd')}`,
                id:'monthlyOnNDD'
            }
        }
        if(moment(selectedTransaction.original.date).month()!==moment(selectedTransaction.original.date).add(1,'week').month()){
            aux1[aux1.length] = {
                name:`${Idioma.planeacion.opcionesRecurrencia.mensual[language]} ${Idioma.nums.ultimo[language]} ${moment(selectedTransaction?.original?.date).locale(language).format('dddd')}`, 
                id:'monthlyLastDD'
            }
        }
        
        setRecurrenceOptions(aux1)
    }

    const handleCloseRecurrence = () => {
        setOpenRecurrence(false)
        setRecurrenceEndDateMinLimit()
        setRecurrenceRepeats()
        setRecurrenceRepeatsLimit()

    }

    const CalculateRecurrence = (date) => {//YYYY-MM-DD
        let datesArray = []
        if(recurrence.id==='daily'){
            if(recurrenceRepeats>0){
                datesArray[0] = moment(date).add(1,'days').format('YYYY-MM-DD')
                for(let i=1;i<recurrenceRepeats;i++){
                    datesArray[i] = moment(datesArray[i-1]).add(1,'days').format('YYYY-MM-DD')
                }
            }
            if(recurrenceEndDate){
                datesArray[0] = moment(date).add(1,'days').format('YYYY-MM-DD')
                while(moment(datesArray[datesArray.length-1])<moment(recurrenceEndDate)){
                    datesArray[datesArray.length] = moment(datesArray[datesArray.length-1]).add(1,'days').format('YYYY-MM-DD')
                }
            }
        }
        if(recurrence.id==='weeklyOnDD'){
            if(recurrenceRepeats>0){
                datesArray[0] = moment(date).add(1,'week').format('YYYY-MM-DD')
                for(let i=1;i<recurrenceRepeats;i++){
                    datesArray[i] = moment(datesArray[i-1]).add(1,'week').format('YYYY-MM-DD')
                }
            }
            if(recurrenceEndDate){
                datesArray[0] = moment(date).add(1,'week').format('YYYY-MM-DD')
                while(moment(datesArray[datesArray.length-1])<=moment(recurrenceEndDate).subtract(1,'week')){
                    datesArray[datesArray.length] = moment(datesArray[datesArray.length-1]).add(1,'week').format('YYYY-MM-DD')
                }
            }
        }
        if(recurrence.id==='monthlyOnNDD'){
            let n = parseInt((date.split('-')[2]-1)/7+1)
            if(recurrenceRepeats>0 && n<5){
                datesArray[0] = moment(date).add(4,'week').format('YYYY-MM-DD')
                if(n!==parseInt((datesArray[0].split('-')[2]-1)/7+1)){
                    datesArray[0] = moment(datesArray[0]).add(1,'week').format('YYYY-MM-DD')
                }
                for(let i=1;i<recurrenceRepeats;i++){
                    datesArray[i] = moment(datesArray[i-1]).add(4,'week').format('YYYY-MM-DD')
                    if(n!==parseInt((datesArray[i].split('-')[2]-1)/7+1)){
                        datesArray[i] = moment(datesArray[i]).add(1,'week').format('YYYY-MM-DD')
                    }
                }
            }
            if(recurrenceEndDate){
                datesArray[0] = moment(date).add(4,'week').format('YYYY-MM-DD')
                if(n!==parseInt((datesArray[0].split('-')[2]-1)/7+1)){
                    datesArray[0] = moment(datesArray[0]).add(1,'week').format('YYYY-MM-DD')
                }
                while(moment(datesArray[datesArray.length-1])<=moment(recurrenceEndDate).subtract(4,'week')){
                    datesArray[datesArray.length] = moment(datesArray[datesArray.length-1]).add(4,'week').format('YYYY-MM-DD')
                    if(n!==parseInt((datesArray[datesArray.length-1].split('-')[2]-1)/7+1)){
                        datesArray[datesArray.length-1] = moment(datesArray[datesArray.length-1]).add(1,'week').format('YYYY-MM-DD')
                    }
                }
                if(moment(datesArray[datesArray.length-1])>moment(recurrenceEndDate)){//porsiacaso
                    datesArray.pop()
                }
            }
        }
        if(recurrence.id==='monthlyLastDD'){
            if(recurrenceRepeats>0){
                datesArray[0] = moment(date).add(4,'week').format('YYYY-MM-DD')
                if(moment(datesArray[0]).month()===moment(datesArray[0]).add(1,'week').month() ){
                    datesArray[0] = moment(datesArray[0]).add(1,'week').format('YYYY-MM-DD')
                }
                for(let i=1;i<recurrenceRepeats;i++){
                    datesArray[i] = moment(datesArray[i-1]).add(4,'week').format('YYYY-MM-DD')
                    if(moment(datesArray[i]).month()===moment(datesArray[i]).add(1,'week').month()){
                        datesArray[i] = moment(datesArray[i]).add(1,'week').format('YYYY-MM-DD')
                    }
                }
            }
            if(recurrenceEndDate){
                datesArray[0] = moment(date).add(4,'week').format('YYYY-MM-DD')
                if(moment(datesArray[0]).month()===moment(datesArray[0]).add(1,'week').month()){
                    datesArray[0] = moment(datesArray[0]).add(1,'week').format('YYYY-MM-DD')
                }
                while(moment(datesArray[datesArray.length-1])<=moment(recurrenceEndDate).subtract(4,'week')){
                    datesArray[datesArray.length] = moment(datesArray[datesArray.length-1]).add(4,'week').format('YYYY-MM-DD')
                    if(moment(datesArray[datesArray.length-1]).month()===moment(datesArray[datesArray.length-1]).add(1,'week').month()){
                        datesArray[datesArray.length-1] = moment(datesArray[datesArray.length-1]).add(1,'week').format('YYYY-MM-DD')
                    }
                }   
                if(moment(datesArray[datesArray.length-1])>moment(recurrenceEndDate)){//porsiacaso
                    datesArray.pop()
                }
            }
        }
        return datesArray
    }

    const TransactionRecurrence = () => {        
        let datesArray = CalculateRecurrence(selectedTransaction.original.date)
        /**/
        let headers = new Headers()

        const bearer = `Bearer ${accessToken}`;
        headers.append("Authorization", bearer);
        headers.append("Content-Type",'application/json')
        let body = {
            "clientID": idClient,
            "transactionID":selectedTransaction.original.id,
            'recurrence':datesArray,
            logsInfo:{
              origin:'planningTransactions',
              sessionID:sessionID
          }
        }
        if(idRS){
            body.idRS = idRS
        }

        fetch(`${urlBase}/planning/recurrenceTransaction`,{
        method: 'POST',
        headers: headers,
        body: JSON.stringify(body)
        })
        .then((response) => response.json())
        .then((aux) => {
        setAddTransactionLoading(false)
        getTransactions('')
        handleCloseRecurrence()
        })
        .catch(error=>{
        alert('Ha ocurrido un error')
        })
        /**/
    }
    

    const handleChangeRecurrence = (value) => {
        setRecurrence(value)
        if(value.id==='daily'){
            setRecurrenceRepeatsLimit(Math.floor((recurrenceEndDateLimit - new Date()) / (1000*60*60*24)))
            let aux = moment(selectedTransaction.original.date).add(1,'days').format('YYYY-MM-DD')
            aux = new Date(aux)
            aux = new Date(aux.getTime() + aux.getTimezoneOffset() * 60000)
            setRecurrenceEndDateMinLimit(aux)
            if(!recurrenceRepeats && recurrenceEndDate<aux){
                setRecurrenceEndDate(aux)
            }
            return
        }
        if(value.id==='weeklyOnDD'){
            setRecurrenceRepeatsLimit(Math.floor((recurrenceEndDateLimit - new Date()) / (1000*60*60*24*7)))
            let aux = moment(selectedTransaction.original.date).add(1,'week').format('YYYY-MM-DD')
            aux = new Date(aux)
            aux = new Date(aux.getTime() + aux.getTimezoneOffset() * 60000)
            setRecurrenceEndDateMinLimit(aux)
            if(!recurrenceRepeats && recurrenceEndDate<aux){
                setRecurrenceEndDate(aux)
            }
            return
        }
        if(value.id==='monthlyOnNDD'){
            setRecurrenceRepeatsLimit(Math.floor((recurrenceEndDateLimit - new Date()) / (1000*60*60*24*30)))
            let n = parseInt((selectedTransaction?.original?.date?.split('-')[2]-1)/7+1)
            let aux = moment(selectedTransaction.original.date).add(4,'week').format('YYYY-MM-DD')
            if(n!==parseInt((aux.split('-')[2]-1)/7+1)){
                aux = moment(aux).add(1,'week').format('YYYY-MM-DD')
            }
            aux = new Date(aux)
            aux = new Date(aux.getTime() + aux.getTimezoneOffset() * 60000)
            setRecurrenceEndDateMinLimit(aux)
            if(!recurrenceRepeats && recurrenceEndDate<aux){
                setRecurrenceEndDate(aux)
            }
            return
        }
        setRecurrenceRepeatsLimit()
    }

    const handleChangeRecurrenceRepeats = (value) => {
        setRecurrenceRepeats(value)
        setRecurrenceEndDate()
    }

    const handleChangeRecurrenceEndDate = (value) => {
        setRecurrenceEndDate(value)
        setRecurrenceRepeats('')
    }

    const checkRecurrenceRepeat = () => {
        if(recurrenceRepeats>recurrenceRepeatsLimit){
            setRecurrenceRepeats(recurrenceRepeatsLimit)
        }
    }

    const handleNameScenarioChange = (value,info) => {
        if(value==info.name || value.length==0){
            return
        }else{
            let headers = new Headers()

            const bearer = `Bearer ${accessToken}`;
            headers.append("Authorization", bearer);
            headers.append("Content-Type",'application/json')
            let body = {
              "clientID": idClient,
              "idScenario": info.id,
              "name": value,
              logsInfo:{
                origin:'planningTransactions',
                sessionID:sessionID
            }
          }
          if(idRS){
            body.idRS = idRS
          }
            fetch(`${urlBase}/planning/updateScenario`,{
                method: 'POST',
                headers: headers,
                body: JSON.stringify(body)
            })
            .then((response) => response.json())
            .then((aux) => {
                getScenarioData()
            })
            .catch(error=>{
            alert('Ha ocurrido un error')
            })
        }
    }

    const handleNotesScenarioChange = (value,info) => {
        if(value!=info.notes && value.length>0 || (info.notes && value!=info.notes)){
            let headers = new Headers()

            const bearer = `Bearer ${accessToken}`;
            headers.append("Authorization", bearer);
            headers.append("Content-Type",'application/json')
            let body = {
              "clientID": idClient,
              "idScenario": info.id,
              "notes": value,
              logsInfo:{
                origin:'planningTransactions',
                sessionID:sessionID
            }
          }
          if(idRS){
            body.idRS = idRS
          }
            fetch(`${urlBase}/planning/updateScenario`,{
                method: 'POST',
                headers: headers,
                body: JSON.stringify(body)
            })
            .then((response) => response.json())
            .then((aux) => {
                getScenarioData()
            })
            .catch(error=>{
            alert('Ha ocurrido un error')
            })
        }
    }
    const newToast = (type, title, message) => {
      let toastProperties = []
      toastProperties = {
        'id': toastList.length+1,
        'alertType': type,
        'alertTitle': title,
        'alertMessage': message
      }
      setToastList((prev)=>[...prev, toastProperties])
    }
      
        
      
    // We don't want to render all of the rows for this example, so cap
    // it for this use case
    const firstPageRows = rows.slice(0, 100)
    const activeClass='bg-light-purple-3 text-white rounded-button 2xl:h-button-3 h-full text-button-2 font-bold px-[13px]'
    const unactiveClass='bg-gray-6 text-neutral-3 rounded-button 2xl:h-button-3 h-full text-button-2 font-normal px-[13px]'
    const fade='bg-gradient-to-r from-md-blue to-md-light-blue text-white text-xl font-extrabold rounded' 
    const classified = (element) => {
        if(element.subcategory.idSubCategory){
            return true
        }else{
            return false
        }
    }
        
    const breakTransactionDisabled = selectedTransaction.original ? ((selectedTransaction.original.inflow>0 ? selectedTransaction.original.inflow==getTransactionQuantityTotal() : selectedTransaction.original.outflow==getTransactionQuantityTotal() ) && transactionQuantities.every(classified) ) : false
          
       
  
    const breakTransactionStyle = breakTransactionDisabled ? '':'bg-gray-6 ' 

    if(openPreferencesModal || openRulesModal || openTransactionModal || openAddTransaction){
        document.body.classList.add('overflow-y-hidden')
    }else{
        document.body.classList.remove('overflow-y-hidden')
    }

    const ToggleExtraCol = ({extraCol,index}) => {
        const toggleFunc = () => {
            
            setExtraColumnsActive({...extraColumnsActive,[extraCol.accessor]:!extraColumnsActive[extraCol.accessor]})
        }
        return <div   className='text-p5-2xl 2xl:text-p5-2xl flex justify-between items-center'>
            <p>{extraCol.Header}</p>
            <div>
                <label for={`default-toggle-${extraCol.accessor}`} class={"flex relative items-center cursor-pointer "}>
                    <input type="checkbox" {...allColumns[13+index+hasEditionAcess].getToggleHiddenProps()} checked={extraColumnsActive[extraCol.accessor]} onClick={()=>toggleFunc()}  id={`default-toggle-${extraCol.accessor}`} class="sr-only peer"/>
                    <div class={Styles.toggleSwitch}></div>
                </label>
            </div>
        </div>
    }

    const infoDict = {
        inflow: Idioma.iconI.abono[language],
        outflow: Idioma.iconI.cargo[language],
        category: Idioma.iconI.categoria[language],
        subcategory: Idioma.iconI.subcategoria[language],
        exchange: Idioma.iconI.fx[language],

    }
    return (
          <div className='pl-[5%] 2xl:mt-6 mr-10 relative text-[12px] 2xl:text-[16px]'>
          <MessageHandler toastList={toastList} setToastList={setToastList}/>

            {openPreferencesModal && 
              <div className='fixed top-0 right-0 left-[15%] bottom-0 w-screen z-[1000] h-screen'>
                <div onClick={()=>setOpenPreferencesModal(false)} className={Styles.modalBackground}>
                </div>
                <div className={Styles.defaultModal + ' overflow-y-scroll'}>
                <div className='2xl:w-[400px] w-[400px] 2xl:h-full h-[60vh] relative'>
                  <p className={Styles.modalTitle}><SettingsLogo/> <span className='ml-[15px] 2xl:ml-[15px]'>{Idioma.transacciones.modales.favoritos[language]}</span></p>
                  
  
                  <div className='flex flex-col gap-y-[15px]'>
                    {rsOptions.length>0 && <div className='text-p5-2xl 2xl:text-p5-2xl flex justify-between items-center'>
                      <p>{Idioma.transacciones.configuracion.empresa[language]}</p>
                      <div>
                        <label for="default-toggle-RS" class={"flex relative items-center cursor-pointer "}>
                            <input type="checkbox" {...allColumns[0+hasEditionAcess].getToggleHiddenProps()} checked={rsActive} onClick={()=>{setRSActive((prev) => !prev)}}  id="default-toggle-RS" class="sr-only peer"/>
                            <div class={Styles.toggleSwitch}></div>
                        </label>
                      </div>
                    </div>}
                    {/*<div className='text-p5-2xl 2xl:text-p5-2xl flex justify-between items-center'>
                      <p>{Idioma.planeacion.escenario[language]}</p>
                      <div>
                        <label for="default-toggle-Escenario" class={"flex relative items-center cursor-pointer "}>
                            <input type="checkbox" {...allColumns[1+hasEditionAcess].getToggleHiddenProps()} checked={scenarioActive} onClick={()=>{setScenarioActive((prev) => !prev)}}  id="default-toggle-Escenario" class="sr-only peer"/>
                            <div class={Styles.toggleSwitch}></div>
                        </label>
                      </div>
            </div>*/}
                    <div className='text-p5-2xl 2xl:text-p5-2xl flex justify-between items-center'>
                      <p>{Idioma.transacciones.configuracion.fecha[language]}</p>
                      <div>
                        <label for="default-toggle-Fecha" class={"flex relative items-center cursor-pointer "}>
                            <input type="checkbox" {...allColumns[2+hasEditionAcess].getToggleHiddenProps()} checked={dateActive} onClick={()=>{setDateActive((prev) => !prev)}}  id="default-toggle-Fecha" class="sr-only peer"/>
                            <div class={Styles.toggleSwitch}></div>
                        </label>
                      </div>
                    </div>
                    <div className='text-p5-2xl 2xl:text-p5-2xl flex justify-between items-center'>
                      <p>{Idioma.transacciones.configuracion.concepto[language]}</p>
                      <div>
                        <label for="default-toggle-Concepto" class={"flex relative items-center cursor-pointer "}>
                            <input type="checkbox" {...allColumns[3+hasEditionAcess].getToggleHiddenProps()} checked={contextActive} onClick={()=>{setContextActive((prev) => !prev)}}  id="default-toggle-Concepto" class="sr-only peer"/>
                            <div class={Styles.toggleSwitch}></div>
                        </label>
                      </div>
                    </div>
                    <div className='text-p5-2xl 2xl:text-p5-2xl flex justify-between items-center'>
                      <p>{Idioma.transacciones.configuracion.abono[language]}</p>
                      <div>
                        <label for="default-toggle-Abono" class={"flex relative items-center cursor-pointer "}>
                            <input type="checkbox" {...allColumns[4+hasEditionAcess].getToggleHiddenProps()} checked={inflowActive} onClick={()=>{setInflowActive((prev) => !prev)}}  id="default-toggle-Abono" class="sr-only peer"/>
                            <div class={Styles.toggleSwitch}></div>
                        </label>
                      </div>
                    </div>
                    <div className='text-p5-2xl 2xl:text-p5-2xl flex justify-between items-center'>
                      <p>{Idioma.transacciones.configuracion.cargo[language]}</p>
                      <div>
                        <label for="default-toggle-Cargo" class={"flex relative items-center cursor-pointer "}>
                            <input type="checkbox" {...allColumns[5+hasEditionAcess].getToggleHiddenProps()} checked={outflowActive} onClick={()=>{setOutflowActive((prev) => !prev)}}  id="default-toggle-Cargo" class="sr-only peer"/>
                            <div class={Styles.toggleSwitch}></div>
                        </label>
                      </div>
                    </div>
  
                    <div className='text-p5-2xl 2xl:text-p5-2xl flex justify-between items-center'>
                      <p>{Idioma.transacciones.configuracion.moneda[language]}</p>
                      <div>
                        <label for="default-toggle-Moneda" class={"flex relative items-center cursor-pointer "}>
                            <input type="checkbox" {...allColumns[8+hasEditionAcess].getToggleHiddenProps()} checked={currencyActive} onClick={()=>{setCurrencyActive((prev) => !prev)}}  id="default-toggle-Moneda" class="sr-only peer"/>
                            <div class={Styles.toggleSwitch}></div>
                        </label>
                      </div>
                    </div>
  
                    <div className='text-p5-2xl 2xl:text-p5-2xl flex justify-between items-center'>
                      <p>{Idioma.transacciones.configuracion.fx[language]}</p>
                      <div>
                        <label for="default-toggle-FX" class={"flex relative items-center cursor-pointer "}>
                            <input type="checkbox" {...allColumns[9+hasEditionAcess].getToggleHiddenProps()} checked={fxActive} onClick={()=>{setFXActive((prev) => !prev)}}  id="default-toggle-FX" class="sr-only peer"/>
                            <div class={Styles.toggleSwitch}></div>
                        </label>
                      </div>
                    </div>
  
                    <div className='text-p5-2xl 2xl:text-p5-2xl flex justify-between items-center'>
                      <p>{Idioma.transacciones.configuracion.creditoDebito[language]}</p>
                      <div>
                        <label for="default-toggle-CD" class={"flex relative items-center cursor-pointer "}>
                            <input type="checkbox" {...allColumns[10+hasEditionAcess].getToggleHiddenProps()} checked={paidWithActive} onClick={()=>{setPaidWithActive((prev) => !prev)}}  id="default-toggle-CD" class="sr-only peer"/>
                            <div class={Styles.toggleSwitch}></div>
                        </label>
                      </div>
                    </div>
                    <div className='text-p5-2xl 2xl:text-p5-2xl flex justify-between items-center'>
                      <p>{Idioma.transacciones.configuracion.clientesProvedores[language]}</p>
                      <div>
                        <label for="default-toggle-CP" class={"flex relative items-center cursor-pointer "}>
                            <input type="checkbox" {...allColumns[11+hasEditionAcess].getToggleHiddenProps()} checked={clientSupplierActive} onClick={()=>{setClientSupplierActive((prev) => !prev)}}  id="default-toggle-CP" class="sr-only peer"/>
                            <div class={Styles.toggleSwitch}></div>
                        </label>
                      </div>
                    </div>
                    <div className='text-p5-2xl 2xl:text-p5-2xl flex justify-between items-center'>
                      <p>{Idioma.transacciones.configuracion.notas[language]}</p>
                      <div>
                        <label for="default-toggle-Notas" class={"flex relative items-center cursor-pointer "}>
                            <input type="checkbox" {...allColumns[12+hasEditionAcess].getToggleHiddenProps()} checked={notesActive} onClick={()=>{setNotesActive((prev) => !prev)}}  id="default-toggle-Notas" class="sr-only peer"/>
                            <div class={Styles.toggleSwitch}></div>
                        </label>
                      </div>
                    </div>
                    {/*<div className='text-p5-2xl 2xl:text-p5-2xl flex justify-between items-center'>
                      <p>{Idioma.transacciones.configuracion.informacionAdicional[language]}</p>
                      <div>
                        <label for="default-toggle-info-adicional" class={"flex relative items-center cursor-pointer "}>
                            <input type="checkbox" {...allColumns[13+hasEditionAcess].getToggleHiddenProps()} checked={additionalInfoActive} onClick={()=>{setAdditionalInfoActive((prev) => !prev)}}  id="default-toggle-info-adicional" class="sr-only peer"/>
                            <div class={Styles.toggleSwitch}></div>
                        </label>
                      </div>
                    </div>*/}
                    {columns.slice(13).map((extraCol,index)=>(
                    <ToggleExtraCol extraCol={extraCol} index={index}/>
                  ))}
                  </div>
                  {/*<p className='text-xl text-md-gray mt-1 font-bold text-left mb-2'>Filas</p>
                  <select
                    className='w-[100%] py-2 pl-4 bg-md-table-bg rounded-md border-1 shadow-sm mb-4 text-md-gray'
                    defaultValue={'Numero de transacciones visibles'}
                    value={pageSize}
                    onChange={e => {
                      setPageSize(Number(e.target.value))
                    }}
                  >
                    {[10, 50, 100, 500].map(pageSize => (
                      <option key={pageSize} value={pageSize}>
                        {pageSize}
                      </option>
                    ))}
                    </select>
                  <div className='border-b-1 mt-1 w-100% border-md-divider'></div>*/}
                  {/*<div className='grid grid-cols-4 text-[12px] 2xl:text-[16px] items-center 2xl:gap-y-4 gap-y-2 2xl:mt-8 mt-3'>
                    {rsOptions.length>0 && 
                    <>
                    <p className='font-bold text-left mr-8 col-start-1'>Empresa</p>
                    <Dropdown
                            options={rsOptions}
                            selected={rsID}
                            setSelected={setRSID}
                            className={'w-full col-span-3 bg-gray-6 rounded-md'}
                        /></>}
                    <p className='font-bold text-left mr-8 col-start-1'>Banco</p>
                    <Dropdown
                            options={[{'name': Idioma.transacciones.filtros.todos[language]},...BankOptions]}
                            selected={bank}
                            setSelected={handleBankChange}
                            className={'w-full col-span-3 bg-gray-6 rounded-md'}
                        />
                    <p className='font-bold text-left mr-8 col-start-1'>Cuenta</p>
                    <Dropdown
                        options={accounts}
                        selected={account}
                        setSelected={setContextAccount}
                        className={' w-full col-span-3 bg-gray-6 rounded-md '}
                    />
                    
                    <p className='font-bold text-left mr-8 col-start-1'>Archivo</p>
                    <FileDropdown //aqui va dropdown de files
                        options={filesData}
                        selected={file}
                        setSelected={handleFileChange}
                        className={'  w-full col-span-3 bg-gray-6 rounded-md'}
                    />
                    <p className='font-bold text-left mr-8 col-start-1'>Fecha</p>
                    
                    <div className='col-span-3'>
                        <DatePicker
                        onChange={(update) => {
                          handleDateRangeChange(update);
                        }}
                        startDate={startDate}
                        endDate={endDate}
                        selectsRange
                        fixedHeight
                        className='bg-gray-6 w-full rounded-[7px] border-1 pl-6 py-2 '
                        showYearDropdown
                        dropdownMode="select"
                        isClearable
                        dateFormat={Idioma.formatoFecha[language]}
                      />
                      </div>
                    <button disabled={isLoading} className={`${isLoading ? 'bg-gray-6 text-md-gray' : 'bg-[#9191F4] text-white'} rounded-md text-center  px-2 mt-1 py-2 font-bold col-span-4`} onClick={()=>getTransactions('')}>Aplicar</button>
                  </div>*/}
                  <span onClick={()=>setOpenPreferencesModal(false)} className={Styles.modalXmark}><XMark/></span>
                  </div>
                  {/*<div className='flex justify-center items-center mt-4'>
                    <button className='bg-md-blue text-md-white py-2 px-4 mr-10 w-1/3 rounded-full' onClick={()=>{setOpenPreferencesModal(false)}}>Guardar cambios</button>
                    <button className='bg-md-table-bg text-md-gray border-1 shadow-sm py-2 px-4 ml-10 w-1/3 rounded-full' onClick={()=>{setOpenPreferencesModal(false)}}>Cancelar</button>
                      </div>*/}
                </div>
              </div>}
              {openFiltersModal && 
              <div className='fixed top-0 right-0 left-[15%] bottom-0 w-screen z-[1000] h-screen'>
                <div onClick={()=>setOpenFiltersModal(false)} className={Styles.modalBackground}>
                </div>
                <div className={Styles.defaultModal}>
                <div className='w-full h-full relative'>
                <p className={Styles.modalTitle}><FiltersLogo/> <span className='ml-[15px] 2xl:ml-[15px]'>{Idioma.transacciones.modales.filtros[language]}</span></p>
                  <div className='grid grid-cols-4 text-[12px] 2xl:text-p8-2xl items-center 2xl:gap-y-[15px] gap-y-2 mt-[40px] w-[400px]'>
                    {rsOptions.length>0 && !idRS && 
                    <>
                    <p className='font-bold text-left col-start-1 text-p5-2xl'>{Idioma.transacciones.configuracion.empresa[language]}</p>
                    <div className='col-span-3'>
                    <DinamicDropdown
                            options={[{'name': Idioma.transacciones.filtros.todos[language]},...rsOptions]}
                            selected={rsID}
                            setSelected={setRSID}
                            className={'w-full py-2 pl-3 pr-10'}
                        />
                    </div>
                    </>}
                    <p className='font-bold text-left col-start-1 text-p5-2xl'>{Idioma.planeacion.escenario[language]}</p>
                    <div className='col-span-3'>
                    <DinamicDropdown //aqui va dropdown de files
                        options={scenarioOptions}
                        selected={scenarioFilter}
                        setSelected={handleScenarioChange}
                        className={'  w-full py-2 pl-3 pr-10'}
                    /></div>
                    
                    <p className='font-bold text-left col-start-1 text-p5-2xl'>{Idioma.transacciones.configuracion.archivoDrop[language]}</p>
                    <div className='col-span-3'>
                    <DinamicDropdown //aqui va dropdown de files
                        options={[{'name': Idioma.transacciones.filtros.todos[language] },...filesData]}

                        selected={file}
                        setSelected={handleFileChange}
                        className={'  w-full py-2 pl-3 pr-10'}
                        percentage={true}

                    /></div>
                    <p className='font-bold text-left col-start-1 text-p5-2xl'>{Idioma.transacciones.configuracion.fecha[language]}</p>
                    
                    <div className='col-span-3'>
                        <DatePicker
                        onChange={(update) => {
                          handleDateRangeChange(update);
                        }}
                        locale={language}
                        startDate={startDate}
                        endDate={endDate}
                        placeholderText='00/00/0000'
                        selectsRange
                        fixedHeight
                        className='bg-gray-6 placeholder:text-neutral-3 w-full rounded-button pl-6 py-2'
                        showYearDropdown
                        dropdownMode="select"
                        isClearable
                        dateFormat={Idioma.formatoFecha[language]}
                      />
                    </div>
                    <div className='col-span-4 flex justify-end gap-x-[15px] mt-[25px]'>
                      <button disabled={isLoading} className={isLoading ? Styles.saveButtonDisabled : Styles.saveButton} onClick={()=>getTransactions('')}>
                          {Idioma.transacciones.configuracion.aplicarBoton[language]}
                      </button>
                      <button onClick={()=>setOpenFiltersModal(false)} className={Styles.cancelButton}>
                          {Idioma.transacciones.anadirTransaccionModal.cancelar[language]}
                      </button>
                    </div>
                  </div>
                  <span  onClick={()=>setOpenFiltersModal(false)} className={Styles.modalXmark}><XMark/></span>
                  </div>
                  {/*<div className='flex justify-center items-center mt-4'>
                    <button className='bg-md-blue text-md-white py-2 px-4 mr-10 w-1/3 rounded-full' onClick={()=>{setOpenPreferencesModal(false)}}>Guardar cambios</button>
                    <button className='bg-md-table-bg text-md-gray border-1 shadow-sm py-2 px-4 ml-10 w-1/3 rounded-full' onClick={()=>{setOpenPreferencesModal(false)}}>Cancelar</button>
                      </div>*/}
                </div>
              </div>}
              
              {openDeleteTransactionsWarning && 
              <div className='fixed top-0 right-0 left-[15%] bottom-0 w-screen z-[1001] h-screen'>
                <div onClick={()=>setOpenDeleteTransactionsWarning(false)} className="fixed top-0 right-0 left-[0%] bottom-0 w-screen z-10 h-screen opacity-[.74] backdrop-brightness-[.15] bg-gray-6 backdrop-blur-[44px]">
                </div>
                <div className='bg-white z-10 absolute top-[calc(40%)] left-[35%] px-[14px] mt-10 py-[28px] w-[500px] rounded-main -translate-x-[50%] -translate-y-[50%]'>
                  <p className=' text-h7-2xl text-center'>{Idioma.transacciones.modalPapelera.eliminarPapeleraTitulo[language]}</p>
                  <p className=' text-p5-2xl text-center'>{Idioma.transacciones.modalPapelera.eliminarPapeleraTexto1[language]}{selectedDeletedTransactions.length} {Idioma.transacciones.modalPapelera.eliminarPapeleraTexto2[language]}</p>
                  <div className='flex justify-center items-center mt-[20px]'>
                    <button className='py-2 px-4 ml-2 rounded-button underline text-b4-2xl font-bold' onClick={()=>{setOpenDeleteTransactionsWarning(false)}}>{Idioma.transacciones.desgloseTransaccion.cancelar[language]}</button>
                    <button className={`py-2 px-4 rounded-button text-white bg-purple-1 text-b4-2xl font-bold`} onClick={()=>removeFromRecycleBin()}>{Idioma.subirTransacciones.eliminar[language]}</button>
                  </div>
                </div>
              </div>}
              {openAddTransaction && 
              <div className='fixed top-0 right-0 left-[15%] bottom-0 w-screen z-[1000] h-screen'>
                <div onClick={()=>handleCloseAddT()} className={Styles.modalBackground}>
                </div>
                <div className="bg-white p-[40px] z-10 absolute top-[40%] mt-20  left-[42.5%] shadow-medium-elevation rounded-main -translate-x-[50%] -translate-y-[50%]">
                <div className='2xl:w-[27rem] w-[24rem] h-full relative'>
                  <p className='text-[20px] 2xl:text-h6-2xl font-bold text-left flex items-center overflow-visible'><AddSingleTransactionLogo className='overflow-visible'/> <span className='ml-[15px]'>{Idioma.transacciones.anadirTransaccionModal.tituloModal[language]}</span></p>
                  <div className='grid grid-cols-3 mt-8 2xl:gap-y-4 gap-y-2 items-center'>
                    <p className='text-[12px] 2xl:text-p8-2xl'>{Idioma.planeacion.escenario[language]}</p>
                    <Dropdown
                        options={scenarioOptions}
                        selected={scenarioAddT}
                        setSelected={setScenarioAddT}
                        className={' w-full col-span-2 text-[10px] 2xl:text-p8-2xl bg-white rounded-button border-1 border-neutral-1 '}
                    />
                    {!idRS && rsOptions.length>0 && 
                    <><p className='text-[12px] 2xl:text-p8-2xl'>{Idioma.transacciones.configuracion.empresa[language]}</p>
                    <Dropdown
                        options={rsOptions}
                        selected={rsAddT}
                        setSelected={setRSAddT}
                        className={' w-full col-span-2 text-[10px] 2xl:text-p8-2xl bg-white rounded-button border-1 border-neutral-1 '}
                    /></>}
                    <p className='text-[12px] 2xl:text-p8-2xl'>{Idioma.transacciones.anadirTransaccionModal.fecha[language]}</p>
                    <div className='col-span-2'>
                      <DatePicker 
                      className={'h-8 pl-6 text-[10px] 2xl:text-p8-2xl bg-white rounded-button border-1 border-neutral-1 mb-2'} 
                      selected={dateAddT} 
                      locale={language}
                      onChange={(date) => handleDateAddTChange(date)}
                      fixedHeight
                      dateFormat={Idioma.formatoFecha[language]}
                      />
                    </div>
                    <p className='text-[12px] 2xl:text-p8-2xl'>{Idioma.transacciones.anadirTransaccionModal.concepto[language]}</p>
                    <input className='col-span-2 pl-6 bg-white border-1 border-neutral-1 w-[100%] text-[10px] 2xl:text-p8-2xl rounded-button px-2 py-2' value={conceptAddT} onChange={(e)=>setConceptAddT(e.target.value)} placeholder={Idioma.transacciones.anadirTransaccionModal.concepto[language]}></input>
                    <p className='text-[12px] 2xl:text-p8-2xl'>{Idioma.transacciones.anadirTransaccionModal.abono[language]}</p>
                    <input className='col-span-2 pl-6 bg-white border-1 border-neutral-1 w-[100%] text-[10px] 2xl:text-p8-2xl rounded-button px-2 py-2' type='number' value={inflowAddT} onChange={(e)=>handleValueAddTChange(e.target.value,'')} placeholder='--'></input>
                    <p className='text-[12px] 2xl:text-p8-2xl'>{Idioma.transacciones.anadirTransaccionModal.cargo[language]}</p>
                    <input className='col-span-2 pl-6 bg-white border-1 border-neutral-1 w-[100%] text-[10px] 2xl:text-p8-2xl rounded-button px-2 py-2' type='number' value={outflowAddT} onChange={(e)=>handleValueAddTChange('',e.target.value)} placeholder='--'></input>
                    <p className='text-[12px] 2xl:text-p8-2xl'>{Idioma.transacciones.anadirTransaccionModal.subCategoria[language]}</p>
                    <div className='col-span-2 relative flex'>
                      <select className=' pl-6 bg-white border-1 border-neutral-1 w-[100%] text-[10px] 2xl:text-p8-2xl rounded-button px-2 py-2 appearance-none' id="multiTransaction-id" value={categoryAddT} onChange={event=>setCategoryAddT(event.target.value)}>
                        <option disabled selected>{Idioma.transacciones.modales.seleccionarSubcategoria[language]}</option>
                        {categories.map(category=>{
                          return (
                            <optgroup label={category.name}>
                              {subcategories[category.index].map(subcategory => {
                                return (
                                  <option value={subcategory.idSubCategory}>{subcategory.name}</option>
                                )
                              })}
                            </optgroup>
                          )
                        })}
                      </select><span className='absolute flex items-center right-2 h-full pointer-events-none'><TrianguloAbajoLogo/></span>
                    </div>
                    <p className='text-[12px] 2xl:text-p8-2xl'>{Idioma.transacciones.configuracion.moneda[language]}</p>
                    <Dropdown
                        options={CurrencyOptions}
                        selected={currencyAddT}
                        setSelected={setCurrencyAddT}
                        className={' w-full col-span-2 text-[10px] 2xl:text-p8-2xl bg-white rounded-button border-1 border-neutral-1 '}
                    />
                    {currencyAddT.id!==mainCurrency && 
                    <>
                        <p className='text-[12px] 2xl:text-p8-2xl'>{Idioma.transacciones.configuracion.fx[language]}</p>
                        <input className='col-span-2 pl-6 bg-white border-1 border-neutral-1 w-[100%] text-[10px] 2xl:text-p8-2xl rounded-button px-2 py-2' type='number' value={exchangeAddT} onChange={(e)=>setExchangeAddT(e.target.value)} placeholder='--'></input>
                    </>}
                    <p className='text-[12px] 2xl:text-p8-2xl'>{Idioma.transacciones.configuracion.creditoDebito[language]}</p>
                    <Dropdown
                        options={creditDebitOptions}
                        selected={paidWithAddT}
                        setSelected={setPaidWithAddT}
                        className={' w-full col-span-2 text-[10px] 2xl:text-p8-2xl bg-white rounded-button border-1 border-neutral-1 '}
                    />
                    
                    <p className='text-[12px] 2xl:text-p8-2xl'>{Idioma.transacciones.configuracion.clientesProvedores[language]}</p>
                    <Dropdown
                        options={[{name:Idioma.planeacion.ninguno[language]},...clientSupplierOptions]}
                        selected={clientSupplierAddT}
                        setSelected={setClientSupplierAddT}
                        className={' w-full col-span-2 text-[10px] 2xl:text-p8-2xl bg-white rounded-button border-1 border-neutral-1'}
                        optionsMaxH='max-h-40'
                    />
                    <p className='text-[12px] 2xl:text-p8-2xl'>{Idioma.planeacion.recurrencia[language]}</p>
                    <Dropdown
                        options={recurrenceOptions}
                        selected={recurrence}
                        setSelected={handleChangeRecurrenceAddT}
                        className={' w-full col-span-2 text-[10px] 2xl:text-p8-2xl bg-white rounded-button border-1 border-neutral-1 '}
                    />
                    
                    <p className={`col-span-3 ${recurrence.id && recurrence.id!='none'?'':'hidden'}`}>{Idioma.planeacion.opcionesRecurrencia.termina[language]}</p>
                    <p className={`text-[12px] 2xl:text-p8-2xl ${recurrence.id && recurrence.id!='none'?'':'hidden'}`}>{Idioma.planeacion.opcionesRecurrencia.cuandoTermina[language]}</p>
                    <div className={`col-span-2 ${recurrence.id && recurrence.id!='none'?'':'hidden'}`}>
                      <DatePicker 
                      className={'h-8 pl-6 text-[10px] 2xl:text-p8-2xl bg-white rounded-button border-1 border-neutral-1 mb-2'} 
                      selected={recurrenceEndDate} 
                      locale={language}
                      onChange={(date) => handleChangeRecurrenceEndDate(date)}
                      fixedHeight
                      maxDate={recurrenceEndDateLimit.setFullYear(recurrenceEndDateLimit.getFullYear() + 2 )}
                      minDate={recurrenceEndDateMinLimit}
                      placeholderText={Idioma.transacciones.configuracion.fecha[language]}
                      dateFormat={Idioma.formatoFecha[language]}
                      />
                    </div>
                    <p className={`text-[12px] 2xl:text-p8-2xl ${recurrence.id && recurrence.id!='none'?'':'hidden'}`}>{Idioma.planeacion.opcionesRecurrencia.despuesDe[language]}(max {recurrenceRepeatsLimit})</p>
                    <input key={'recurrenceRepeats'} className={`col-span-2 pl-6 bg-white border-1 border-neutral-1 w-[100%] text-[10px] 2xl:text-p8-2xl rounded-button px-2 py-2 ${recurrence.id && recurrence.id!='none'?'':'hidden'}`} type='number' min='0' max={recurrenceRepeatsLimit} value={recurrenceRepeats} onBlur={()=>checkRecurrenceRepeat()} onChange={(e)=>handleChangeRecurrenceRepeats(e.target.value)} placeholder={Idioma.planeacion.opcionesRecurrencia.repeticiones[language]}></input>
                    
                  </div>
                  
                  <div className='flex justify-end items-center mt-8'>
                    <button className={` font-bold text-b3-2xl py-2 px-4 w-fit rounded-button ${AddTransactionDisabled || addTransactionLoading ? 'bg-gray-6 ':'bg-light-purple-3'}`} disabled={AddTransactionDisabled  || addTransactionLoading} onClick={()=>{AddSingleTransaction()}}>{addTransactionLoading ?  Idioma.transacciones.desgloseTransaccion.cargando[language]:Idioma.transacciones.desgloseTransaccion.guardar[language]}</button>
                    <button className='bg-secondary font-bold text-b3-2xl py-2 px-4 w-fit ml-2 rounded-button text-white' onClick={()=>{handleCloseAddT()}}>{Idioma.transacciones.anadirTransaccionModal.cancelar[language]}</button>
                  </div>
                  <span onClick={()=>handleCloseAddT()} className={Styles.modalXmark}><XMark/></span>
                  </div>
                </div>
              </div>}
              {openAddScenario && 
              <div className='fixed top-0 right-0 left-[15%] bottom-0 w-screen z-[1000] h-screen'>
                <div onClick={()=>handleCloseAddScenario()} className={Styles.modalBackground}>
                </div>
                <div className="bg-white p-[40px] z-10 absolute top-[40%] mt-20  left-[42.5%] shadow-medium-elevation rounded-main -translate-x-[50%] -translate-y-[50%]">
                <div className='2xl:w-[27rem] w-[24rem] h-full relative'>
                  <p className='text-[20px] 2xl:text-h6-2xl font-bold text-left flex items-center overflow-visible'><AddScenarioLogo className='overflow-visible'/> <span className='ml-[15px]'>{Idioma.planeacion.modalAnadir.titulo[language]}</span></p>
                  <div className='grid grid-cols-3 mt-8 2xl:gap-y-4 gap-y-2 items-center'>
                    
                    <p className='text-[12px] 2xl:text-p8-2xl'>{Idioma.planeacion.escenario[language]}</p>
                    <input className='col-span-2 pl-6 bg-white border-1 border-neutral-1 w-[100%] text-[10px] 2xl:text-p8-2xl rounded-button px-2 py-2' value={nameAddScenario} onChange={(e)=>setNameAddScenario(e.target.value)} placeholder={Idioma.planeacion.escenario[language]}></input>
                    
                  </div>
                  
                  <div className='flex justify-end items-center mt-8'>
                    <button className={` font-bold text-b3-2xl py-2 px-4 w-fit rounded-button ${AddScenarioDisabled ? 'bg-gray-6 ':'bg-light-purple-3'}`} disabled={AddScenarioDisabled} onClick={()=>{AddScenario()}}>{addTransactionLoading ?  Idioma.transacciones.desgloseTransaccion.cargando[language]:Idioma.transacciones.desgloseTransaccion.guardar[language]}</button>
                    <button className='bg-secondary font-bold text-b3-2xl py-2 px-4 w-fit ml-2 rounded-button text-white' onClick={()=>{handleCloseAddScenario()}}>{Idioma.transacciones.anadirTransaccionModal.cancelar[language]}</button>
                  </div>
                  <span onClick={()=>handleCloseAddScenario()} className={Styles.modalXmark}><XMark/></span>
                  </div>
                </div>
              </div>}
            {openEditScenario && 
                <div className='fixed top-0 right-0 left-[15%] bottom-0 w-screen z-[1000] h-screen'>
                <div onClick={()=>setOpenEditScenario(false)} className={Styles.modalBackground}>
                </div>
                <div className="bg-white p-[40px] z-10 absolute top-[40%] mt-20  left-[42.5%] shadow-medium-elevation rounded-main -translate-x-[50%] -translate-y-[50%]">
                <div className='2xl:w-[900px] w-[24rem] h-full relative'>
                <p className='text-[20px] 2xl:text-h6-2xl font-bold text-left flex items-center overflow-visible'><EditScenarioLogo/> <span className='ml-[15px]'>{Idioma.planeacion.modalEditar.titulo[language]}</span></p>
                <table className='mt-[40px] 2xl:gap-4 gap-2 table-auto w-full' >
                    <thead>
                        <tr className='text-left w-[900px] text-h9-2xl'>
                            <th className='w-[140px]'>
                                {Idioma.planeacion.escenario[language]}
                            </th>
                            <th className='w-[140px]'>
                                {Idioma.planeacion.modalEditar.fechaIni[language]}
                            </th>
                            <th className='w-[140px]'>
                                {Idioma.planeacion.modalEditar.fechaFin[language]}
                            </th>
                            <th className='w-[140px]'>
                                {Idioma.planeacion.modalEditar.creadoPor[language]}
                            </th>
                            <th className='w-[280px]'>
                                {Idioma.planeacion.modalEditar.notas[language]}
                            </th>
                        </tr>
                    </thead>
                    <tbody className=' text-p8-2xl'>
                        {scenarioOptions.map((scenarioOpt)=>(
                            <tr className='h-[40px]'>
                                <td>
                                    <input className=' appearance-none underline'  defaultValue={scenarioOpt.name} onBlur={(e)=>handleNameScenarioChange(e.target.value,scenarioOpt)}></input>
                                </td>
                                <td>
                                    {scenarioOpt.minDate ? moment(scenarioOpt.minDate).locale(language).format(language==='en' ?'MMM/DD/YYYY':'DD/MMM/YYYY').replace('.','') :''}
                                </td>
                                <td>
                                    {scenarioOpt.maxDate ? moment(scenarioOpt.maxDate).locale(language).format(language==='en' ?'MMM/DD/YYYY':'DD/MMM/YYYY').replace('.','') : ''}
                                </td>
                                <td className=' capitalize'>
                                    {scenarioOpt.userName.toLowerCase()}
                                </td>
                                <td>
                                    <input className=' appearance-none underline' placeholder={Idioma.planeacion.modalEditar.anadirNotas[language]} defaultValue={scenarioOpt.notes} onBlur={(e)=>handleNotesScenarioChange(e.target.value,scenarioOpt)}></input>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
                {/*<div className='flex justify-end items-center mt-8'>
                    <button className={` font-bold text-b3-2xl py-2 px-4 w-fit rounded-button ${AddScenarioDisabled ? 'bg-gray-6 ':'bg-light-purple-3'}`} disabled={AddScenarioDisabled} onClick={()=>{AddScenario()}}>{addTransactionLoading ?  Idioma.transacciones.desgloseTransaccion.cargando[language]:Idioma.transacciones.desgloseTransaccion.guardar[language]}</button>
                    <button className='bg-secondary font-bold text-b3-2xl py-2 px-4 w-fit ml-2 rounded-button text-white' onClick={()=>{setOpenEditScenario(false)}}>{Idioma.transacciones.anadirTransaccionModal.cancelar[language]}</button>
                </div>*/}
                <span onClick={()=>setOpenEditScenario(false)} className={Styles.modalXmark}><XMark/></span>
                </div>
              </div>
            </div>}
              {openCopyScenario && 
              <div className='fixed top-0 right-0 left-[15%] bottom-0 w-screen z-[1000] h-screen'>
                <div onClick={()=>handleCloseCopyScenario()} className={Styles.modalBackground}>
                </div>
                <div className="bg-white p-[40px] z-10 absolute top-[40%] mt-20  left-[42.5%] shadow-medium-elevation rounded-main -translate-x-[50%] -translate-y-[50%]">
                <div className='2xl:w-[27rem] w-[24rem] h-full relative'>
                  <p className='text-[20px] 2xl:text-h6-2xl font-bold text-left flex items-center overflow-visible'><CopyScenarioLogo fill='#000' className='overflow-visible'/> <span className='ml-[15px]'>{Idioma.planeacion.copiarAOtroEscenario[language]}</span></p>
                  <div className='grid grid-cols-3 mt-8 2xl:gap-y-4 gap-y-2 items-center'>
                    
                    <p className='text-[12px] 2xl:text-p8-2xl col-span-3'>{Object.keys(selectedRowIds).length} {Idioma.transacciones.transaccionesSeleccionadas[language]}</p>
                    <p className='text-[12px] 2xl:text-p8-2xl'>{Idioma.planeacion.escenario[language]}</p>
                    <Dropdown
                        options={scenarioOptions}
                        selected={copyScenario}
                        setSelected={setCopyScenario}
                        className={' w-full col-span-2 text-[10px] 2xl:text-p8-2xl bg-white rounded-button border-1 border-neutral-1 '}
                    />
                  </div>
                  <div className='flex justify-end items-center mt-8'>
                    <button className={` font-bold text-b3-2xl py-2 px-4 w-fit rounded-button ${copyScenarioDisabled ? 'bg-gray-6 ':'bg-light-purple-3'}`} disabled={copyScenarioDisabled} onClick={()=>{CopyTransactionsToScenario()}}>{addTransactionLoading ?  Idioma.transacciones.desgloseTransaccion.cargando[language]:Idioma.transacciones.desgloseTransaccion.guardar[language]}</button>
                    <button className='bg-secondary font-bold text-b3-2xl py-2 px-4 w-fit ml-2 rounded-button text-white' onClick={()=>{handleCloseCopyScenario()}}>{Idioma.transacciones.anadirTransaccionModal.cancelar[language]}</button>
                  </div>
                  <span onClick={()=>handleCloseCopyScenario()} className={Styles.modalXmark}><XMark/></span>
                  </div>
                </div>
              </div>}
              {openRecurrence && 
              <div className='fixed top-0 right-0 left-[15%] bottom-0 w-screen z-[1000] h-screen'>
                <div onClick={()=>handleCloseRecurrence()} className={Styles.modalBackground}>
                </div>
                <div className="bg-white p-[40px] z-10 absolute top-[40%] mt-20  left-[42.5%] shadow-medium-elevation rounded-main -translate-x-[50%] -translate-y-[50%]">
                <div className='2xl:w-[27rem] w-[24rem] h-full relative'>
                  <p className='text-[20px] 2xl:text-h6-2xl font-bold text-left flex items-center overflow-visible'><RecurrenceLogo fill='#000' className='overflow-visible'/> <span className='ml-[15px]'>{Idioma.planeacion.recurrencia[language]}</span></p>
                  <div className='grid grid-cols-3 mt-8 2xl:gap-y-4 gap-y-2 items-center'>
                    
                    <p className='text-[12px] 2xl:text-p8-2xl col-span-3'>{Object.keys(selectedRowIds).length} {Idioma.transacciones.transaccionesSeleccionadas[language]}</p>
                    <p className='text-[12px] 2xl:text-p8-2xl'>{Idioma.planeacion.recurrencia[language]}</p>
                    <Dropdown
                        options={recurrenceOptions}
                        selected={recurrence}
                        setSelected={handleChangeRecurrence}
                        className={' w-full col-span-2 text-[10px] 2xl:text-p8-2xl bg-white rounded-button border-1 border-neutral-1 '}
                    />
                    <p className='col-span-3'>{Idioma.planeacion.opcionesRecurrencia.termina[language]}</p>
                    <p className='text-[12px] 2xl:text-p8-2xl'>{Idioma.planeacion.opcionesRecurrencia.cuandoTermina[language]}</p>
                    <div className='col-span-2'>
                      <DatePicker 
                      className={'h-8 pl-6 text-[10px] 2xl:text-p8-2xl bg-white rounded-button border-1 border-neutral-1 mb-2'} 
                      selected={recurrenceEndDate} 
                      locale={language}
                      onChange={(date) => handleChangeRecurrenceEndDate(date)}
                      fixedHeight
                      maxDate={recurrenceEndDateLimit.setFullYear(recurrenceEndDateLimit.getFullYear() + 2 )}
                      minDate={recurrenceEndDateMinLimit}
                      placeholderText={Idioma.transacciones.configuracion.fecha[language]}
                      dateFormat={Idioma.formatoFecha[language]}
                      />
                    </div>
                    <p className='text-[12px] 2xl:text-p8-2xl'>{Idioma.planeacion.opcionesRecurrencia.despuesDe[language]}(max {recurrenceRepeatsLimit})</p>
                    <input key={'recurrenceRepeats'} className='col-span-2 pl-6 bg-white border-1 border-neutral-1 w-[100%] text-[10px] 2xl:text-p8-2xl rounded-button px-2 py-2' type='number' min='0' max={recurrenceRepeatsLimit} value={recurrenceRepeats} onBlur={()=>checkRecurrenceRepeat()} onChange={(e)=>handleChangeRecurrenceRepeats(e.target.value)} placeholder={Idioma.planeacion.opcionesRecurrencia.repeticiones[language]}></input>
                  </div>
                  <div className='flex justify-end items-center mt-8'>
                    <button className={` font-bold text-b3-2xl py-2 px-4 w-fit rounded-button ${recurrenceDisabled ? 'bg-gray-6 ':'bg-light-purple-3'}`} disabled={recurrenceDisabled} onClick={()=>{TransactionRecurrence()}}>{addTransactionLoading ?  Idioma.transacciones.desgloseTransaccion.cargando[language]:Idioma.transacciones.desgloseTransaccion.guardar[language]}</button>
                    <button className='bg-secondary font-bold text-b3-2xl py-2 px-4 w-fit ml-2 rounded-button text-white' onClick={()=>{handleCloseRecurrence()}}>{Idioma.transacciones.anadirTransaccionModal.cancelar[language]}</button>
                  </div>
                  <span onClick={()=>handleCloseRecurrence()} className={Styles.modalXmark}><XMark/></span>
                  </div>
                </div>
              </div>}
              {openDeletedTransactions && 
                <div className='fixed top-0 right-0 left-[15%] bottom-0 w-screen z-[1000] h-screen'>
                <div onClick={()=>closeRecycleBin()} className={Styles.modalBackground}>
                </div>
                <div className={Styles.defaultModal}>
                  <div className='2xl:w-[1300px] w-[900px] relative'>
                  <span onClick={()=>closeRecycleBin()} className={Styles.modalXmark}><XMark/></span>
  
                    <div className='flex items-center mb-[15px]'>
                      <span className='w-[45px]'>
                       <WarningLogo/>
                      </span>
                      <p className='text-[30px] 2xl:text-h5-2xl font-bold'>{Idioma.transacciones.modalPapelera.transaccionesEliminadas[language]}</p>
                    </div>
                    <div className='flex ml-[45px] mb-[20px]'>
                      <p>{Idioma.transacciones.modalPapelera.avisoTransaccionesPapelera[language]}</p>
                      <button disabled={deletedData.length===0} className={`  ${deletedData.length===0 ? ' ':'text-light-purple-3 underline'}`} onClick={()=>emptyRecycleBin()}>{Idioma.transacciones.modalPapelera.botonVaciarPapelera[language]}</button>
                    </div>
                    <DeletedTransactionsTable columns={columns} data={deletedData} recycleBinLoading={recycleBinLoading} setSelectedDeletedTransactions={setSelectedDeletedTransactions}/>
                    
                    <div className='flex items-center justify-between mt-[15px]' >
                      <button onClick={()=>restoreFromRecycleBin()} className='' disabled={selectedDeletedTransactions.length==0} title={Idioma.transacciones.modalPapelera.restaurar[language]}>
                        <RestoreRBinLogo fill={selectedDeletedTransactions.length==0 && '#ADADAD'}/>
                      </button>
                      <p className={` text-neutral-3 text-center ${selectedDeletedTransactions.length>0 ? '':'invisible'}`}>{selectedDeletedTransactions.length} {Idioma.transacciones.transaccionesSeleccionadas[language]}</p>
                      <button onClick={()=>setOpenDeleteTransactionsWarning(true)} disabled={selectedDeletedTransactions.length==0} className={`${selectedDeletedTransactions.length==0 ? 'bg-gray-6 text-neutral-3':'bg-purple-1 text-white'}  font-bold py-2 px-4 ml-2 rounded-button `}>{Idioma.transacciones.modalPapelera.eliminarDefinitivo[language]}</button>
                    </div>
                    </div>
                </div>
                
              </div>}
              {openSendToRecycleBinWarning && 
              <div className='fixed top-0 right-0 left-[15%] bottom-0 w-screen z-[1001] h-screen'>
                <div onClick={()=>setOpenSendToRecycleBinWarning(false)} className="fixed top-0 right-0 left-[0%] bottom-0 w-screen z-10 h-screen opacity-[.74] backdrop-brightness-[.15] bg-gray-6 backdrop-blur-[44px]">
                </div>
                <div className='bg-white z-10 absolute top-[calc(40%)] left-[35%] px-[14px] mt-10 py-[28px] w-[500px] rounded-main -translate-x-[50%] -translate-y-[50%]'>
                  <p className=' text-h7-2xl text-center'>{Idioma.transacciones.modalPapelera.eliminarPapeleraTitulo[language]}</p>
                  <p className=' text-p5-2xl text-center'>{Idioma.transacciones.modales.mandarAPapeleraTexto1[language]}{selectedFlatRows.length} {Idioma.transacciones.modales.mandarAPapeleraTexto2[language]}</p>
                  <div className='flex justify-center items-center mt-[20px]'>
                    <button className='py-2 px-4 ml-2 rounded-button underline text-b4-2xl font-bold' onClick={()=>{setOpenSendToRecycleBinWarning(false)}}>{Idioma.transacciones.desgloseTransaccion.cancelar[language]}</button>
                    <button className={`py-2 px-4 rounded-button text-white bg-purple-1 text-b4-2xl font-bold`} onClick={()=>multiDeleteTransaction()}>{Idioma.subirTransacciones.eliminar[language]}</button>
                  </div>
                </div>
              </div>}
              {openCleanTransactionsWarning && 
            <div className='fixed top-0 right-0 left-[15%] bottom-0 w-screen z-[1001] h-screen'>
              <div onClick={()=>setOpenCleanTransactionsWarning(false)} className="fixed top-0 right-0 left-[0%] bottom-0 w-screen z-10 h-screen opacity-[.74] backdrop-brightness-[.15] bg-gray-6 backdrop-blur-[44px]">
              </div>
              <div className='bg-white z-10 absolute top-[calc(40%)] left-[35%] px-[14px] mt-10 py-[28px] w-[500px] rounded-main -translate-x-[50%] -translate-y-[50%]'>
                <p className=' text-h7-2xl text-center'>{Idioma.transacciones.modales.limpiarTransaccionTitulo[language]}</p>
                <p className=' text-p5-2xl text-center'>{Idioma.transacciones.modales.limpiarTransaccionTexto1[language]}{selectedFlatRows.length} {Idioma.transacciones.modales.limpiarTransaccionTexto2[language]}</p>
                <div className='flex justify-center items-center mt-[20px]'>
                  <button className='py-2 px-4 ml-2 rounded-button underline text-b4-2xl font-bold' onClick={()=>{setOpenCleanTransactionsWarning(false)}}>{Idioma.transacciones.desgloseTransaccion.cancelar[language]}</button>
                  <button className={`py-2 px-4 rounded-button text-white bg-purple-1 text-b4-2xl font-bold`} onClick={()=>cleanTransactions()}>{Idioma.transacciones.modales.continuar[language]}</button>
                </div>
              </div>
            </div>}
              
            {/*<a href='/subcategories'>Subcategories settings</a>*/}
            <div className='h-[100%]  overflow-y-scroll min-h-[70vh] overflow-x-hidden'>
              <div className=' flex justify-between '>
                
                <div className='flex items-center justify-start mb-4 gap-x-4 h-full'>
                    <DinamicDropdown //aqui va dropdown de files
                            options={scenarioOptions}
                            selected={scenarioFilter}
                            setSelected={handleScenarioChange}
                            className={'  px-[15px] 2xl:py-4 py-2 text-[18px] 2xl:text-button-1 h-button-1'}
                        />
                    <button onClick={()=>setOpenAddScenario(true)} title={Idioma.planeacion.modalAnadir.titulo[language]} className={` bg-gray-6 h-button-1 flex justify-center items-center rounded-button aspect-square   ${scenarioOptions.length===0 ? 'animate-pulsebg':''}`}>
                        <AddScenarioLogo/>
                    </button>
                    <button onClick={()=>setOpenEditScenario(true)} title={Idioma.planeacion.modalEditar.titulo[language]} className={` bg-gray-6 h-button-1 flex justify-center items-center rounded-button aspect-square `}>
                        <EditScenarioLogo/>
                    </button>
                    {userAccess && userAccess.access3 && <div onClick={()=>navigate("/planning/upload")} className='bg-light-purple-3 overflow-visible cursor-pointer items-center font-bold rounded-button flex justify-center px-[15px] 2xl:py-4 py-2 text-[18px] 2xl:text-button-1 h-button-1 '>
                        <span className='2xl:scale-100'><TransaccionesLogo fill="#000"/></span>
                        <p className='pl-[15px]'>{Idioma.navbar.subirPlaneacion[language]}</p>
                    </div>}
                    <div onClick={()=>navigate("/planning")} className='bg-light-purple-3 overflow-visible cursor-pointer items-center font-bold rounded-button flex justify-center px-[15px] 2xl:py-4 py-2 text-[18px] 2xl:text-button-1 h-button-1 '>
                        <span className='2xl:scale-100'><PlanningLogo fill="#000"/></span>
                        <p className='pl-[15px]'>{Idioma.planeacion.planeacion[language]}</p>
                    </div>
                  
                </div>
                <div className=' mr-2 mb-1 justify-end'>
                  <div className='flex justify-end'>
                  <GlobalFilter
                    preGlobalFilteredRows={preGlobalFilteredRows}
                    globalFilter={state.globalFilter}
                    setGlobalFilter={setGlobalFilter}
                  /></div>
                  <div className='my-auto 2xl:h-button-3 h-button-3 overflow-visible'>
                  {categories.length>0 && subcategories.length>0 && Object.keys(selectedRowIds).length>= 1 && 
                        <div className='overflow-visible py-2'>
                          <div className='flex justify-end relative items-center'>
                            <button className={` p-4 py-2 h-button-3 mr-[15px] rounded-button text-button-3 w-fit ${selectedSubcategory ? 'bg-light-purple-3 cursor-pointer text-white font-bold' :'bg-gray-6 text-neutral-3 cursor-default'}`} disabled={isMultiUpdateLoading} onClick={()=>multiTransactionUpdate(selectedSubcategory)}>{isMultiUpdateLoading ? <Loading height='5' width='5'/> : Idioma.transacciones.aplicarClasificacion[language] }</button>
                            <MultiTransactionLogo fill={"#000"}/>
                            <div className={'absolute flex items-center right-2'}>
                              <TrianguloAbajoLogo fill={'#B4B4B4'}/>
                            </div>
                            <select className=' py-2 px-2 rounded-button appearance-none font-bold w-[270px] option:bg-gray-6 text-h9-2xl' id="multiTransaction-id" value={selectedSubcategory} onChange={event=>setSelectedSubcategory(event.target.value)}>
                              <option className='hover:bg-gray-6 appearance-none text-p9-2xl' disabled selected>{Idioma.transacciones.clasificacionMultiple[language]}</option>
                              {categories.map(category=>{
                                return (
                                  <optgroup className='text-h12-2xl' label={category.name}>
                                    {subcategories[category.index].map(subcategory => {
                                      return (
                                        <option className='hover:bg-gray-6 appearance-none text-p9-2xl' value={subcategory.idSubCategory}>{subcategory.name}</option>
                                      )
                                    })}
                                  </optgroup>
                                )
                              })}
                            </select>
                          </div>
                        
                        </div>
                        }
                        </div>
                </div>
              </div>
              
                {/*<div className='ml-1 min-w-[400px] max-w-[400px]'>
                  <FileDropdown //aqui va dropdown de files
                    options={filesData}
                    selected={file}
                    setSelected={handleFileChange}
                    className={' mb-2 border-gray-300 border-b-2'}
                  />
                </div>*/}
                      
                      <div className='flex justify-between items-center px-2 2xl:py-2 py-0 bg-gray-6 w-full xl:overflow-visible overflow-y-hidden overflow-x-auto thin-scrollbar rounded-main text-[14px] 2xl:text-[18px] mt-[6px]'>
                       <div className='flex items-center gap-x-[12px]'>
                       {userAccess && userAccess.access3 && <>
                        <button onClick={()=>handleOpenAddTransaction()} disabled={scenarioOptions.length===0} title={Idioma.transacciones.anadirTransaccionModal.tituloModal[language]} className={`${scenarioOptions.length>=1&&'hover:bg-white'} p-[4px] rounded-button flex justify-center items-center scale-100`}>
                          <AddSingleTransactionLogo fill={scenarioOptions.length>= 1 ? '#000':'#adadad'}/>
                        </button>
                       
                        {/*<button onClick={()=>setOpenRulesModal(true)} title='Generar reglas' className='hover:bg-white px-2  rounded-[7px]'>
                          <PencilSquareIcon className='h-8 w-8 text-md-blue'/>
                </button>*/}
                        
                        {/*
                        <button onClick={()=>setFiltersActive(prev => !prev)} title='Activar/Desactivar filtros' className={filtersActive ? 'hover:bg-white px-2 rounded-[7px] text-secondary flex justify-between items-center':'hover:bg-white px-2 rounded-[7px] flex justify-between items-center'}>
                          <PreferencesLogo/>
                          <span className='pl-2'>{filtersActive ? 'Desactivar filtros':'Activar filtros'}</span>
                        </button>*/}
                        <button onClick={()=>setOpenSendToRecycleBinWarning(true)} disabled={Object.keys(selectedRowIds).length== 0} title={Idioma.transacciones.eliminarTransaccion[language]} className={`flex items-center justify-center rounded-button p-[4px] ${Object.keys(selectedRowIds).length>= 1 ? 'hover:bg-white' : ''}`}>
                          <ThrashCanLogo fill={Object.keys(selectedRowIds).length>= 1 ? '#000':'#adadad'}/>
                        </button>
                        </>}
                        <button onClick={()=>setOpenPreferencesModal(true)} title={Idioma.transacciones.editarPreferencias[language]} className={`hover:bg-white p-[4px] rounded-button flex justify-between items-center`}>
                          <SettingsLogo/>
                        </button>
                        {/*<button onClick={()=>setOpenFiltersModal(true)} title={Idioma.transacciones.editarFiltros[language]} className={`hover:bg-white p-[4px] rounded-button flex justify-between items-center ${scenarioOptions.length===0 ? '':isLoading===false && data.length===0 && 'animate-pulsebg'}`}>
                          <FiltersLogo/>
                        </button>*/}
                        <div className={`relative ${Object.keys(selectedRowIds).length==1 ? '':''}`}>
                            <button onClick={()=>handleOpenRecurrence()} disabled={Object.keys(selectedRowIds).length!= 1} title={Idioma.planeacion.recurrencia[language]} className={`flex items-center justify-center rounded-button p-[4px] ${Object.keys(selectedRowIds).length== 1 ? 'hover:bg-white' : ''}`}>
                            <RecurrenceLogo  fill={Object.keys(selectedRowIds).length== 1 ? '#000':'#adadad'}/>
                            </button>
                        </div>
                        <button onClick={()=>setOpenCopyScenario(true)} disabled={Object.keys(selectedRowIds).length== 0} title={Idioma.planeacion.copiarAOtroEscenario[language]} className={`flex items-center justify-center rounded-button p-[4px] ${Object.keys(selectedRowIds).length>= 1 ? 'hover:bg-white' : ''}`}>
                          <CopyScenarioLogo fill={Object.keys(selectedRowIds).length>= 1 ? '#000':'#adadad'}/>
                        </button>
                        
                        {userAccess && userAccess.access3 && MLInfo.endpoint && showState==='nonClassified' && <button onClick={()=>sendToMLModel()} disabled={Object.keys(selectedRowIds).length== 0} title={Idioma.transacciones.autoclasificar[language]} className={` flex items-center rounded-button p-[4px] ${Object.keys(selectedRowIds).length>= 1 ? 'hover:bg-white' : ''}`}>
                          {isAutoClassing ? <Loading/>:<AutoclassLogo fill={Object.keys(selectedRowIds).length== 0 ? "#adadad":"#000"}/>}
                        </button>}
                        {userAccess && userAccess.access3 && <button onClick={()=>setOpenCleanTransactionsWarning(true)} disabled={Object.keys(selectedRowIds).length== 0} title={Idioma.transacciones.limpiarTransacciones[language]} className={` flex items-center rounded-button p-[4px] ${Object.keys(selectedRowIds).length>= 1 ? 'hover:bg-white' : ''}`}>
                        <CleaningLogo fill={Object.keys(selectedRowIds).length== 0 ? "#adadad":"#000"}/>
                        </button>}
                        <DynamicExcelExport excelData={selectedFlatRows.length === 0 ? rows:selectedFlatRows} fileName={'Export'} title={Idioma.transacciones.exportarExcel[language]} disabled={rows.length==0} language={language} columns={columns}/>
                        
                       </div>
                       
                        {/*<input value={forexValue} onChange={e=>setForexValue(e.target.value)}  type='number' className=' appearance-none border-1 border-md-gray rounded-[7px] mr-1 w-64' placeholder={Object.keys(selectedRowIds).length== 0 ? 'selecciona una transaccion': 'introduce la tasa de cambio'} disabled={Object.keys(selectedRowIds).length== 0}></input>
                        {<button onClick={()=>multiTransactionUpdateForex(forexValue)} className={forexValue ? '  font-bold rounded-full text-white text-xl bg-md-blue px-2': ' invisible font-bold rounded-full text-white text-xl bg-md-blue px-2 '} disabled={!forexValue}>Establecer</button>}         */ }      
                        
                        <div className='bg-gray-6 overflow-visible flex justify-end rounded-main h-8 px-4  right-2'>
                          
                        
                          <div className='flex items-center justify-center gap-x-[14px]'>
                              <button className={showState=='nonClassified'?activeClass:unactiveClass} onClick={()=>handleShowStateChange('nonClassified')}>{Idioma.transacciones.showState.nonClassified[language]}</button>
                              <button className={showState=='classified'?activeClass:unactiveClass} onClick={()=>handleShowStateChange('classified')}>{Idioma.transacciones.showState.classified[language]}</button>
                              <button className={showState=='all'? activeClass:unactiveClass} onClick={()=>handleShowStateChange('all')}>{Idioma.transacciones.showState.all[language]}</button>
                          </div>
                        </div>
                        
                      </div>
                      
  
                      {/*<div className='flex'>
                      <p className='mr-2 p-3'>Clasificadas/No Clasificadas</p>
                      <div className='p-3'>
                          <label for="default-toggle2" class="inline-flex relative items-center cursor-pointer">
                              <input type="checkbox" checked={active} onClick={()=>handleActiveChange()}  id="default-toggle2" class="sr-only peer"/>
                              <div class="w-11 h-6 rounded-full peer bg-md-gray peer-checked:bg-gradient-to-r from-md-blue to-md-light-blue peer-checked:after:translate-x-full after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-main-bg dark:after:bg-md-white  after:rounded-full after:h-5 after:w-5 after:transition-all "></div>
                          </label>
                      </div>
                      </div>*/}
                      <div className='flex mt-2'>
                        {contextSubcategoryId>0 && 
                        <div className='items-center flex px-2 rounded-button bg-white  2xl:text-p8-2xl text-[12px] border-1 border-neutral-1 w-fit'>
                          <p className='text-clip'>{Idioma.transacciones.filtradoPor[language]}: <span className='font-bold'>{categoriesDict[contextSubcategoryId]?.name}</span></p> 
                          <AiOutlineCloseCircle onClick={()=>setContextSubcategoryId()} className='text-neutral-1 pl-1 h-6 w-6 cursor-pointer'/>
                        </div>}
                        {contextCategoriesId.length>0 && 
                          <div title={contextCategoriesIdsFilter()} className='items-center flex px-2 ml-1 rounded-button bg-white  2xl:text-p8-2xl text-[12px] border-1 border-neutral-1 w-fit '>
                            <p className='text-clip w-48'>{Idioma.transacciones.filtradoPor[language]} <span className='font-bold'>{contextCategoriesIdsFilter(1)}</span> {Idioma.transacciones.categorias[language]}</p> 
                            <AiOutlineCloseCircle onClick={()=>setContextCategoriesIds([])} className='text-neutral-1 pl-1 h-6 w-6 cursor-pointer'/>
                          </div>
                        }
                        {contextRSID.length>0 && 
                          <div title={contextRSIDText()} className='items-center flex px-2 ml-1 rounded-button bg-white  2xl:text-p8-2xl text-[12px] border-1 border-neutral-1 w-fit '>
                            <p className='text-clip w-48'>{Idioma.transacciones.filtradoPor[language]} <span className='font-bold'>{contextRSID.length}</span> {Idioma.transacciones.empresas[language]}</p> 
                            <AiOutlineCloseCircle onClick={()=>setContextRSID([])} className='text-neutral-1 pl-1 h-6 w-6 cursor-pointer'/>
                          </div>
                        }
                      </div>
                  <div className=' block max-w-[100%] overflow-x-scroll overflow-y-hidden thin-scrollbar'>
                  <table {...getTableProps()} className=' dark:bg-secondary-dark-bg dark:text-white rounded-main w-fit border-spacing-0  '>
                    <thead className=' text-center sticky top-0 z-30 h-10 rounded-main text-[15px] '>
                    {/*<tr>
                        <th
                          colSpan={visibleColumns.length-4}
                          style={{
                            textAlign: 'left',
                          }}
                          className='bg-main-bg dark:bg-main-dark-bg '
                        >
                          <GlobalFilter
                            preGlobalFilteredRows={preGlobalFilteredRows}
                            globalFilter={state.globalFilter}
                            setGlobalFilter={setGlobalFilter}
                          />
                        </th>
                        <th className='bg-md-table-bg dark:bg-main-dark-bg flex'>
                          <Dropdown
                            options={BankOptions}
                            selected={bank}
                            setSelected={handleBankChange}
                            className={'w-[300px]'}
                        />
                        <Dropdown
                            options={accounts}
                            selected={account}
                            setSelected={setAccount}
                        />
                        </th>
                        <th className=' text-right bg-main-bg dark:bg-main-dark-bg' colSpan={3}>
                        <p className='ml-2'>Clasificadas/No Clasificadas</p>
                        <label for="default-toggle2" class="inline-flex relative items-center cursor-pointer">
                              <input type="checkbox" checked={active} onClick={()=>handleActiveChange()}  id="default-toggle2" class="sr-only peer"/>
                              <div class="w-11 h-6 rounded-full peer bg-md-gray peer-checked:bg-gradient-to-r from-md-blue to-md-light-blue peer-checked:after:translate-x-full after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-main-bg dark:after:bg-md-white  after:rounded-full after:h-5 after:w-5 after:transition-all "></div>
                          </label>
                        </th>
                        <th className='bg-main-bg dark:bg-main-dark-bg'>
                          
                        </th>
                        
                      </tr>*/}
                        {/*
                          {headerGroups.map(headerGroup => (
                            <tr {...headerGroup.getHeaderGroupProps()}>
                              {headerGroup.headers.map(column => (
                                <th {...column.getHeaderProps()} className='bg-main-bg dark:bg-main-dark-bg center'>
                                  
                                  <div>{column.canFilter ? column.render('Filter') : null}</div>
                                </th>
                                
                              ))}
                              <th className='bg-md-table-bg dark:bg-main-dark-bg'> <Link to='/subcategories'><Cog8ToothIcon className='h-8 w-8 text-md-gray dark:text-md-white'/></Link> </th>
                            </tr>
                          ))}
                      */ }
                      
                      {headerGroups.map(headerGroup => (
                        <tr {...headerGroup.getHeaderGroupProps()} className={' py-2 '}>
                          {headerGroup.headers.map(column => (
                            <th {...column.getHeaderProps()} className={column.className + ' hover:text-light-purple-3  '}>
                               {/* Render the columns filter UI */}
                              {<div className='text-[10px] mb-2 '>{column.canFilter && column.render('Filter')}</div>}
                              {column.id=='selection' ?
                              <>
                              <button className={` cursor-pointer scale-[.80] -mt-2 mb-3 rounded-full ${isLoading===false && rows.length===0 && data.length!==rows.length && 'animate-pulsebg'}`} onClick={()=>resetFilters()}><ResetLogo/></button>
                                {column.render('Header')} </>:
                                column.id=='index' ? 
                                <p className='text-[12px] 2xl:text-[16px]'>{column.Header}
                                </p> :
                                <p className={`flex relative text-[12px] 2xl:text-[16px] items-center cursor-pointer ${column.id=='inflow'||column.id=='outflow'||column.id=='exchange'?' flex justify-end':'justify-between pl-2'}`} onClick={()=>{
                                  const desc =
                                  column.isSortedDesc === true ?
                                  undefined :
                                  column.isSortedDesc === false ?
                                  true :
                                  false ;
                                  if(desc===undefined){
                                    setSortBy([/*...sortees*/])
                                  }else if(desc===true){
                                  setSortBy([{id:column.id,desc:desc}/*,...sorteesTrue*/])
                                  }else{
                                    setSortBy([{id:column.id,desc:desc}/*,...sortees*/])
                                  }
                                  
                                  
                                }} ><span className=' relative'><span className='absolute top-0 -left-3'>{infoDict[column.id] && <InfoI texto={infoDict[column.id]} popDirection={false} popXDirection={column.id==='exchange'?'left':'right'}/>}</span>{column.Header}</span>
                                <span className={`${column.isSorted || column.isSortedDesc ? 'mx-[15px]':''}`}>
                                {column.isSorted
                                  ? column.isSortedDesc
                                    ? <TrianguloAbajoLogo/>
                                    : <div className='rotate-180'><TrianguloAbajoLogo/></div>
                                  : <></>}
                                  
                                  
                              </span></p>
                              }
                              
                              {column.id != 'selection' &&
                                  <div
                                  {...column.getResizerProps()}
                                  className={` inline-block bg-neutral-1 w-[4px] h-[32px] absolute right-2 top-0 translate-x-[50%] z-1 touch-none ${
                                    column.isResizing ? 'isResizing' : ''
                                  }`}
                                />
                                  }
                            </th>
                          ))}
                          
                        </tr>
                      ))}
                      
                    </thead>
                    <tbody {...getTableBodyProps()} className=' 2xl:text-p6-2xl text-[12px] relative'>
                    {isLoading ? 
                    <>
                      <tr className='relative'>
                          <p className='absolute w-[100%] text-neutral-3 text-center mt-[60px] text-lg'>{Idioma.transacciones.buscando[language]}...</p>
                      </tr>
                    </> :
                    file.id || (startDate!=null && endDate!=null) ? 
                      (data.length==0 ?
                      <>
                        <tr className='relative'>
                            <p className='absolute w-[100%] text-neutral-3 text-center mt-[60px] text-lg'>{Idioma.transacciones.noTransacciones[language]}</p>
                        </tr>
                      </> :<></> ): data.length>0 ? 
                      <></> :
                      <>
                        <tr className='relative'>
                            <p className='absolute w-[100%] text-neutral-3 text-center mt-[60px] text-lg'>{Idioma.transacciones.usarFiltros[language]}</p>
                        </tr>
                      </>
                      }  
                    <FixedSizeList
                    className='w-[calc(100%+10px)] overflow-y-hidden thin-scrollbar absolute 2xl:text-[12px] text-[10px] mt-4'
                        height={document.documentElement.clientHeight < 900 ? document.documentElement.clientHeight*.35 :document.documentElement.clientHeight*.40 }
                        itemCount={rows.length}
                        itemSize={40}
                      >
                        {RenderRow(rows)}
                      </FixedSizeList>
                                   {/*    
                      {rows.map((row, i) => {
                        prepareRow(row)
                        return (
                          <tr {...row.getRowProps()} className={transactionId==row.original.id ? 'bg-md-white' : 'group hover:bg-md-white'} onClick={()=>transactionSelect(row)}>
                            {row.cells.map(cell => {
                              return <td {...cell.getCellProps()}>{createCellOfType(cell,row)}</td>
                            })}
                            {/*<td className=' bg-main-bg dark:bg-secondary-dark-bg'>
                            <div className=' group-hover:visible text-md-light-blue invisible dark:text-md-white'>
                              <button>
                                <Scissors className='h-8 w-8 '/>
                              </button>
                            </div>
                          </td>}
                          </tr>
                        )
                      })} */}
                    </tbody>
                  </table>
                  </div>
                </div>
  
                {userAccess && userAccess.access3 && 
                <>
                <div className='flex justify-between items-start 2xl:mt-[60px] mt-[30px]  mb-2'>
                  <button onClick={()=>openRecycleBin(true)} className='flex  items-center'><ThrashCanLogo fill="#000"/><p className='2xl:text-[18px] ml-1 underline -mb-0.5 font-normal'>{Idioma.transacciones.papelera[language]}</p></button>
                  <div className='text-right mr-12 text-neutral-3 text-p9-2xl w-1/2'>
                    {Object.keys(totals).map((total)=>(
                      <>
                        {Object.keys(totals[total]).map((totalCurrency)=>(
                          <>
                            {
                            `Total ${total==='inflow'? Idioma.transacciones.configuracion.abono[language]:Idioma.transacciones.configuracion.cargo[language]} ${totalCurrency}: ${total==='outflow' && numberFormat===0 ? '(' : numberFormat!==0 ?  '-' :''}${totals[total][totalCurrency]!=0 ? totals[total][totalCurrency].toLocaleString("en-US", {
                              style: "currency",
                              currency: "USD",
                              currencySign: "accounting",
                              currencyDisplay: 'code'
                            }).slice(4):'--'}${total==='outflow' && numberFormat===0 ? ')' : ''} `}<br/>
                          </>
                        ))}    
                      </>
                          ))}
                    {Object.keys(selectedRowIds).length>=1 && <p> {Object.keys(selectedRowIds).length} {Idioma.transacciones.transaccionesSeleccionadas[language]}</p>}
                          
                  </div>
                  </div>
                  </>}
                  <div className='absolute bottom-[0px] -right-[40px] z-[101] bg-white'>
                      {/*showTransactionsUpdated && 
                        <>
                        {isAutoClassing ? 
                          <MessageToast type='warning' setSign={setShowTransactionsUpdated} title={Idioma.transacciones.procesoAutoclass[language]} message={amountAutoClassing} />
                          :
                          <MessageToast type='success' setSign={setShowTransactionsUpdated} title={Idioma.transacciones.transActualizadas[language]} message={updatedTransactions} />
                        }
                        </>
                      }
                    {errorSign &&  <MessageToast type="error" title="Algo salio mal" message={Idioma.transacciones.transNoActualizadas[language]} setSign={setErrorSign}/>*/}
                  </div>
          </div>
      )
}

export default PlanningTransaction